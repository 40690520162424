import React, { Component } from "react";
import { connect } from "react-redux";
import classNames from "classnames";

import { withStyles } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Toolbar from "@material-ui/core/Toolbar";
import Grid from "@material-ui/core/Grid";
import logo from "../../ms4logo.png";
import NotReady from "./NotReady";

const styles = (theme) => ({
    appContainer: {
        // The parent container of the header, sidebar, content
        paddingTop: 80, // 64 = Height of Header; + 16 padding
        height: "calc(100% - 80px)", // Height 100% - padding; This is set so the dashboard map can be 100%
    },
    backgroundColor: {
        backgroundColor: "#f0f0f0",
    },
    height: {
        height: "100%",
    },

    appLogo: {
        width: 115,
    },
    zIndex: {
        zIndex: 1300,
        backgroundColor: theme.palette.primary.light,
    },
    hide: {
        display: "none",
    },
    flex: {
        display: "flex",
        flexFlow: "row wrap",
        height: 64,
    },
    flexCenter: {
        marginLeft: 24,
        marginTop: 16,
        flex: "1 auto",
    },
    flexCenterMobile: {
        marginLeft: 8,
        marginTop: 16,
        flex: "1 auto",
    },
    flexRight: {
        textAlign: "right",
        flex: "1 auto",
        marginTop: -4,
    },
    userLogo: {
        maxHeight: 64,
    },
});

class AppContainer extends Component {
    state = {
        user: {},
        loaded: false,
    };

    componentDidMount() {
        const { city_id } = this.props;

        fetch("/city_lookup/" + city_id)
            .then((res) => res.json())
            .then((results) => {
                this.setState({ user: results, loaded: true });
            });
    }

    render() {
        const { children, classes, page_loading, printing, crwdgrant, mediaQuery } = this.props;
        const { user, loaded } = this.state;

        return (
            <div className={classNames(classes.appContainer, classes.backgroundColor)}>
                <AppBar position="fixed" className={printing ? classes.hide : classes.zIndex} style={{backgroundColor: user.header_color}}>
                    <Toolbar>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <div className={classes.flex}>
                                    <div>
                                        {(user.display === "Both" || user.display === "Logo Only") && (
                                            <img
                                                onError={(e) => {
                                                    e.currentTarget.style.display = "none";
                                                }}
                                                src={user.logo}
                                                alt={user.city_name}
                                                className={classes.userLogo}
                                            />
                                        )}
                                    </div>
                                    <div className={mediaQuery ? classes.flexCenter : classes.flexCenterMobile}>
                                        {(user.display === "Both" || user.display === "Name Only") && (
                                            <Typography variant="h5" style={{ color: "#3d3d3d" }}>
                                                {user.city_name}
                                            </Typography>
                                        )}
                                    </div>
                                    <div className={classes.flexRight}>
                                        <img style={{ padding: "3px 0" }} src={logo} alt="MS4Front" className={classes.appLogo} />
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
                <div className={classes.height}>
                    {page_loading && !crwdgrant && <NotReady message="Saving Application..." />}
                    {page_loading && crwdgrant && <NotReady message="Please wait until your application has been submitted" />}
                    {loaded && !page_loading && children}
                    {!loaded && !page_loading && <NotReady message="Loading Application..." />}
                </div>
            </div>
        );
    }
}
AppContainer.displayName = "AppContainerApplication";
AppContainer = connect((state) => ({
    navState: state.nav,
    authState: state.auth,
}))(AppContainer);

const withMediaQuery = (...args) => Component => props => {
    const mediaQuery = useMediaQuery(...args);
    return <Component mediaQuery={mediaQuery} {...props} />;
};

export default withStyles(styles)(withMediaQuery('(min-width:960px)')(AppContainer));
