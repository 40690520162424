import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Form } from "react-form";
import { connect } from "react-redux";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepButton from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Divider from "@material-ui/core/Divider";
import DialogContent from "@material-ui/core/DialogContent";
import Accordion from "@material-ui/core/Accordion";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import DialogContentText from "@material-ui/core/DialogContentText";
import Toolbar from "@material-ui/core/Toolbar";
import { withStyles } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";

import ViewIcon from "@material-ui/icons/Visibility";
import Close from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import BaseMapControlApplication from "../esri/BaseMapControlApplication";
import AppContainerApplication from "../../common/AppContainerApplication";
import TextField from "../../common/TextField";
import Select from "../../common/Select";
// import ReactSelect from "../../common/ReactSelect";
// import RadioGroup from "../../common/RadioGroup";
import FileInput from "../../common/FileInput";
import Checkbox from "../../common/Checkbox";
// import HelpLabel from "../../common/HelpLabel";
// import { createSelector } from "../../common/orm";
import states from "../../common/states.json";
import EnhancedTableHead from "../../common/EnhancedTableHead";
import CustomTableCell from "../../common/TableCell";
import {
    ApplicationRecord,
    ApplicationRecordStatus,
    ColumbusResidential,
    ColumbusResidentialContact,
    ColumbusResidentialAttachment
} from "../../page/models";

const styles = theme => ({
    deleteWidth: {
        minWidth: 34,
        width: 34,
        marginRight: 8,
        marginTop: 2,
        marginBottom: 2
    },
    centerAlign: {
        textAlign: "center"
    },
    centerAlign2: {
        textAlign: "center",
        display: "inline-block",
        width: "100%"
    },
    nowrap: {
        whiteSpace: "nowrap"
    },
    rightAlign: {
        textAlign: "right"
    },
    flex: {
        flex: 1
    },
    printDivider: {
        height: 8,
        backgroundColor: "rgba(0, 0, 0, 0.42)",
        marginTop: 16,
        marginBottom: 8
    },
    dialogMin: {
        minWidth: 400
    },
    minWidth: {
        minWidth: 600
    },
    root: {
        flexBasis: "100%",
        maxWidth: "100%",
    },
    height: {
        height: "600px",
        width: "100%"
    }
});

function MAKE_OPTIONS(table) {
    return table.map(row => ({
        label: row,
        value: row
    }));
}

// const getApplication = createSelector(
//     (state, ownProps) => ownProps.match.params["hash"],
//     (session, hash) => {
//         return session.ColumbusResidential.filter(p => p.hash === hash).toModelArray()[0];
//     }
// );

class ColumbusResidentialApp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeStep: 0,
            contactDialogOpen: false,
            contactEditing: null,
            formKey: null,
            order: "desc",
            orderBy: null,
            temp_contacts: [],
            attachmentDialogOpen: false,
            attachmentEditing: null,
            attachmentFormKey: null,
            attachmentOrder: "desc",
            attachmentOrderBy: null,
            temp_attachments: [],
            loading: false,
            new_save: false,
            new_save2: false,
            mainFormKey: null,
            applicant_same_NO: true,
            applicant_same_secondary_NO: true,
            permittee_same_NO: true,
            loaded_record: null,
            createPoint:false,
            drawingFeature:false,
        };
    }

    componentDidMount() {
        const {
            match,
            ormColumbusResidentialLoadDetailChild,
            ormColumbusResidentialContactLoadDetailChild,
            ormColumbusResidentialAttachmentLoadDetailChild
        } = this.props;
        var component = this;

        if (match.params && match.params.hash) {
            ormColumbusResidentialLoadDetailChild(match.params.hash, function(rec) {
                component.setState({ loaded_record: rec[0], mainFormKey: window.performance.now(), applicant_same_NO: !rec[0].applicant_same, applicant_same_secondary_NO: !rec[0].applicant_same_secondary, permittee_same_NO: !rec[0].permittee_same });
            });
            ormColumbusResidentialContactLoadDetailChild(match.params.hash, function(data) {
                component.setState({ temp_contacts: data });
            });
            ormColumbusResidentialAttachmentLoadDetailChild(match.params.hash, function(data) {
                component.setState({ temp_attachments: data });
            });
        }
        if (match.params && match.params.print) {
            component.setState({ activeStep: "PRINT" });
        }
    }

    errorValidator = values => {
        const isRequired = val => {
            return !val ? "Required" : null;
        };
        const validateEmail = email => {
            var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return !re.test(email) ? "Invalid Email Address" : null;
        };
        var valObj = {
            owner_first_name: isRequired(values.owner_first_name),
            owner_last_name: isRequired(values.owner_last_name), 
            owner_mailing_address: isRequired(values.owner_mailing_address),
            owner_phone: isRequired(values.owner_phone),
            owner_city: isRequired(values.owner_city),
            owner_state: isRequired(values.owner_state),
            owner_zip: isRequired(values.owner_zip),
            owner_email: validateEmail(values.owner_email),
            secondary_first_name: isRequired(values.secondary_first_name),
            secondary_last_name: isRequired(values.secondary_last_name),
            secondary_organization: isRequired(values.secondary_organization),
            secondary_mailing_address: isRequired(values.secondary_mailing_address),
            secondary_city: isRequired(values.secondary_city),
            secondary_state: isRequired(values.secondary_state),
            secondary_zip: isRequired(values.secondary_zip),
            secondary_phone: isRequired(values.secondary_phone),
            secondary_email: validateEmail(values.secondary_email),
            applicant_type: isRequired(values.applicant_type),
            
        };
        if (!values.applicant_same && !values.applicant_same_secondary) {
            valObj["applicant_first_name"] = isRequired(values.applicant_first_name);
            valObj["applicant_last_name"] = isRequired(values.applicant_last_name); 
            valObj["applicant_mailing_address"] = isRequired(values.applicant_mailing_address);
            valObj["applicant_city"] = isRequired(values.applicant_city);
            valObj["applicant_state"] = isRequired(values.applicant_state);
            valObj["applicant_zip"] = isRequired(values.applicant_zip);
            valObj["applicant_phone"] = isRequired(values.applicant_phone);
            valObj["applicant_email"] = isRequired(values.applicant_email);
        }
        if (!values.permittee_same) {
            valObj["permittee_first_name"] = isRequired(values.permittee_first_name);
            valObj["permittee_last_name"] = isRequired(values.permittee_last_name); 
            valObj["permittee_mailing_address"] = isRequired(values.permittee_mailing_address);
            valObj["permittee_city"] = isRequired(values.permittee_city);
            valObj["permittee_state"] = isRequired(values.permittee_state);
            valObj["permittee_zip"] = isRequired(values.permittee_zip);
            valObj["permittee_phone"] = isRequired(values.permittee_phone);
            valObj["permittee_email"] = isRequired(values.permittee_email);
        }

        var alerts = "";
        var ai = 4;
        if (valObj["owner_first_name"] === "Required") {
            alerts += "Property Owner First Name (Step 1)\n";
            ai = 0;
        }
        if (valObj["owner_last_name"] === "Required") {
            alerts += "Property Owner Last Name (Step 1)\n";
            ai = 0;
        } 
        if (valObj["owner_mailing_address"] === "Required") {
            alerts += "Property Owner Mailing Address (Step 1)\n";
            ai = 0;
        }
        if (valObj["owner_city"] === "Required") {
            alerts += "Property Owner City (Step 1)\n";
            ai = 0;
        }
        if (valObj["owner_state"] === "Required") {
            alerts += "Property Owner State (Step 1)\n";
            ai = 0;
        }
        if (valObj["owner_zip"] === "Required") {
            alerts += "Property Owner Zip (Step 1)\n";
            ai = 0;
        }
        if (valObj["owner_phone"] === "Required") {
            alerts += "Property Owner Phone (Step 1)\n";
            ai = 0;
        }
        if (valObj["owner_email"] === "Invalid Email Address") {
            alerts += "Property Owner Email (Step 1)\n";
            ai = 0;
        }

        if (valObj["secondary_first_name"] === "Required") {
            alerts += "Plan Preparer First Name (Step 1)\n";
            ai = 0;
        }
        if (valObj["secondary_last_name"] === "Required") {
            alerts += "Plan Preparer Last Name (Step 1)\n";
            ai = 0;
        }
        if (valObj["secondary_organization"] === "Required") {
            alerts += "Plan Preparer Organization / Company (Step 1)\n";
            ai = 0;
        }
        if (valObj["secondary_mailing_address"] === "Required") {
            alerts += "Plan Preparer Mailing Address (Step 1)\n";
            ai = 0;
        }
        if (valObj["secondary_city"] === "Required") {
            alerts += "Plan Preparer City (Step 1)\n";
            ai = 0;
        }
        if (valObj["secondary_state"] === "Required") {
            alerts += "Plan Preparer State (Step 1)\n";
            ai = 0;
        }
        if (valObj["secondary_zip"] === "Required") {
            alerts += "Plan Preparer Zip (Step 1)\n";
            ai = 0;
        }
        if (valObj["secondary_phone"] === "Required") {
            alerts += "Plan Preparer Phone (Step 1)\n";
            ai = 0;
        }
        if (valObj["secondary_email"] === "Invalid Email Address") {
            alerts += "Plan Preparer Email (Step 1)\n";
            ai = 0;
        }
        if (valObj["applicant_type"] === "Required") {
            alerts += "Applicant Role (Step 1)\n";
            ai = 0;
        }
        if (!values.applicant_same && !values.applicant_same_secondary) {
            if (valObj["applicant_first_name"] === "Required") {
                alerts += "Application Representative First Name (Step 1)\n";
                ai = 0;
            }
            if (valObj["applicant_last_name"] === "Required") {
                alerts += "Application Representative Last Name (Step 1)\n";
                ai = 0;
            } 
            if (valObj["applicant_mailing_address"] === "Required") {
                alerts += "Application Representative Mailing Address (Step 1)\n";
                ai = 0;
            }
            if (valObj["applicant_city"] === "Required") {
                alerts += "Application Representative City (Step 1)\n";
                ai = 0;
            }
            if (valObj["applicant_state"] === "Required") {
                alerts += "Application Representative State (Step 1)\n";
                ai = 0;
            }
            if (valObj["applicant_zip"] === "Required") {
                alerts += "Application Representative Zip (Step 1)\n";
                ai = 0;
            }
            if (valObj["applicant_phone"] === "Required") {
                alerts += "Application Representative Phone (Step 1)\n";
                ai = 0;
            }
            if (valObj["applicant_email"] === "Invalid Email Address") {
                alerts += "Application Representative Email (Step 1)\n";
                ai = 0;
            }
        }
        if (!values.permittee_same) {
            if (valObj["permittee_first_name"] === "Required") {
                alerts += "Permittee First Name (Step 1)\n";
                ai = 0;
            }
            if (valObj["permittee_last_name"] === "Required") {
                alerts += "Permittee Last Name (Step 1)\n";
                ai = 0;
            } 
            if (valObj["permittee_mailing_address"] === "Required") {
                alerts += "Permittee Mailing Address (Step 1)\n";
                ai = 0;
            }
            if (valObj["permittee_city"] === "Required") {
                alerts += "Permittee City (Step 1)\n";
                ai = 0;
            }
            if (valObj["permittee_state"] === "Required") {
                alerts += "Permittee State (Step 1)\n";
                ai = 0;
            }
            if (valObj["permittee_zip"] === "Required") {
                alerts += "Permittee Zip (Step 1)\n";
                ai = 0;
            }
            if (valObj["permittee_phone"] === "Required") {
                alerts += "Permittee Phone (Step 1)\n";
                ai = 0;
            }
            if (valObj["permittee_email"] === "Invalid Email Address") {
                alerts += "Permittee Email (Step 1)\n";
                ai = 0;
            }
        }
        if (valObj["location_description"] === "Required") {
            alerts += "Project Location Description (Step 2)\n";
            ai = 1;
        }
   

        //8
        if (valObj["signature"] === "Required") {
            alerts += "Applicant Signature (Step 8)\n";
        }
        if (valObj["signature2"] === "Required") {
            alerts += "Applicant Acknowledgement 1 (Step 8)\n";
        }
        if (valObj["signature3"] === "Required") {
            alerts += "Applicant Acknowledgement 2 (Step 8)\n";
        }
        if (valObj["signature4"] === "Required") {
            alerts += "Applicant Acknowledgement 3 (Step 8)\n";
        }
        if (valObj["signature5"] === "Required") {
            alerts += "Applicant Acknowledgement 4 (Step 8)\n";
        }
        if (valObj["signature6"] === "Required") {
            alerts += "Payment Verification (Step 8)\n";
        }
        if (valObj["signature7"] === "Required") {
            alerts += "Payment Verification (Step 8)\n";
        }
        if (alerts) {
            alert("You are missing the following required fields:\n" + alerts);
            this.setState({ activeStep: ai });
        }

        return valObj;
    };

    errorValidatorContact = values => {
        const isRequired = val => {
            return !val ? "Required" : null;
        };
        const validateEmail = email => {
            var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return !re.test(email) ? "Invalid Email Address" : null;
        };
        var valObj = {
            first_name: isRequired(values.first_name),
            last_name: isRequired(values.last_name),
            mailing_address: isRequired(values.mailing_address),
            city: isRequired(values.city),
            state: isRequired(values.state),
            zip: isRequired(values.zip),
            phone: isRequired(values.phone),
            email: validateEmail(values.email),
            type: isRequired(values.type)
        };

        return valObj;
    };

    errorValidatorAttachment = values => {
        const isRequired = val => {
            return !val ? "Required" : null;
        };
        var valObj = {
            file: isRequired(values.file),
            name: isRequired(values.name),
            _type: isRequired(values._type)
        };

        return valObj;
    };

    formatDate = date_string => {
        if (!date_string)
            return new Date().toLocaleDateString();
        const d = new Date(date_string + " 12:00:00");
        return d.toLocaleDateString();
    };

    submitApplication = values => {
        const {
            ormApplicationRecordCreate,
            ormApplicationRecordStatusCreate,
            ormColumbusResidentialCreate,
            ormColumbusResidentialContactCreate,
            ormColumbusResidentialAttachmentCreate, 
        } = this.props;
        const { temp_contacts, temp_attachments, geom } = this.state;

        const _this = this;
        this.setState({ loading: true });

         
        // New
        ormApplicationRecordCreate({
            application: 17
        }).then(ar => {
            ormApplicationRecordStatusCreate({
                applicationrecord: ar.id,
                status: "Received"
            });
            ormColumbusResidentialCreate({
                applicationrecord: ar.id,
                geometry: geom,
                ...values
            }).then(col => {
                var i = 0;
                var j = 0;
                temp_contacts.forEach(function(tc) {
                    i++;
                    ormColumbusResidentialContactCreate({
                        columbusresidential_id: col.id,
                        ...tc
                    }).then(_z => {
                        j++;
                        if (i === j) _this.setState({ loading: false, new_save: true });
                    });
                });
                temp_attachments.forEach(function(tc) {
                    i++;
                    ormColumbusResidentialAttachmentCreate({
                        columbusresidential_id: col.id,
                        ...tc
                    }).then(_z => {
                        j++;
                        if (i === j) _this.setState({ loading: false, new_save: true });
                    });
                });
                if (i === 0) {
                    // No attachments or contacts - its finished loading
                    _this.setState({ loading: false, new_save: true });
                }
            });
        });
       
    };

    addContact = values => {
        var { contactEditing, temp_contacts } = this.state;

        if (!contactEditing) {
            temp_contacts.push(values);
            this.setState({ temp_contacts: temp_contacts });
        } else {
            var temp = temp_contacts.filter(tc => tc !== contactEditing);
            temp.push(values);
            this.setState({ temp_contacts: temp });
        }

        this.setState({ contactDialogOpen: false });
    };

    addAttachment = values => {
        var { attachmentEditing, temp_attachments } = this.state;

        if (!attachmentEditing) {
            temp_attachments.push(values);
            this.setState({ temp_attachments: temp_attachments });
        } else {
            var temp = temp_attachments.filter(tc => tc !== attachmentEditing);
            temp.push(values);
            this.setState({ temp_attachments: temp });
        }

        this.setState({ attachmentDialogOpen: false });
    };

    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = "desc";

        if (this.state.orderBy === property && this.state.order === "desc") {
            order = "asc";
        }

        order === "desc"
            ? this.state.temp_contacts.sort((a, b) => (b[orderBy].toUpperCase() < a[orderBy].toUpperCase() ? -1 : 1))
            : this.state.temp_contacts.sort((a, b) => (a[orderBy].toUpperCase() < b[orderBy].toUpperCase() ? -1 : 1));

        this.setState({ order, orderBy });
    };

    handleRequestSort2 = (event, property) => {
        const orderBy = property;
        let order = "desc";

        if (this.state.orderBy === property && this.state.order === "desc") {
            order = "asc";
        }

        order === "desc"
            ? this.state.temp_attachments.sort((a, b) => (b[orderBy].toUpperCase() < a[orderBy].toUpperCase() ? -1 : 1))
            : this.state.temp_attachments.sort((a, b) => (a[orderBy].toUpperCase() < b[orderBy].toUpperCase() ? -1 : 1));

        this.setState({ order, orderBy });
    };

    handleNext = index => {
        const { activeStep } = this.state;
        if (index || index === 0) {
            this.setState({ activeStep: index });
        } else if (activeStep < 3) this.setState({ activeStep: activeStep + 1 });
        else {
            this.formApi.submitForm();
        }
    };
    
    setDialogLoading = (e) =>{
        this.setState({dialogLoading:e})
    }

    //this is triggered from basemapcontrolapplication.js
    setDrawingFeature = (geom) =>{ 
        if(geom){
            this.formApi.setValue('geometry', geom)
            this.formApi.setValue('latitude', geom.coordinates[1]) 
            this.formApi.setValue('longitude', geom.coordinates[0])  

            //address name
            // fetch(`https://services5.arcgis.com/W5OhcxczJbVwey5v/arcgis/rest/services/Wild_Rice_MS4Front_Map_WFL1/FeatureServer/1/query?geometry=${geom.coordinates[0]},${geom.coordinates[1]}
            // &outFields=*&geometryType=esriGeometryPoint&inSR=4326&returnGeometry=false&f=pjson`,
            // {
            // method : "GET"

            // })
            // .then(resp=> resp.json())
            // .then(data=>{
            //     if(data.features.length === 0){
            //     // this.setState({outOfDistrict:true})?
            //         console.log("out of district")
            //     }else{
            //         let res = data.features[0].attributes 
            //         this.formApi.setValue('address',res.MapLabel) //address 
             //         this.formApi.setValue('subdivision',res.MapLabel)   
              //         this.formApi.setValue('lot_num',res.MapLabel) 
               //         this.formApi.setValue('parcel',res.MapLabel)  
            //     }
            // })
  
        }
    }

    render() {
        const { classes/*, application*/ } = this.props;
        const {
            activeStep,
            contactDialogOpen,
            contactEditing,
            formKey,
            temp_contacts,
            order,
            orderBy,
            attachmentDialogOpen,
            attachmentEditing,
            attachmentFormKey,
            attachmentOrder,
            attachmentOrderBy,
            temp_attachments,
            loading,
            new_save,
            mainFormKey,
            new_save2,
            applicant_same_NO,
            applicant_same_secondary_NO,
            permittee_same_NO,
            createPoint,
            drawingFeature,
            dialogLoading,
            loaded_record

        } = this.state;

        //this has all parameters for the basemapcontrolapplication
        var layers = [
            {attribution: "", city_id: 2,city_label: "Columbus",id:2, is_basemap: false,
            layer_type: "Web Map", layernumbers: "3", layerurl: null,
            name: "Columbus Area",on_by_default: true,opacity: 1,order: 2,proxyurl: "",show_popup: false, subdomains: "",
            tileddynamic: "Feature Service",token: "",webmap_id: "", wmslayers: "", x: -85.920951, y: 39.200326,
            geocoder_left:[], geocoder_bottom:[], geocoder_right:[],geocoder_top:[], map_level:12 }]
        
        const is_read_only = false;

        var columnDataContacts = [
            { id: "actions", numeric: false, label: "", allowSort: false, width: "20px" },
            { id: "type", numeric: false, label: "Type", allowSort: true },
            { id: "first_name", numeric: false, label: "First Name", allowSort: true },
            { id: "last_name", numeric: false, label: "Last Name", allowSort: true },
            { id: "mailing_address", numeric: false, label: "Address", allowSort: true },
            { id: "phone", numeric: false, label: "Phone", allowSort: true },
            { id: "email", numeric: false, label: "Email", allowSort: true }
        ];

        var columnDataAttachments = [
            { id: "actions", numeric: false, label: "", allowSort: false, width: "20px" },
            { id: "name", numeric: false, label: "Name", allowSort: true },
            { id: "date_uploaded", numeric: false, label: "Date Added", allowSort: true, date: true },
            { id: "_type", numeric: false, label: "Type", allowSort: true },
            { id: "notes", numeric: false, label: "Notes", allowSort: true }
        ];

        if (new_save) {
            return (
                <AppContainerApplication city_id={window.location.origin.includes("ms4prod") ? "11" : "2"} page_loading={loading}>
                    <Grid container spacing={1} style={{ padding: "0 12px" }}>
                        <Grid item xs={12} lg={10} xl={8}>
                            <Typography>
                                This is a confirmation that the permit application has been saved and submitted successfully. A confirmation email has
                                been emailed to the <u>plan preparer's</u> email address with an additional information to pay the application fee. 
                                Please check your spam and junk folders if you have not received it or contact the city. The city will contact you as the application is being reviewed.
                            </Typography>
                        </Grid>
                    </Grid>
                </AppContainerApplication>
            );
        }

        if (new_save2) {
            return (
                <AppContainerApplication city_id={window.location.origin.includes("ms4prod") ? "11" : "2"} page_loading={loading}>
                    <Grid container spacing={1} style={{ padding: "0 12px" }}>
                        <Grid item xs={12} lg={10} xl={8}>
                            <Typography>This is a confirmation that the permit application has been resubmitted successfully.</Typography>
                        </Grid>
                    </Grid>
                </AppContainerApplication>
            );
        }

        return (
            <AppContainerApplication
                city_id="11"
                page_loading={loading}
                printing={activeStep === "PRINT"}>
                <Form
                    getApi={el => (this.formApi = el)}
                    defaultValues={loaded_record?loaded_record:{'latitude':'', 'longitude':""}}
                    key={mainFormKey}
                    dontValidateOnMount={true}
                    validateOnSubmit={true}
                    validateError={values => this.errorValidator(values)}
                    onSubmit={values => this.submitApplication(values)}>
                    {formApi => (
                        <form onSubmit={formApi.submitForm}>
                            <Grid container spacing={1} style={{ padding: "0 12px" }}>
                                <Grid item xs={12} lg={10} xl={8} classes={{ root: classes.root }}>
                                    <Typography className={classes.centerAlign} variant="h6">
                                        {'Permit Application Form'}
                                    </Typography>
                                    <Typography className={classes.centerAlign}>
                                    <u><b>For Residential Properties</b></u>
                                    </Typography>
                                    <Typography className={classes.centerAlign}>
                                        <b>IMPORTANT:</b> The entire permit application must be filled out at one time and progress will not be saved
                                        so please plan accordingly.
                                    </Typography>
                                    <Typography variant="caption" className={classes.centerAlign2}>
                                        Required Fields are marked with an asterisk (*)
                                    </Typography>
                                </Grid>
                                {activeStep !== "PRINT" && (
                                    <Grid item xs={12} lg={10} xl={8} classes={{ root: classes.root }}>
                                        <Stepper style={{ padding: 8 }} alternativeLabel nonLinear activeStep={activeStep}>
                                            {[
                                                "Contact Info",
                                                "Project Location", 
                                                "Attach Documents",
                                                "Review and Submit"
                                            ].map((label, index) => {
                                                return (
                                                    <Step key={label}>
                                                        <StepButton style={{ cursor: "pointer" }} onClick={() => this.handleNext(index)}>
                                                            {label}
                                                        </StepButton>
                                                    </Step>
                                                );
                                            })}
                                        </Stepper>
                                    </Grid>
                                )}
                                {(activeStep === 0 || activeStep === "PRINT") && (
                                    <Grid item xs={12} lg={10} xl={8} classes={{ root: classes.root }}>
                                        <Typography>
                                            Please add all contacts associated with the permit and project. There is a button towards the bottom to
                                            add additional contacts such as Consultants, Contractors and additional property owners or
                                            representatives.
                                        </Typography>
                                        <Accordion defaultExpanded>
                                            <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ backgroundColor: "#ddd" }}>
                                                <Typography variant="subtitle1">
                                                    Primary Property Owner (required)
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails style={{ backgroundColor: "f6f0f0" }}>
                                                <Grid container spacing={2} style={{ marginTop: 0 }}>
                                                    <Grid item xs={6}>
                                                        <TextField disabled={is_read_only} field="owner_first_name" label="First Name *" fullWidth />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField disabled={is_read_only} field="owner_last_name" label="Last Name *" fullWidth />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            disabled={is_read_only}
                                                            field="owner_organization"
                                                            label="Organization / Company"
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            disabled={is_read_only}
                                                            field="owner_mailing_address"
                                                            label="Mailing Address *"
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            disabled={is_read_only}
                                                            field="owner_mailing_address2" 
                                                            label={"Mailing Address 2 (i.e. Suite #, Apartment #, etc.)"}  
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField disabled={is_read_only} field="owner_city" label="City *" fullWidth />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Select
                                                            disabled={is_read_only}
                                                            field="owner_state"
                                                            label="State *"
                                                            options={states}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField disabled={is_read_only} field="owner_zip" label="Zip *" fullWidth />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField disabled={is_read_only} field="owner_phone" label="Phone *" fullWidth  usePhoneNumberFormat/>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField disabled={is_read_only} field="owner_phone_ext" label="Phone Extension" fullWidth />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField disabled={is_read_only} field="owner_email" label="Email *" fullWidth />
                                                    </Grid>
                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion>
                                        <Accordion defaultExpanded>
                                            <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ backgroundColor: "#ddd" }}>
                                                <Typography variant="subtitle1">
                                                    Home Builder (Required)
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails style={{ backgroundColor: "f6f0f0" }}>
                                                <Grid container spacing={2} style={{ marginTop: 0 }}>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            disabled={is_read_only}
                                                            field="secondary_first_name"
                                                            label="First Name *"
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField disabled={is_read_only} field="secondary_last_name" label="Last Name *" fullWidth />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            disabled={is_read_only}
                                                            field="secondary_organization"
                                                            label="Organization / Company *"
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            disabled={is_read_only}
                                                            field="secondary_mailing_address"
                                                            label="Mailing Address *"
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            disabled={is_read_only}
                                                            field="secondary_mailing_address2"
                                                            label={"Mailing Address 2 (i.e. Suite #, Apartment #, etc.)"}  
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField disabled={is_read_only} field="secondary_city" label="City *" fullWidth />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Select
                                                            disabled={is_read_only}
                                                            field="secondary_state"
                                                            label="State *"
                                                            options={states}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField disabled={is_read_only} field="secondary_zip" label="Zip *" fullWidth />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField disabled={is_read_only} field="secondary_phone" label="Phone *" fullWidth  usePhoneNumberFormat/>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField disabled={is_read_only} field="secondary_phone_ext" label="Phone Extension" fullWidth />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField disabled={is_read_only} field="secondary_email" label="Email *" fullWidth />
                                                    </Grid>
                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion>
                                        <Accordion defaultExpanded>
                                            <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ backgroundColor: "#ddd" }}>
                                                <Typography variant="subtitle1">
                                                    Application Representative Information (Required)
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails style={{ backgroundColor: "f6f0f0" }}>
                                                <Grid container spacing={2} style={{ marginTop: 0 }}>
                                                    <Grid item xs={6}>
                                                        <Select
                                                            disabled={is_read_only}
                                                            field="applicant_type"
                                                            label="Applicant Role *"
                                                            options={MAKE_OPTIONS([
                                                                "Primary Property Owner", "Secondary Property Owner", "Consultant-Plan Preparer", 
                                                                "Contractor", "SWPPP Representative", "Permittee", "Trained Individual", "Other"
                                                            ])}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Checkbox
                                                            eventHandle={checked => {
                                                                if (checked) {
                                                                    this.setState({ applicant_same_NO: false });
                                                                } else {
                                                                    this.setState({ applicant_same_NO: true });
                                                                }
                                                            }}
                                                            disabled={is_read_only || !applicant_same_secondary_NO}
                                                            label="Check if the same as the Primary Property Owner"
                                                            field="applicant_same"
                                                        />
                                                        <Checkbox
                                                            eventHandle={checked => {
                                                                if (checked) {
                                                                    this.setState({ applicant_same_secondary_NO: false });
                                                                } else {
                                                                    this.setState({ applicant_same_secondary_NO: true });
                                                                }
                                                            }}
                                                            disabled={is_read_only || !applicant_same_NO}
                                                            label="Check if the same as the Consultant Plan Preparer"
                                                            field="applicant_same_secondary"
                                                        />
                                                    </Grid>
                                                    {applicant_same_NO && applicant_same_secondary_NO && (
                                                        <>
                                                            <Grid item xs={6}>
                                                                <TextField
                                                                    disabled={is_read_only}
                                                                    field="applicant_first_name"
                                                                    label="First Name *"
                                                                    fullWidth
                                                                />
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <TextField
                                                                    disabled={is_read_only}
                                                                    field="applicant_last_name"
                                                                    label="Last Name *"
                                                                    fullWidth
                                                                />
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <TextField
                                                                    disabled={is_read_only}
                                                                    field="applicant_organization"
                                                                    label="Organization / Company "
                                                                    fullWidth
                                                                />
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <TextField
                                                                    disabled={is_read_only}
                                                                    field="applicant_mailing_address"
                                                                    label="Mailing Address *"
                                                                    fullWidth
                                                                />
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <TextField
                                                                    disabled={is_read_only}
                                                                    field="applicant_mailing_address2"
                                                                    label={"Mailing Address 2 (i.e. Suite #, Apartment #, etc.)"}   
                                                                    fullWidth
                                                                />
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <TextField disabled={is_read_only} field="applicant_city" label="City *" fullWidth />
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <Select
                                                                    disabled={is_read_only}
                                                                    field="applicant_state"
                                                                    label="State *"
                                                                    options={states}
                                                                    fullWidth
                                                                />
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <TextField disabled={is_read_only} field="applicant_zip" label="Zip *" fullWidth />
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <TextField disabled={is_read_only} field="applicant_phone" label="Phone *" fullWidth  usePhoneNumberFormat/>
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <TextField disabled={is_read_only} field="applicant_phone_ext" label="Phone Extension" fullWidth />
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <TextField disabled={is_read_only} field="applicant_email" label="Email *" fullWidth />
                                                            </Grid>
                                                        </>
                                                    )}
                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion>
                                        <Accordion defaultExpanded>
                                            <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ backgroundColor: "#ddd" }}>
                                                <Typography variant="subtitle1">
                                                    Permittee (Required)
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails style={{ backgroundColor: "f6f0f0" }}>
                                                <Grid container spacing={2} style={{ marginTop: 0 }}>
                                                    <Grid item xs={12}>
                                                        <Checkbox
                                                            eventHandle={checked => {
                                                                if (checked) {
                                                                    this.setState({ permittee_same_NO: false });
                                                                } else {
                                                                    this.setState({ permittee_same_NO: true });
                                                                }
                                                            }}
                                                            disabled={is_read_only}
                                                            label="Check if the same as the Primary Property Owner"
                                                            field="permittee_same"
                                                        />
                                                    </Grid>
                                                    {permittee_same_NO && (
                                                        <>
                                                            <Grid item xs={6}>
                                                                <TextField
                                                                    disabled={is_read_only}
                                                                    field="permittee_first_name"
                                                                    label="First Name *"
                                                                    fullWidth
                                                                />
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <TextField
                                                                    disabled={is_read_only}
                                                                    field="permittee_last_name"
                                                                    label="Last Name *"
                                                                    fullWidth
                                                                />
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <TextField
                                                                    disabled={is_read_only}
                                                                    field="permittee_organization"
                                                                    label="Organization / Company "
                                                                    fullWidth
                                                                />
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <TextField
                                                                    disabled={is_read_only}
                                                                    field="permittee_mailing_address"
                                                                    label="Mailing Address *"
                                                                    fullWidth
                                                                />
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <TextField
                                                                    disabled={is_read_only}
                                                                    field="permittee_mailing_address2"
                                                                    label={"Mailing Address 2 (i.e. Suite #, Apartment #, etc.)"}   
                                                                    fullWidth
                                                                />
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <TextField disabled={is_read_only} field="permittee_city" label="City *" fullWidth />
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <Select
                                                                    disabled={is_read_only}
                                                                    field="permittee_state"
                                                                    label="State *"
                                                                    options={states}
                                                                    fullWidth
                                                                />
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <TextField disabled={is_read_only} field="permittee_zip" label="Zip *" fullWidth />
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <TextField disabled={is_read_only} field="permittee_phone" label="Phone *" fullWidth  usePhoneNumberFormat/>
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <TextField disabled={is_read_only} field="permittee_phone_ext" label="Phone Extension" fullWidth />
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <TextField disabled={is_read_only} field="permittee_email" label="Email *" fullWidth />
                                                            </Grid>
                                                        </>
                                                    )}
                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion>
                                        <Typography variant="subtitle1">Additional Contacts</Typography>
                                        <Typography gutterBottom>
                                            If different from the primary owner, please provide contact information for another property owners
                                            subject to this application (secondary owners), a project representative (e.g., Consultant, Engineer,
                                            Arcitect, etc), and Erosion control Representative)
                                        </Typography>
                                        <Button
                                            onClick={() =>
                                                this.setState({ contactDialogOpen: true, contactEditing: null, formKey: window.performance.now() })
                                            }
                                            variant="contained"
                                            color="primary">
                                            Add Contact
                                        </Button>
                                        <Table style={{ marginTop: 8 }}>
                                            <EnhancedTableHead
                                                columnData={columnDataContacts}
                                                order={order}
                                                orderBy={orderBy}
                                                onRequestSort={this.handleRequestSort}
                                            />
                                            <TableBody>
                                                {temp_contacts.map(n => {
                                                    return (
                                                        <TableRow hover>
                                                            <CustomTableCell className={classes.nowrap}>
                                                                <Tooltip title="Edit">
                                                                    <Button
                                                                        color="primary"
                                                                        onClick={() =>
                                                                            this.setState({
                                                                                contactDialogOpen: true,
                                                                                contactEditing: n,
                                                                                formKey: window.performance.now()
                                                                            })
                                                                        }
                                                                        className={classes.deleteWidth}>
                                                                        <ViewIcon color="primary" />
                                                                    </Button>
                                                                </Tooltip>
                                                                <Tooltip title="Delete">
                                                                    <Button
                                                                        color="primary"
                                                                        onClick={() =>
                                                                            this.setState({ temp_contacts: temp_contacts.filter(tc => tc !== n) })
                                                                        }
                                                                        className={classes.deleteWidth}>
                                                                        <DeleteIcon color="primary" />
                                                                    </Button>
                                                                </Tooltip>
                                                            </CustomTableCell>
                                                            <CustomTableCell>{n.type}</CustomTableCell>
                                                            <CustomTableCell>{n.first_name}</CustomTableCell>
                                                            <CustomTableCell>{n.last_name}</CustomTableCell>
                                                            <CustomTableCell>{n.mailing_address}</CustomTableCell>
                                                            <CustomTableCell>{n.phone}</CustomTableCell>
                                                            <CustomTableCell>{n.email}</CustomTableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                                {temp_contacts.length < 1 && (
                                                    <TableRow>
                                                        <CustomTableCell colSpan={columnDataContacts.length} className={classes.centerAlign}>
                                                            No Additional Contacts Added
                                                        </CustomTableCell>
                                                    </TableRow>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </Grid>
                                )}
                                {activeStep === "PRINT" && (
                                    <Grid item xs={12} lg={10} xl={8} classes={{ root: classes.root }}>
                                        <Divider className={classes.printDivider} />
                                    </Grid>
                                )}
                                {(activeStep === 1 || activeStep === "PRINT") && (
                                    <Grid item xs={12} lg={10} xl={8} classes={{ root: classes.root }}>
                                         <Grid container spacing={2}>
                                            <Grid item xs={12} xl={10} classes={{ root: classes.root }}>
                                                <Typography variant="subtitle1" gutterBottom style={{marginBottom:-7, marginLeft:14}}>
                                                    <b>Instructions:</b> 
                                                </Typography>
                                                <Typography variant="subtitle1" gutterBottom style={{ marginLeft:16}}>
                                                    Step 1: Use the + or - buttons to zoom the map to your permit locations. Alternatively you can type an address or road intersection into the search box to zoom the map to the general area of your permit.
                                                </Typography>
                                                 <Typography variant="subtitle1" gutterBottom style={{  marginLeft:16}}>
                                                 Step 2: Click on the Mapping Tools button labeled "Add a New Permit Location". This will change your mouse cursor to a blue dot. Click on the map to place your location of your permit. For linear projects place it on the approximate mid-point of the project. For other projects place the point on the best place that represents the location of your project.
                                                </Typography>
                                                <Typography variant="subtitle1" gutterBottom style={{  marginLeft:16}}>
                                                Step 3: After you place the point on the map, the Project Location latitude/longitude fields will automatically populate. 
                                                </Typography>
                                                <Typography variant="subtitle1" gutterBottom style={{  marginLeft:16}}>
                                                Step 4: If you need to modify the location of the point location on the map, Click Delete Project Location button and repeat Step 2. Otherwise click the Next button to proceed to the Project Details step. 
                                                </Typography> 
                                            </Grid>
                                            <Grid item xs={12} xl={10} style={{ marginLeft: 15, marginRight:15 }} classes={{ root: classes.root }}>
                                                <BaseMapControlApplication
                                                    geometry={loaded_record && loaded_record.geometry?loaded_record.geometry:null}
                                                    // saveAddress={this.saveAddress}
                                                    disableSearch={loaded_record && loaded_record.geometry?true:false}
                                                    viewOnly
                                                    application={loaded_record}
                                                    setDialogLoading={this.setDialogLoading} 
                                                    // address={address}
                                                    // city={city}
                                                    // zip={zip} 
                                                    setDrawingFeature={this.setDrawingFeature}
                                                    drawingFeature={drawingFeature}
                                                    createPoint={createPoint}
                                                    setPoint={this.setPoint}
                                                    layers={layers}
                                                    cityID={"2"}  
                                                /> 
                                                
                                                {dialogLoading && ( 
                                                    <CircularProgress
                                                        size={72}
                                                        style={{ display: "table", marginLeft: "auto", marginRight: "auto", zIndex:90000 }}
                                                    /> 
                                               
                                                )} 
                                        </Grid> 
                                        </Grid>
                                        <Accordion defaultExpanded>
                                            <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ backgroundColor: "#ddd" }}>
                                                <Typography variant="subtitle1">
                                                    Project Location
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails style={{ backgroundColor: "f6f0f0" }}>
                                                <Grid container spacing={2} style={{ marginTop: 0 }}>
                                                    <Grid item xs={6}>
                                                        <TextField disabled={is_read_only} field="address_1" label="Address 1" fullWidth />
                                                    </Grid> 
                                                    <Grid item xs={6}>
                                                        <TextField disabled={is_read_only} field="zip" label="Zip" fullWidth />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField disabled={is_read_only} field="subdivision" label="Subdivision" fullWidth />
                                                    </Grid> 
                                                    <Grid item xs={6}>
                                                        <TextField disabled={is_read_only} field="lot_num" label="Lot Number" fullWidth />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField disabled={is_read_only} field="parcel" label="Parcel Number" fullWidth />
                                                    </Grid> 
                                                    <Grid item xs={6}>
                                                        <TextField disabled={is_read_only} field="notes" label="Notes" fullWidth />
                                                    </Grid>
                                              
                                                    <Grid item xs={6}>
                                                        <TextField disabled={is_read_only} field="latitude" label="Latitude" fullWidth />
                                                    </Grid> 
                                                    <Grid item xs={6}>
                                                        <TextField disabled={is_read_only} field="longitude" label="Longitude" fullWidth />
                                                    </Grid> 
                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                )}
                                {activeStep === "PRINT" && (
                                    <Grid item xs={12} lg={10} xl={8} classes={{ root: classes.root }}>
                                        <Divider className={classes.printDivider} />
                                    </Grid>
                                )}
                             
                                {(activeStep === 2 || activeStep === "PRINT") && (
                                    <Grid item xs={12} lg={10} xl={8} classes={{ root: classes.root }}>
                                        <Typography variant="subtitle1"><u>Attachments</u></Typography>
                                        <Typography gutterBottom>
                                            Please attached all electronic files associated with your applications. This could include but is not
                                            limited to the following:
                                            <ul>
                                                <li>Signed Application - Signature Page</li>
                                                <li>Receipt for Application Fee</li>
                                                <li>Plans- construction, civil plans, SWPPP, landscape, etc.</li> 
                                                <li>Drainage Report- adequate outlet assessment, detention assessment, quantity management, quality management</li>
                                                <li>Operation and Maintenance Manual- for all Stormwater Management Facilities </li> 
                                                <li>IDEM Draft NOI</li>
                                                <li>Other</li> 
                                            </ul>
                                        </Typography>
                                        <Button
                                            onClick={() =>
                                                this.setState({
                                                    attachmentDialogOpen: true,
                                                    attachmentEditing: null,
                                                    attachmentFormKey: window.performance.now()
                                                })
                                            }
                                            variant="contained"
                                            color="primary">
                                            Add Attachment
                                        </Button>
                                        <Table style={{ marginTop: 8 }}>
                                            <EnhancedTableHead
                                                columnData={columnDataAttachments}
                                                order={attachmentOrder}
                                                orderBy={attachmentOrderBy}
                                                onRequestSort={this.handleRequestSort2}
                                            />
                                            <TableBody>
                                                {temp_attachments.map(n => {
                                                    return (
                                                        <TableRow hover>
                                                            <CustomTableCell className={classes.nowrap}>
                                                                {n.file.includes("https") && (
                                                                    <Tooltip title="Open Link">
                                                                        <Button
                                                                            color="primary"
                                                                            onClick={() => {
                                                                                window.open(n.file);
                                                                            }}
                                                                            className={classes.deleteWidth}>
                                                                            <ViewIcon color="primary" />
                                                                        </Button>
                                                                    </Tooltip>
                                                                )}
                                                                <Tooltip title="Delete">
                                                                    <Button
                                                                        color="primary"
                                                                        onClick={() =>
                                                                            this.setState({
                                                                                temp_attachments: temp_attachments.filter(tc => tc !== n)
                                                                            })
                                                                        }
                                                                        className={classes.deleteWidth}>
                                                                        <DeleteIcon color="primary" />
                                                                    </Button>
                                                                </Tooltip>
                                                            </CustomTableCell>
                                                            <CustomTableCell>{n.name}</CustomTableCell>
                                                            <CustomTableCell>{this.formatDate(n.date_uploaded)}</CustomTableCell>
                                                            <CustomTableCell>{n._type}</CustomTableCell>
                                                            <CustomTableCell>{n.notes}</CustomTableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                                {temp_attachments.length < 1 && (
                                                    <TableRow>
                                                        <CustomTableCell colSpan={columnDataAttachments.length} className={classes.centerAlign}>
                                                            No Attachments Added
                                                        </CustomTableCell>
                                                    </TableRow>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </Grid>
                                )}
                                {activeStep === "PRINT" && (
                                    <Grid item xs={12} lg={10} xl={8} classes={{ root: classes.root }}>
                                        <Divider className={classes.printDivider} />
                                    </Grid>
                                )}
                                {(activeStep === 3 || activeStep === "PRINT") && (
                                    <Grid item xs={12} lg={10} xl={8} classes={{ root: classes.root }}>
                                        {/* <Typography variant="subtitle1" gutterBottom>
                                            Applicant Signature
                                        </Typography> */}
                                        <Typography variant="subtitle1" gutterBottom>
                                            <u>Applicant Acknowledgement</u>
                                        </Typography>
                                        <table>
                                            <tr>
                                                <td style={{ whiteSpace: "nowrap", paddingRight: 16 }}>
                                                    <Checkbox disabled={false} label="I AGREE *" field="signature" />
                                                </td>
                                                <td>
                                                    <Typography gutterBottom style={{marginLeft:14}}>
                                                        I understand that the following practices are <u>not</u> allowed: 
                                                         <li>Straw bale filters are not acceptable for use.</li>
                                                         <li>Cationic polymers are not authorized for use in the State of Indiana. The use of anionic polymers on the project site for sediment control shall be in accordance with current State of Indiana standards and specifications and the use shall be approved by IDEM and the City.</li>
                                                         <li>Coconut mat or fabric on top of inlets shall not be the only inlet protection practice.</li>
                                                         <li>Rock-filled bags are not allowed.</li>
                                                       
                                                    </Typography>
                                                   
                                                </td>
                                            </tr>
                                        </table>
                                        
                                        <table>
                                            <tr>
                                                <td style={{ whiteSpace: "nowrap", paddingRight: 16 }}>
                                                    <Checkbox disabled={false} label="I AGREE *" field="signature2" />
                                                </td>
                                                <td>
                                                    <Typography style={{marginLeft:14}} >
                                                        I agree to electronic communication such as but to limited to email correspondence of review statuses, review reports, inspection reports, fine and violation notifications. 
                                                    </Typography>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ whiteSpace: "nowrap", paddingRight: 16 }}>
                                                    <Checkbox disabled={false} label="I AGREE *" field="signature3" />
                                                </td>
                                                <td>
                                                    <Typography style={{marginLeft:14}}>
                                                        Temporary downspout extenders must be used until the site is stabilized.
                                                    </Typography>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ whiteSpace: "nowrap", paddingRight: 16 }}>
                                                    <Checkbox disabled={false} label="I AGREE *" field="signature4" />
                                                </td>
                                                <td>
                                                    <Typography style={{marginLeft:14}}>
                                                        If this application is not completed correctly with the appropriate information, the permit may be revoked.
                                                    </Typography>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ whiteSpace: "nowrap", paddingRight: 16 }}>
                                                    <Checkbox disabled={false} label="I AGREE *" field="signature5" />
                                                </td>
                                                <td>
                                                    <Typography style={{marginLeft:14}}>
                                                    I have read the terms & conditions, reviewed the City Standards and agree that the individual lot implementation plan is consistent with the stormwater management permit, as approved by the City Engineering Department for the larger project.
                                                    </Typography>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ whiteSpace: "nowrap", paddingRight: 16 }}>
                                                    <Checkbox disabled={false} label="I AGREE *" field="signature6" />
                                                </td>
                                                <td>
                                                    <Typography style={{marginLeft:14}}>
                                                        I am the legal owner of this property and/or I have the legal authority to sign for the owner.  
                                                    </Typography>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ whiteSpace: "nowrap", paddingRight: 16 }}>
                                                    <Checkbox disabled={false} label="I AGREE *" field="signature7" />
                                                </td>
                                                <td>
                                                    <Typography style={{marginLeft:14}}>
                                                    "BY CHECKING I AGREE, I UNDERSTAND THAT, AS THE PERMITTEE, I AM LEGALLY ACCOUNTABLE TO ENSURE COMPLIANCE WITH THE TERMS AND CONDITIONS OF THE PERMIT. I UNDERSTAND THAT I AM NOT AUTHORIZED TO BEGIN THE PROJECT UNTIL I HAVE RECEIVED THE PERMIT, IF THE PROJECT IS MODIFIED, I WILL OBTAIN APPROVAL FROM THE CITY OF COLUMBUS BEFORE I CONTINUE WITH THE PROJECT. I AUTHORIZE THE CITY OF COLUMBUS, AND ITS AGENTS, EMPLOYEES, OFFICERS, AND CONTRACTORS, TO ENTER THE PROJECT SITE TO PERFORM ANY INSPECTIONS OF WORK AUTHORIZED BY THE PERMIT OR ANY APPLICABLE LAW." IF APPLICANT IS NOT THE PERMITTEE, I AGREE THAT I HAVE BEEN AUTHORIZED TO SUBMIT THE APPLICATION ON BEHALF OF THE PERMITTEE."
                                                    </Typography>
                                                </td>
                                            </tr>
                                        </table>
                                        {/* <Typography variant="subtitle1" gutterBottom>
                                            Payment Verification
                                        </Typography>
                                        <table>
                                            <tr>
                                                <td style={{ whiteSpace: "nowrap", paddingRight: 16 }}>
                                                    <Checkbox disabled={false} label="I AGREE *" field="signature6" />
                                                </td>
                                                <td>
                                                    <Typography>
                                                        “I agree to submit payment through mail or electronically through the City’s 
                                                        payment system at: <a rel="noopener noreferrer" href="https://pay.paygov.us/EndUser/PaymentAgency.aspx?ttid=20871" target="_blank">https://pay.paygov.us/EndUser/PaymentAgency.aspx?ttid=20871</a>”
                                                    </Typography>
                                                </td>
                                            </tr>
                                        </table> */}
                                    </Grid>
                                )}
                                {activeStep !== "PRINT" && (
                                    <Grid item xs={12} lg={10} xl={8} className={classes.rightAlign} classes={{ root: classes.root }} style={{marginTop:10}}>
                                        <Button
                                            disabled={activeStep === 0 || loaded_record}
                                            onClick={() => this.setState({ activeStep: activeStep - 1 })}
                                            className={classes.button}>
                                            Back
                                        </Button>
                                        <Button onClick={() => this.handleNext()} variant="contained" color="primary" style={{ marginLeft: 16 }}>
                                            {activeStep === 3 ? "Submit Permit Application" : "Next"}
                                        </Button>
                                    </Grid>
                                )}
                            </Grid>
                        </form>
                    )}
                </Form>
                <Dialog open={contactDialogOpen} classes={{ paper: classes.minWidth }}>
                    <Toolbar>
                        <Typography variant="h6" className={classes.flex}>
                            Additional Contact
                        </Typography>
                        <IconButton aria-label="Close Dialog" onClick={() => this.setState({ contactDialogOpen: false })}>
                            <Close />
                        </IconButton>
                    </Toolbar>
                    <DialogContent className={classes.dialogMin}>
                        <DialogContentText>
                            <Form
                                dontValidateOnMount={true}
                                validateOnSubmit={true}
                                key={formKey}
                                defaultValues={contactEditing}
                                validateError={this.errorValidatorContact}
                                onSubmit={values => this.addContact(values)}>
                                {formApi => (
                                    <form onSubmit={formApi.submitForm}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={6}>
                                                <Select
                                                    field="type"
                                                    label="Role *"
                                                    options={MAKE_OPTIONS([ 
                                                        "Secondary Property Owner", 
                                                        "Other"
                                                    ])}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={6} />
                                            <Grid item xs={6}>
                                                <TextField field="first_name" label="First Name *" fullWidth />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField field="last_name" label="Last Name *" fullWidth />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField field="organization" label="Organization / Company" fullWidth />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField field="mailing_address" label="Mailing Address *" fullWidth />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField field="mailing_address2"  
                                                    label={"Mailing Address 2 (Suite #, Apartment #, etc.)"} 
                                                    fullWidth />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField field="city" label="City *" fullWidth />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Select field="state" label="State *" options={states} fullWidth />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField field="zip" label="Zip *" fullWidth />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField field="phone" label="Phone *" fullWidth  usePhoneNumberFormat/>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField field="phone_ext" label="Phone Extension" fullWidth />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField field="email" label="Email *" fullWidth />
                                            </Grid>
                                            <Grid item xs={6}></Grid>
                                            <Grid item xs={6}>
                                                <Button fullWidth onClick={() => this.setState({ contactDialogOpen: false })}>
                                                    Cancel
                                                </Button>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Button fullWidth type="submit" variant="contained" color="primary">
                                                    Save
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </form>
                                )}
                            </Form>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
                <Dialog open={attachmentDialogOpen} classes={{ paper: classes.minWidth }}>
                    <Toolbar>
                        <Typography variant="h6" className={classes.flex}>
                            Attachment
                        </Typography>
                        <IconButton aria-label="Close Dialog" onClick={() => this.setState({ attachmentDialogOpen: false })}>
                            <Close />
                        </IconButton>
                    </Toolbar>
                    <DialogContent className={classes.dialogMin}>
                        <DialogContentText>
                            <Form
                                dontValidateOnMount={true}
                                validateOnSubmit={true}
                                key={attachmentFormKey}
                                defaultValues={attachmentEditing}
                                validateError={this.errorValidatorAttachment}
                                onSubmit={values => this.addAttachment(values)}>
                                {formApi => (
                                    <form onSubmit={formApi.submitForm}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <FileInput field="file" id="file_upl" fullWidth label="File *" />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField field="name" label="Name *" fullWidth />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Select
                                                    field="_type"
                                                    label="Type *"
                                                    options={MAKE_OPTIONS([
                                                        "Signed Application",
                                                        "Receipt for Application Fee",
                                                        "Plans",
                                                        "Drainage Report",
                                                        "Operation and Maintenance Manual",
                                                        "DRAFT IDEM NOI",
                                                        "Other", 
                                                    ])}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField field="notes" multiline rows={4} label="Notes" fullWidth />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Button fullWidth onClick={() => this.setState({ attachmentDialogOpen: false })}>
                                                    Cancel
                                                </Button>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Button fullWidth type="submit" variant="contained" color="primary">
                                                    Save
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </form>
                                )}
                            </Form>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
            </AppContainerApplication>
        );
    }
}

ColumbusResidentialApp = connect(
    (state, ownProps) => ({
        // application: getApplication(state, ownProps),
        authState: state.auth // Admins will have an authstate
    }),
    {
        ...ApplicationRecord.actions,
        ...ApplicationRecordStatus.actions,
        ...ColumbusResidential.actions,
        ...ColumbusResidentialContact.actions,
        ...ColumbusResidentialAttachment.actions
    }
)(ColumbusResidentialApp);

export default withStyles(styles)(withRouter(ColumbusResidentialApp));
