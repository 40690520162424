import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Form } from "react-form";
import { connect } from "react-redux";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepButton from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Divider from "@material-ui/core/Divider";
import DialogContent from "@material-ui/core/DialogContent";
import Accordion from "@material-ui/core/Accordion";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import DialogContentText from "@material-ui/core/DialogContentText";
import Toolbar from "@material-ui/core/Toolbar";
import { withStyles } from "@material-ui/core";

import ViewIcon from "@material-ui/icons/Visibility";
import Close from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";

import AppContainerApplication from "../../common/AppContainerApplication";
import TextField from "../../common/TextField";
import Select from "../../common/Select";
import CheckboxGroup from "../../common/CheckboxGroup";
// import ReactSelect from "../../common/ReactSelect";
import DatePicker from "../../common/DatePicker2";
import FileInput from "../../common/FileInput";
import Checkbox from "../../common/Checkbox";
// import Subquestion from "../../common/Subquestion";
import HelpLabel from "../../common/HelpLabel";
import { createSelector } from "../../common/orm";
import states from "../../common/statesmn.json";
import EnhancedTableHead from "../../common/EnhancedTableHead";
import CustomTableCell from "../../common/TableCell";
import { ApplicationRecord, ApplicationRecordStatus, SrwdPermit, SrwdPermitAttachment, SrwdPermitContact } from "../../page/models";

const styles = (theme) => ({
    deleteWidth: {
        minWidth: 34,
        width: 34,
        marginRight: 8,
        marginTop: 2,
        marginBottom: 2,
    },
    centerAlign: {
        textAlign: "center",
    },
    centerAlign2: {
        textAlign: "center",
        display: "inline-block",
        width: "100%",
    },
    nowrap: {
        whiteSpace: "nowrap",
    },
    rightAlign: {
        textAlign: "right",
        marginRight: 15,
    },
    flex: {
        flex: 1,
    },
    printDivider: {
        height: 8,
        backgroundColor: "rgba(0, 0, 0, 0.42)",
        marginTop: 16,
        marginBottom: 8,
    },
    dialogMin: {
        minWidth: 400,
    },
    minWidth: {
        minWidth: 600,
    },
    root: {
        flexBasis: "100%",
        maxWidth: "100%",
    },
});

function MAKE_OPTIONS(table) {
    return table.map((row) => ({
        label: row,
        value: row,
    }));
}

const getApplication = createSelector(
    (state, ownProps) => ownProps.match.params["hash"],
    (session, hash) => {
        return session.SrwdPermit.filter((p) => p.hash === hash).toModelArray()[0];
    }
);

class Srwd extends Component {
    state = {
        activeStep: 0,
        contactDialogOpen: false,
        contactEditing: null,
        formKey: null,
        order: "desc",
        orderBy: null,
        temp_contacts: [],
        attachmentDialogOpen: false,
        attachmentEditing: null,
        attachmentFormKey: null,
        attachmentOrder: "desc",
        attachmentOrderBy: null,
        temp_attachments: [],
        loading: false,
        new_save: false,
        new_save2: false,
        mainFormKey: null,
        entryError: false,
    };

    componentDidMount() {
        const { match, ormSrwdPermitLoadDetailChild, ormSrwdPermitAttachmentLoadDetailChild, ormSrwdPermitContactLoadDetailChild } = this.props;
        var component = this;

        if (match.params && match.params.hash) {
            ormSrwdPermitLoadDetailChild(match.params.hash, function () {
                component.setState({ mainFormKey: window.performance.now() });
            });
            ormSrwdPermitContactLoadDetailChild(match.params.hash, function (data) {
                component.setState({ temp_contacts: data });
            });
            ormSrwdPermitAttachmentLoadDetailChild(match.params.hash, function (data) {
                component.setState({ temp_attachments: data });
            });
        }
        if (match.params && match.params.print) {
            component.setState({ activeStep: "PRINT" });
        }
    }

    errorValidator = (values) => {
        const isRequired = (val) => {
            return !val ? "Required" : null;
        };
        const validateEmail = (email) => {
            var re =
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return !re.test(email) ? "Invalid Email Address" : null;
        };

        var valObj = {
            owner_first_name: isRequired(values.owner_first_name),
            owner_last_name: isRequired(values.owner_last_name),
            owner_mailing_address: isRequired(values.owner_mailing_address),
            owner_phone: isRequired(values.owner_phone),
            owner_city: isRequired(values.owner_city),
            owner_state: isRequired(values.owner_state),
            owner_zip: isRequired(values.owner_zip),
            owner_email: validateEmail(values.owner_email),
            project_name: isRequired(values.project_name),
            county: isRequired(values.county),
            pin: !values.project_section && !values.project_township && !values.project_range ? isRequired(values.pin) : null,

            construction_start:isRequired(values.construction_start),
            project_description: isRequired(values.project_description),
            signature: isRequired(values.signature),
            signature1: isRequired(values.signature),
            signature2: values.secondary_last_name ? isRequired(values.signature2) : null,
        };

        var alerts = "";
        var ai = 6;
        if (valObj["owner_first_name"] === "Required") {
            alerts += "Property Owner First Name (Step 2)\n";
            ai = 1;
        }
        if (valObj["owner_last_name"] === "Required") {
            alerts += "Property Owner Last Name (Step 2)\n";
            ai = 1;
        }
        if (valObj["owner_mailing_address"] === "Required") {
            alerts += "Property Owner Mailing Address (Step 2)\n";
            ai = 1;
        }
        if (valObj["owner_city"] === "Required") {
            alerts += "Property Owner City (Step 2)\n";
            ai = 1;
        }
        if (valObj["owner_state"] === "Required") {
            alerts += "Property Owner State (Step 2)\n";
            ai = 1;
        }
        if (valObj["owner_zip"] === "Required") {
            alerts += "Property Owner Zip (Step 2)\n";
            ai = 1;
        }
        if (valObj["owner_phone"] === "Required") {
            alerts += "Property Owner Phone (Step 2)\n";
            ai = 1;
        }
        if (valObj["owner_email"] === "Invalid Email Address") {
            alerts += "Property Owner Email (Step 2)\n";
            ai = 1;
        }

        if (valObj["county"] === "Required") {
            alerts += "Project County (Step 3)\n";
            ai = 2;
        }
        if (valObj["pin"] === "Required") {
            alerts += "Project pin (Step 3)\n";
            ai = 2;
        }
        if (valObj["project_description"] === "Required") {
            alerts += "Project Description (Step 3)\n";
            ai = 2;
        }
        if (valObj["construction_start"] === "Required") {
            alerts += "Intended Construction Date (Step 3)\n";
            ai = 2;
        }
        if (valObj["signature"] === "Required") {
            alerts += "Applicant Signature (Step 6)\n";
        }
        if (valObj["signature1"] === "Required") {
            alerts += "Fee Understand (Step 6)\n";
        }
        if (valObj["signature2"] === "Required") {
            alerts += "Form Scanned (Step 6)\n";
        }
        if (alerts) {
            alert("The following fields are missing or invalid:\n" + alerts);
            this.setState({ activeStep: ai });
        }

        return valObj;
    };

    errorValidatorAttachment = (values) => {
        const isRequired = (val) => {
            return !val ? "Required" : null;
        };
        var valObj = {
            file: isRequired(values.file),
            name: isRequired(values.name),
        };

        return valObj;
    };

    submitApplication = (values) => {
        const {
            application,
            ormApplicationRecordCreate,
            ormApplicationRecordStatusCreate,
            ormSrwdPermitCreate,
            ormSrwdPermitContactCreate,
            ormSrwdPermitAttachmentCreate,
            ormSrwdPermitUpdatePublic,
        } = this.props;
        const { temp_attachments, temp_contacts } = this.state;

        const _this = this;
        this.setState({ loading: true });

        // Editing
        if (application) {
            ormSrwdPermitUpdatePublic({
                id: application.id,
                ...values,
            });

            var i = 0;
            var j = 0;
            temp_contacts.forEach(function (tc) {
                if (tc.id) {
                    /*ormRpbcwdPermitContactUpdate({
                        id: tc.id,
                        ...tc
                    });*/
                } else {
                    i++;
                    ormSrwdPermitContactCreate({
                        srwdpermit: application.id,
                        ...tc,
                    }).then((_z) => {
                        j++;
                        if (i === j) {
                            _this.setState({ loading: false, new_save2: true });
                        }
                    });
                }
            });
            temp_attachments.forEach(function (tc) {
                if (tc.id) {
                    /*ormRpbcwdPermitAttachmentUpdate({
                        id: tc.id,
                        ...tc
                    });*/
                } else {
                    i++;
                    ormSrwdPermitAttachmentCreate({
                        rpbcwdpermit: application.id,
                        ...tc,
                    }).then((_z) => {
                        j++;
                        if (i === j) {
                            _this.setState({ loading: false, new_save2: true });
                        }
                    });
                }
            });
            if (i === 0) {
                // No attachments or contacts - its finished loading
                setTimeout(function () {
                    _this.setState({ loading: false, new_save2: true });
                }, 1000);
            }
        } else {
            // New
            ormApplicationRecordCreate({
                application: 9,
            }).then((ar) => {
                ormApplicationRecordStatusCreate({
                    applicationrecord: ar.id,
                    status: "Received",
                });
                ormSrwdPermitCreate({
                    applicationrecord: ar.id,
                    ...values,
                }).then((rpb) => {
                    if (rpb && rpb.error) {
                        this.setState({ entryError: true, loading: false });
                        fetch("/error/post", {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                            },
                            body: JSON.stringify({
                                error: JSON.stringify(values),
                                error_info: rpb.error.toString(),
                            }),
                        });
                    } else {
                        var i = 0;
                        var j = 0;
                        temp_contacts.forEach(function (tc) {
                            i++;
                            ormSrwdPermitContactCreate({
                                srwdpermit: rpb.id,
                                ...tc,
                            }).then((_z) => {
                                j++;
                                if (i === j) _this.setState({ loading: false, new_save: true });
                            });
                        });
                        temp_attachments.forEach(function (tc) {
                            i++;
                            ormSrwdPermitAttachmentCreate({
                                srwdpermit_id: rpb.id,
                                ...tc,
                            }).then((_z) => {
                                j++;
                                if (i === j) _this.setState({ loading: false, new_save: true });
                            });
                        });
                        if (i === 0) {
                            // No attachments or contacts - its finished loading
                            _this.setState({ loading: false, new_save: true });
                        }
                    }
                });
            });
        }
    };
    addContact = (values) => {
        var { contactEditing, temp_contacts } = this.state;

        if (!contactEditing) {
            temp_contacts.push(values);
            this.setState({ temp_contacts: temp_contacts });
        } else {
            var temp = temp_contacts.filter((tc) => tc !== contactEditing);
            temp.push(values);
            this.setState({ temp_contacts: temp });
        }

        this.setState({ contactDialogOpen: false });
    };
    errorValidatorContact = (values) => {
        const isRequired = (val) => {
            return !val ? "Required" : null;
        };
        var valObj = {
            first_name: isRequired(values.first_name),
            last_name: isRequired(values.last_name),
            phone: isRequired(values.phone),
        };

        return valObj;
    };
    addAttachment = (values) => {
        var { attachmentEditing, temp_attachments } = this.state;

        if (!attachmentEditing) {
            temp_attachments.push(values);
            this.setState({ temp_attachments: temp_attachments });
        } else {
            var temp = temp_attachments.filter((tc) => tc !== attachmentEditing);
            temp.push(values);
            this.setState({ temp_attachments: temp });
        }

        this.setState({ attachmentDialogOpen: false });
    };

    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = "desc";

        if (this.state.orderBy === property && this.state.order === "desc") {
            order = "asc";
        }

        order === "desc"
            ? this.state.temp_contacts.sort((a, b) => (b[orderBy].toUpperCase() < a[orderBy].toUpperCase() ? -1 : 1))
            : this.state.temp_contacts.sort((a, b) => (a[orderBy].toUpperCase() < b[orderBy].toUpperCase() ? -1 : 1));

        this.setState({ order, orderBy });
    };

    handleRequestSort2 = (event, property) => {
        const orderBy = property;
        let order = "desc";

        if (this.state.orderBy === property && this.state.order === "desc") {
            order = "asc";
        }

        order === "desc"
            ? this.state.temp_attachments.sort((a, b) => (b[orderBy].toUpperCase() < a[orderBy].toUpperCase() ? -1 : 1))
            : this.state.temp_attachments.sort((a, b) => (a[orderBy].toUpperCase() < b[orderBy].toUpperCase() ? -1 : 1));

        this.setState({ order, orderBy });
    };

    handleNext = (index) => {
        const { activeStep } = this.state;
        if (index || index === 0) {
            this.setState({ activeStep: index });
        } else if (activeStep < 5) this.setState({ activeStep: activeStep + 1 });
        else {
            this.formApi.submitForm();
        }
    };

    render() {
        const { classes, application } = this.props;
        const {
            activeStep,
            attachmentDialogOpen,
            attachmentEditing,
            attachmentFormKey,
            attachmentOrder,
            attachmentOrderBy,
            temp_attachments,
            loading,
            new_save,
            mainFormKey,
            new_save2,
            entryError,
            temp_contacts,
            order,
            orderBy,
            contactDialogOpen,
            contactEditing,
            formKey,
        } = this.state;

        var is_read_only = false;
        if (
            application &&
            application.applicationrecord &&
            (application.applicationrecord.status === "Received" ||
                application.applicationrecord.status === "For Review" ||
                application.applicationrecord.status === "Incomplete" ||
                application.applicationrecord.status === "Approved" ||
                application.applicationrecord.status === "Withdrawn Administratively" ||
                application.applicationrecord.status === "Withdrawn by Applicant")
        )
            is_read_only = true;

        var columnDataAttachments = [
            { id: "actions", numeric: false, label: "", allowSort: false, width: "20px" },
            { id: "name", numeric: false, label: "Name", allowSort: true },
            { id: "_type", numeric: false, label: "Type", allowSort: true },
            { id: "notes", numeric: false, label: "Notes", allowSort: true },
        ];
        var columnDataContacts = [
            { id: "actions", numeric: false, label: "", allowSort: false, width: "20px" },
            { id: "type", numeric: false, label: "Type", allowSort: true },
            { id: "first_name", numeric: false, label: "First Name", allowSort: true },
            { id: "last_name", numeric: false, label: "Last Name", allowSort: true },
            { id: "mailing_address", numeric: false, label: "Address", allowSort: true },
            { id: "phone", numeric: false, label: "Phone", allowSort: true },
            { id: "email", numeric: false, label: "Email", allowSort: true },
        ];

        if (new_save) {
            return (
                <AppContainerApplication city_id={window.location.origin.includes("ms4prod") ? "12" : "2"} page_loading={loading}>
                    <Grid container spacing={1} style={{ padding: "0 12px" }}>
                        <Grid item xs={12} xl={10}>
                            <Typography>
                                This is a confirmation that the permit application has been submitted successfully. A copy of the permit application
                                has been emailed to the primary property owner's email address. Please check your spam and junk folders if you have
                                not received it or contact the district. The district will contact you as the application is being reviewed.
                            </Typography>
                        </Grid>
                    </Grid>
                </AppContainerApplication>
            );
        }
        if (entryError) {
            return (
                <AppContainerApplication city_id={window.location.origin.includes("ms4prod") ? "12" : "2"} page_loading={loading}>
                    <Grid container spacing={1} style={{ padding: "0 12px" }}>
                        <Grid item xs={12} xl={10}>
                            <Typography>
                                This permit application was 'not' successfully saved. Please contact the permit 'managing' staff to alert them of the
                                entry failure.
                            </Typography>
                        </Grid>
                    </Grid>
                </AppContainerApplication>
            );
        }

        if (new_save2) {
            return (
                <AppContainerApplication city_id={window.location.origin.includes("ms4prod") ? "12" : "2"} page_loading={loading}>
                    <Grid container spacing={1} style={{ padding: "0 12px" }}>
                        <Grid item xs={12} xl={10}>
                            <Typography>This is a confirmation that the permit application has been resubmitted successfully.</Typography>
                        </Grid>
                    </Grid>
                </AppContainerApplication>
            );
        }
        if (activeStep === "PRINT")
            return (
                <AppContainerApplication city_id={window.location.origin.includes("ms4prod") ? "12" : "2"} page_loading={loading}>
                    <Grid container spacing={1} style={{ padding: "0 12px" }}>
                        <Grid item xs={12} xl={10}>
                            <Typography>Permit Printed.</Typography>
                        </Grid>
                    </Grid>
                </AppContainerApplication>
            );

        return (
            <AppContainerApplication
                city_id={"45"} //window.location.origin.includes("ms4prod") ? "45" : "2"}
                page_loading={loading}
                printing={activeStep === "PRINT"}>
                <Form
                    getApi={(el) => (this.formApi = el)}
                    defaultValues={application}
                    key={mainFormKey}
                    dontValidateOnMount={true}
                    validateOnSubmit={true}
                    validateError={(values) => this.errorValidator(values)}
                    onSubmit={(values) => this.submitApplication(values)}>
                    {(formApi) => (
                        <form onSubmit={formApi.submitForm}>
                            <>
                                {application && application.applicationrecord && application.applicationrecord.status && (
                                    <Grid container spacing={1} style={{ backgroundColor: "#a3a3a3", marginTop: -15 }}>
                                        <Grid item xs={12} xl={10} classes={{ root: classes.root }}>
                                            <Typography className={classes.centerAlign} variant="h6">
                                                Permit Status: {application && application.applicationrecord.status}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                )}
                                <Grid container spacing={1} style={{ padding: "0 12px" }}>
                                    <Grid item xs={12} xl={10} classes={{ root: classes.root }}>
                                        <Typography className={classes.centerAlign} variant="h6">
                                            Permit Application Form {application && application.permit_number && "#" + application.permit_number}
                                        </Typography>{" "}
                                        <Grid item xs={12} xl={10} className={classes.rightAlign} classes={{ root: classes.root }}></Grid>
                                        <Typography className={classes.centerAlign}>
                                            <b>IMPORTANT:</b> The entire permit application must be filled out at one time and progress will not be
                                            saved so please plan accordingly.
                                        </Typography>
                                        <Typography variant="caption" className={classes.centerAlign2}>
                                            Required Fields are marked with an asterisk (*)
                                        </Typography>
                                    </Grid>
                                    {activeStep !== "PRINT" && (
                                        <Grid item xs={12} xl={10} classes={{ root: classes.root }}>
                                            <Stepper style={{ padding: 8 }} alternativeLabel nonLinear activeStep={activeStep}>
                                                {[
                                                    "Instructions",
                                                    "Contact Info",
                                                    "Project Details",
                                                    "Applicable Rules",
                                                    "Attach Documents",
                                                    "Review and Submit",
                                                ].map((label, index) => {
                                                    return (
                                                        <Step key={label}>
                                                            <StepButton style={{ cursor: "pointer" }} onClick={() => this.handleNext(index)}>
                                                                {label}
                                                            </StepButton>
                                                        </Step>
                                                    );
                                                })}
                                            </Stepper>
                                        </Grid>
                                    )}
                                    {activeStep === 0 && (
                                        <Grid item xs={12} xl={10} style={{ marginLeft: 15 }} classes={{ root: classes.root }}>
                                            <Typography variant="subtitle1">
                                                <b>
                                                    {"1. "}
                                                    <u>Preparation</u>
                                                </b>
                                            </Typography>
                                            <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                {`\u2022`} As you work through the online application you will not be able to save your progress for
                                                submittal at a later time.
                                            </Typography>
                                            <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                {`\u2022`} Please have all the required information on hand to ensure you are able to submit a
                                                complete application.
                                            </Typography>
                                            <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                {`\u2022`} For guidance about the required information as well as the fee schedule and a printable
                                                permit application, please see the{" "}
                                                <a
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    href="https://srwdmn.org/wp-content/uploads/2021/03/2021.3.17-SRWD-Permit-Packet-Fillable-Comments.pdf">
                                                    SRWD Permit Application Packet
                                                </a>{" "}
                                                or contact the SRWD office.
                                            </Typography>
                                            <br />
                                            <Typography variant="subtitle1">
                                                <b>
                                                    {"2. "}
                                                    <u>Submit Application</u>
                                                </b>
                                            </Typography>
                                            <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                {`\u2022`} Once you have compiled the required information, complete the permit application and
                                                submit. Again, your progress will not be saved for submittal at a later time.
                                            </Typography>
                                            <br />
                                            <Typography variant="subtitle1">
                                                <b>
                                                    {"3. "}
                                                    <u>Application Fee</u>
                                                </b>
                                            </Typography>
                                            <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                {`\u2022`} Your submitted application will be considered <u>incomplete</u> until we receive your
                                                permit application fee. See the{" "}
                                                <a
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    href="https://srwdmn.org/wp-content/uploads/2021/03/2021.3.17-SRWD-Permit-Packet-Fillable-Comments.pdf">
                                                    SRWD Permit Application Packet
                                                </a>{" "}
                                                for a fee schedule
                                            </Typography>
                                            <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                {`\u2022`} Please mail or drop off your application fee at the SRWD office:{" "}
                                                <b>642 Lincoln Rd, Sauk Center, MN 56378. Phone # (320)-352-2231</b>
                                            </Typography>
                                        </Grid>
                                    )}
                                    {activeStep === 1 && (
                                        <Grid item xs={12} xl={10} style={{ marginLeft: 15 }} classes={{ root: classes.root }}>
                                            <Typography>Please add contacts associated with the application.</Typography>
                                            <Accordion defaultExpanded>
                                                <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ backgroundColor: "#ddd" }}>
                                                    <Typography variant="subtitle1" gutterBottom>
                                                        Primary Property Owner (required)
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails style={{ paddingBottom: 8, backgroundColor: "f6f0f0" }}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                disabled={is_read_only}
                                                                field="owner_first_name"
                                                                label="First Name *"
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                disabled={is_read_only}
                                                                field="owner_last_name"
                                                                label="Last Name *"
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                disabled={is_read_only}
                                                                field="owner_organization"
                                                                label="Organization / Company"
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                disabled={is_read_only}
                                                                field="owner_mailing_address"
                                                                label="Mailing Address *"
                                                                fullWidth
                                                            />
                                                        </Grid>

                                                        <Grid item xs={6}>
                                                            <TextField disabled={is_read_only} field="owner_city" label="City *" fullWidth />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Select
                                                                disabled={is_read_only}
                                                                field="owner_state"
                                                                label="State *"
                                                                options={states}
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField disabled={is_read_only} field="owner_zip" label="Zip *" fullWidth />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField disabled={is_read_only} field="owner_phone" label="Phone *" fullWidth />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField disabled={is_read_only} field="owner_fax" label="Fax" fullWidth />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField disabled={is_read_only} field="owner_email" label="Email *" fullWidth />
                                                        </Grid>
                                                    </Grid>
                                                </AccordionDetails>
                                            </Accordion>
                                            <Accordion defaultExpanded>
                                                <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ backgroundColor: "#ddd" }}>
                                                    <Typography variant="subtitle1" gutterBottom>
                                                        Applicant/Owner's Agent (required if different from the primary property owner)
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails style={{ paddingBottom: 8, backgroundColor: "f6f0f0" }}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                disabled={is_read_only}
                                                                field="secondary_first_name"
                                                                label="First Name"
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                disabled={is_read_only}
                                                                field="secondary_last_name"
                                                                label="Last Name"
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                disabled={is_read_only}
                                                                field="secondary_organization"
                                                                label="Organization / Company"
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                disabled={is_read_only}
                                                                field="secondary_mailing_address"
                                                                label="Mailing Address"
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField disabled={is_read_only} field="secondary_city" label="City" fullWidth />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Select
                                                                disabled={is_read_only}
                                                                field="secondary_state"
                                                                label="State"
                                                                options={states}
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField disabled={is_read_only} field="secondary_zip" label="Zip" fullWidth />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField disabled={is_read_only} field="secondary_phone" label="Phone" fullWidth />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField disabled={is_read_only} field="secondary_fax" label="Fax" fullWidth />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField disabled={is_read_only} field="secondary_email" label="Email" fullWidth />
                                                        </Grid>
                                                    </Grid>
                                                </AccordionDetails>
                                            </Accordion>{" "}
                                            <Typography variant="subtitle1">Other Contacts</Typography>
                                            {/* <Typography gutterBottom>
                                            If different from the primary owner, please provide contact information for another property owners
                                            subject to this application (secondary owners), a project representative (e.g., Consultant, Engineer,
                                            Arcitect, etc), and Erosion control Representative)
                                        </Typography> */}
                                            <Button
                                                onClick={() =>
                                                    this.setState({
                                                        contactDialogOpen: true,
                                                        contactEditing: null,
                                                        formKey: window.performance.now(),
                                                    })
                                                }
                                                disabled={is_read_only}
                                                variant="contained"
                                                color="primary">
                                                Add Contact
                                            </Button>
                                            <Table style={{ marginTop: 8 }}>
                                                <EnhancedTableHead
                                                    columnData={columnDataContacts}
                                                    order={order}
                                                    orderBy={orderBy}
                                                    onRequestSort={this.handleRequestSort}
                                                />
                                                <TableBody>
                                                    {temp_contacts.map((n) => {
                                                        return (
                                                            <TableRow hover>
                                                                <CustomTableCell className={classes.nowrap}>
                                                                    <Tooltip title="Edit">
                                                                        <Button
                                                                            color="primary"
                                                                            onClick={() =>
                                                                                this.setState({
                                                                                    contactDialogOpen: true,
                                                                                    contactEditing: n,
                                                                                    formKey: window.performance.now(),
                                                                                })
                                                                            }
                                                                            disabled={is_read_only}
                                                                            className={classes.deleteWidth}>
                                                                            <ViewIcon color="primary" />
                                                                        </Button>
                                                                    </Tooltip>
                                                                    <Tooltip title="Delete">
                                                                        <Button
                                                                            color="primary"
                                                                            onClick={() =>
                                                                                this.setState({
                                                                                    temp_contacts: temp_contacts.filter((tc) => tc !== n),
                                                                                })
                                                                            }
                                                                            disabled={is_read_only}
                                                                            className={classes.deleteWidth}>
                                                                            <DeleteIcon color="primary" />
                                                                        </Button>
                                                                    </Tooltip>
                                                                </CustomTableCell>
                                                                <CustomTableCell>{n.type}</CustomTableCell>
                                                                <CustomTableCell>{n.first_name}</CustomTableCell>
                                                                <CustomTableCell>{n.last_name}</CustomTableCell>
                                                                <CustomTableCell>{n.mailing_address}</CustomTableCell>
                                                                <CustomTableCell>{n.phone}</CustomTableCell>
                                                                <CustomTableCell>{n.email}</CustomTableCell>
                                                            </TableRow>
                                                        );
                                                    })}
                                                    {temp_contacts.length < 1 && (
                                                        <TableRow>
                                                            <CustomTableCell colSpan={columnDataContacts.length} className={classes.centerAlign}>
                                                                No Other Contacts Added
                                                            </CustomTableCell>
                                                        </TableRow>
                                                    )}
                                                </TableBody>
                                            </Table>
                                        </Grid>
                                    )}
                                    {activeStep === "PRINT" && (
                                        <>
                                            <div style={{ height: 50 }}></div>
                                            <Grid item xs={12} xl={10} style={{ marginLeft: 15 }} classes={{ root: classes.root }}>
                                                <Divider className={classes.printDivider} />
                                            </Grid>
                                        </>
                                    )}
                                    {activeStep === 2 && (
                                        <Grid item xs={12} xl={10} style={{ marginLeft: 15 }} classes={{ root: classes.root }}>
                                            <Typography variant="subtitle1" gutterBottom>
                                                Please Provide Project Information
                                            </Typography>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <Typography variant="subtitle1" gutterBottom>
                                                        <b>Project Location (either Parcel Number or Township, Range, Section is required)</b>
                                                    </Typography>
                                                </Grid>
                                                <Grid container spacing={2} style={{ padding: 10 }}>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            label={
                                                                <HelpLabel
                                                                    inputLabel="Site Address*"
                                                                    helpText="Address of Site.
                                                                    "
                                                                />
                                                            }
                                                            field="project_address"
                                                            disabled={is_read_only}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            label={
                                                                <HelpLabel
                                                                    inputLabel="Parcel Identification Number (PIN/PID) *"
                                                                    helpText="If new parcel was purchased, please also provide the Township, Range, Section.
                                                                    "
                                                                />
                                                            }
                                                            field="pin"
                                                            disabled={is_read_only}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Select
                                                        disabled={is_read_only}
                                                        field="county"
                                                        label="County *"
                                                        options={MAKE_OPTIONS(["Douglas", "Meeker", "Pope", "Stearns", "Todd"])}
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Select
                                                        disabled={is_read_only}
                                                        field="project_city"
                                                        label="Township/City Name"
                                                        options={MAKE_OPTIONS([
                                                            "Albany",
                                                            "Alexandria",
                                                            "Ashley",
                                                            "Belle River",
                                                            "Bircgdale",
                                                            "Bumhamville",
                                                            "Carlos",
                                                            "Collegeville",
                                                            "Crow River",
                                                            "Eden Lake",
                                                            "Elmdale",
                                                            "Fairhaven",
                                                            "Farming",
                                                            "Forest Prairie",
                                                            "Getty",
                                                            "Glenwood",
                                                            "Gordon",
                                                            "Grey Eagle",
                                                            "Grove",
                                                            "Grove Lake",
                                                            "Hudson",
                                                            "kandota",
                                                            "Krain",
                                                            "Lake George",
                                                            "Lake Henry",
                                                            "Le Sauk",
                                                            "Lesillie",
                                                            "Leven",
                                                            "Little Sauk",
                                                            "Long Prairie",
                                                            "Lexemburg",
                                                            "Main Prairie",
                                                            "Manannah",
                                                            "Melrose",
                                                            "Millwood",
                                                            "Munson",
                                                            "Oak",
                                                            "Orange",
                                                            "Osakis",
                                                            "Paynesville",
                                                            "Raymond",
                                                            "Reynolds",
                                                            "Rockville",
                                                            "Round Prairie",
                                                            "Saint Cloud",
                                                            "Saint Joesph",
                                                            "Saint Martin",
                                                            "Saint Wendel",
                                                            "Sauk Centre",
                                                            "Spring Hill",
                                                            "Wakefield",
                                                            "Westport",
                                                            "West Union",
                                                            "Zion",
                                                        ])}
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Select
                                                        disabled={is_read_only}
                                                        field="project_township"
                                                        label="Township"
                                                        options={MAKE_OPTIONS([
                                                            "121N",
                                                            "122N",
                                                            "123N",
                                                            "124N",
                                                            "125N",
                                                            "126N",
                                                            "127N",
                                                            "128N",
                                                            "129N",
                                                        ])}
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Select
                                                        disabled={is_read_only}
                                                        field="project_range"
                                                        label="Range"
                                                        options={MAKE_OPTIONS(["28W", "29W", "30W", "31W", "32W", "33W", "34W", "35W", "36W", "37W"])}
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Select
                                                        disabled={is_read_only}
                                                        field="project_section"
                                                        label="Section"
                                                        options={MAKE_OPTIONS([
                                                            "1",
                                                            "2",
                                                            "3",
                                                            "4",
                                                            "5",
                                                            "6",
                                                            "7",
                                                            "8",
                                                            "9",
                                                            "10",
                                                            "11",
                                                            "12",
                                                            "13",
                                                            "14",
                                                            "15",
                                                            "16",
                                                            "17",
                                                            "18",
                                                            "19",
                                                            "20",
                                                            "21",
                                                            "22",
                                                            "23",
                                                            "24",
                                                            "25",
                                                            "26",
                                                            "27",
                                                            "28",
                                                            "29",
                                                            "30",
                                                            "31",
                                                            "32",
                                                            "33",
                                                            "34",
                                                            "35",
                                                            "36",
                                                        ])}
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Select
                                                        disabled={is_read_only}
                                                        field="project_quarter_section"
                                                        label="Primary Quarter Section"
                                                        options={MAKE_OPTIONS(["NW", "NE", "SW", "SE"])}
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <TextField
                                                        useNumberFormat3
                                                        label="Parcel Size"
                                                        field="parcelsize"
                                                        unitsVisual="Acres"
                                                        disabled={is_read_only}
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <br />
                                                    <hr />
                                                    <br />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <TextField
                                                        label={
                                                            <HelpLabel
                                                                inputLabel="Project Name *"
                                                                helpText="Short and simple identification name for project, example: Big Pine Apartments."
                                                            />
                                                        }
                                                        field="project_name"
                                                        disabled={is_read_only}
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <DatePicker
                                                        field="construction_start"
                                                        label={
                                                            <HelpLabel
                                                                inputLabel="Intended Construction Date *"
                                                                helpText="Enter in the estimated date that construction is to start."
                                                            />
                                                        }
                                                        disabled={is_read_only}
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        field="project_description"
                                                        label={
                                                            <HelpLabel
                                                                inputLabel="Project Description *"
                                                                helpText="Please be specific when describing your project. Attach additional sheets if necessary. Application will be considered incomplete if Project Description is not filled in.
                                                                "
                                                            />
                                                        }
                                                        multiline
                                                        rows={8}
                                                        disabled={is_read_only}
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid container spacing={2} style={{ padding: 10 }}>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            useNumberFormat3
                                                            label="Distance to Lake, River, Stream, or Wetland"
                                                            field="distance"
                                                            unitsVisual="ft"
                                                            disabled={is_read_only}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing={2} style={{ padding: 10 }}>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            useNumberFormat3
                                                            label="Total Area of Land Disturbance"
                                                            field="area_disturbance"
                                                            unitsVisual="sqft"
                                                            disabled={is_read_only}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing={2} style={{ padding: 10 }}>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            useNumberFormat3
                                                            label="Total Area of Existing Impervous"
                                                            field="ex_impervous"
                                                            unitsVisual="sqft"
                                                            disabled={is_read_only}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing={2} style={{ padding: 10 }}>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            useNumberFormat3
                                                            label="Total Area of Proposed Impervous"
                                                            field="impervious"
                                                            unitsVisual="sqft"
                                                            disabled={is_read_only}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing={2} style={{ padding: 10 }}>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            useNumberFormat3
                                                            label="Total Area of Increase in Impervous"
                                                            field="inc_impervous"
                                                            unitsVisual="sqft"
                                                            disabled={is_read_only}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        field="comments"
                                                        label={
                                                            <HelpLabel
                                                                inputLabel="Additional Comments"
                                                                helpText="Add additional comments if needed."
                                                            />
                                                        }
                                                        multiline
                                                        rows={4}
                                                        disabled={is_read_only}
                                                        fullWidth
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )}
                                    {activeStep === "PRINT" && (
                                        <Grid item xs={12} xl={10} style={{ marginLeft: 15 }} classes={{ root: classes.root }}>
                                            <Divider className={classes.printDivider} />
                                        </Grid>
                                    )}
                                    {activeStep === 3 && (
                                        <Grid item xs={12} xl={10} style={{ marginLeft: 15 }} classes={{ root: classes.root }}>
                                            <Typography gutterBottom>
                                                <br />
                                                See page 1 of the{" "}
                                                <a
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    href="https://srwdmn.org/wp-content/uploads/2021/03/2021.3.17-SRWD-Permit-Packet-Fillable-Comments.pdf">
                                                    Permit Application Packet
                                                </a>{" "}
                                                for guidance or the{" "}
                                                <a
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    href="https://srwdmn.org/wp-content/uploads/2021/03/2021.3.17-SRWD-Permit-Packet-Fillable-Comments.pdf">
                                                    SRWD Administrative Rules
                                                </a>{" "}
                                                for our current rule language.
                                            </Typography>
                                            {/* <Typography gutterBottom>
                                            <br />
                                            Rule guidance materials and additional information can be found at:
                                            <a
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                href="https://srwdmn.org/wp-content/uploads/2021/03/2021.3.17-SRWD-Permit-Packet-Fillable-Comments.pdf">
                                                https://srwdmn.org/wp-content/uploads/2021/03/2021.3.17-SRWD-Permit-Packet-Fillable-Comments.pdf
                                            </a>
                                        </Typography> */}
                                            <CheckboxGroup
                                                field="rules"
                                                label="Applicable Rule Sections (check all that apply)"
                                                row={true}
                                                width={205}
                                                disabled={is_read_only}
                                                options={MAKE_OPTIONS([
                                                    "Sec. 7 - Stormwater", //before this was - Rule 7 and below Rule 8, etc...
                                                    "Sec. 8 - Erosion & Sediment Control",
                                                    "Sec. 09 - Drainage",
                                                    "Sec. 10 - Waterbody Uses",
                                                ])}
                                            />
                                        </Grid>
                                    )}
                                    {activeStep === "PRINT" && (
                                        <Grid item xs={12} xl={10} classes={{ root: classes.root }}>
                                            <Divider className={classes.printDivider} />
                                        </Grid>
                                    )}

                                    {activeStep === 4 && (
                                        <Grid item xs={12} xl={10} style={{ marginLeft: 15 }} classes={{ root: classes.root }}>
                                            <Typography variant="subtitle1">Attachments</Typography>
                                            <Typography gutterBottom>
                                                Please refer to the Appendices of the{" "}
                                                <a
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    href="https://srwdmn.org/wp-content/uploads/2021/03/2021.3.17-SRWD-Permit-Packet-Fillable-Comments.pdf">
                                                    Permit Application Packet
                                                </a>
                                                {"  for information on required attachments. "}
                                                Please attached all electronic files associated with your applications. This could include but is not
                                                limited to signed permit form, construction drawings, project narrative, water quantity and quality
                                                modeling in their respective native form, draft maintenance declaration/agreement, Stormwater
                                                Management plan, Chloride Management plan, etc.
                                            </Typography>
                                            <Button
                                                onClick={() =>
                                                    this.setState({
                                                        attachmentDialogOpen: true,
                                                        attachmentEditing: null,
                                                        attachmentFormKey: window.performance.now(),
                                                    })
                                                }
                                                disabled={is_read_only}
                                                variant="contained"
                                                color="primary">
                                                Add Attachment
                                            </Button>
                                            <Table style={{ marginTop: 8 }}>
                                                <EnhancedTableHead
                                                    columnData={columnDataAttachments}
                                                    order={attachmentOrder}
                                                    orderBy={attachmentOrderBy}
                                                    onRequestSort={this.handleRequestSort2}
                                                />
                                                <TableBody>
                                                    {temp_attachments.map((n) => {
                                                        return (
                                                            <TableRow hover>
                                                                <CustomTableCell className={classes.nowrap}>
                                                                    {n.file.includes("https") && (
                                                                        <Tooltip title="Open Link">
                                                                            <Button
                                                                                color="primary"
                                                                                onClick={() => {
                                                                                    window.open(n.file);
                                                                                }}
                                                                                className={classes.deleteWidth}>
                                                                                <ViewIcon color="primary" />
                                                                            </Button>
                                                                        </Tooltip>
                                                                    )}
                                                                    <Tooltip title="Delete">
                                                                        <Button
                                                                            color="primary"
                                                                            onClick={() =>
                                                                                this.setState({
                                                                                    temp_attachments: temp_attachments.filter((tc) => tc !== n),
                                                                                })
                                                                            }
                                                                            disabled={is_read_only}
                                                                            className={classes.deleteWidth}>
                                                                            <DeleteIcon color="primary" />
                                                                        </Button>
                                                                    </Tooltip>
                                                                </CustomTableCell>
                                                                <CustomTableCell>{n.name}</CustomTableCell>
                                                                <CustomTableCell>{n._type}</CustomTableCell>
                                                                <CustomTableCell>{n.notes}</CustomTableCell>
                                                            </TableRow>
                                                        );
                                                    })}
                                                    {temp_attachments.length < 1 && (
                                                        <TableRow>
                                                            <CustomTableCell colSpan={columnDataAttachments.length} className={classes.centerAlign}>
                                                                No Attachments Added
                                                            </CustomTableCell>
                                                        </TableRow>
                                                    )}
                                                </TableBody>
                                            </Table>
                                        </Grid>
                                    )}
                                    {activeStep === "PRINT" && (
                                        <Grid item xs={12} xl={10} classes={{ root: classes.root }}>
                                            <Divider className={classes.printDivider} />
                                        </Grid>
                                    )}
                                    {activeStep === 5 && (
                                        <Grid item xs={12} xl={10} style={{ marginLeft: 15 }} classes={{ root: classes.root }}>
                                            <Typography variant="subtitle1" gutterBottom>
                                                Applicant Signature
                                            </Typography>
                                            <table>
                                                {" "}
                                                <tr>
                                                    <td style={{ whiteSpace: "nowrap", paddingRight: 16 }}>
                                                        <Checkbox disabled={is_read_only} label="I AGREE *" field="signature1" />
                                                    </td>
                                                    <td>
                                                        <Typography gutterBottom>
                                                            The undersigned that my application will be considered incomplete until I submit the
                                                            appropriate application fee. Refer to{" "}
                                                            <a
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                                href="https://srwdmn.org/wp-content/uploads/2021/03/2021.3.17-SRWD-Permit-Packet-Fillable-Comments.pdf">
                                                                Fee Schedule.
                                                            </a>{" "}
                                                        </Typography>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={{ whiteSpace: "nowrap", paddingRight: 16 }}>
                                                        <Checkbox disabled={is_read_only} label="I AGREE *" field="signature" />
                                                    </td>
                                                    <td>
                                                        <Typography gutterBottom>
                                                            The undersigned hereby acknowledges by signing this Permit Application, the undersigned,
                                                            its agents, assigns and/or representatives (hereinafter “Permittee”) shall abide by all
                                                            the standard conditions and special terms and conditions of the Sauk River Watershed
                                                            District (SRWD) Permit. Any work which violates the terms of the permit may result in the
                                                            SRWD issuing a Stop Work Order, which shall immediately cause the work on the project
                                                            relating to the permit to cease and desist. All work on the project shall cease until the
                                                            permit conditions are met and approved by the SRWD.
                                                        </Typography>
                                                        <Typography gutterBottom>
                                                            I hereby make application for a permit or make notification to proceed with the proposed
                                                            described above and have attached all supporting maps, plans, and other information
                                                            submitted with this application and all necessary fees. The information submitted and
                                                            statements made concerning this application are true and correct to the best of my
                                                            knowledge.{" "}
                                                        </Typography>
                                                    </td>
                                                </tr>
                                                {formApi.values.secondary_last_name && (
                                                    <tr>
                                                        <td style={{ whiteSpace: "nowrap", paddingRight: 16 }}>
                                                            <Checkbox disabled={is_read_only} label="I AGREE *" field="signature2" />
                                                        </td>
                                                        <td>
                                                            <Typography gutterBottom>
                                                                I agree I have downloaded, printed and signed the Owner Agent form. The form has been
                                                                scanned and uploaded as an attached document to this application. If you do not have
                                                                the form please download it from here: &nbsp;
                                                                <a
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                    href="https://srwdmn.org/wp-content/uploads/2021/03/2021.3.17-SRWD-Permit-Packet-Fillable-Comments.pdf">
                                                                    https://srwdmn.org/wp-content/uploads/2021/03/2021.3.17-SRWD-Permit-Packet-Fillable-Comments.pdf
                                                                </a>{" "}
                                                                &nbsp; on page 4.
                                                            </Typography>
                                                        </td>
                                                    </tr>
                                                )}
                                            </table>
                                        </Grid>
                                    )}
                                    {activeStep !== "PRINT" && (
                                        <Grid item xs={12} xl={10} className={classes.rightAlign} classes={{ root: classes.root }}>
                                            <Button
                                                disabled={activeStep === 0}
                                                onClick={() => this.setState({ activeStep: activeStep - 1 })}
                                                className={classes.button}>
                                                Back
                                            </Button>
                                            <Button
                                                onClick={() => this.handleNext()}
                                                disabled={is_read_only && activeStep === 5}
                                                variant="contained"
                                                color="primary"
                                                style={{ marginLeft: 16 }}>
                                                {activeStep === 5 ? "Submit Permit Application" : "Next"}
                                            </Button>
                                            <Button
                                                style={{ marginLeft: 16 }}
                                                onClick={() => {
                                                    return (
                                                        // this.setState({ activeStep: "PRINT" }),
                                                        window.open("/reports/application/" + application.hash)
                                                    );
                                                }}
                                                variant="contained"
                                                color="primary">
                                                Print
                                            </Button>
                                        </Grid>
                                    )}
                                </Grid>
                            </>
                        </form>
                    )}
                </Form>
                <Dialog open={attachmentDialogOpen} classes={{ paper: classes.minWidth }}>
                    <Toolbar>
                        <Typography variant="h6" className={classes.flex}>
                            Attachment
                        </Typography>
                        <IconButton aria-label="Close Dialog" onClick={() => this.setState({ attachmentDialogOpen: false })}>
                            <Close />
                        </IconButton>
                    </Toolbar>
                    <DialogContent className={classes.dialogMin}>
                        <DialogContentText>
                            <Form
                                dontValidateOnMount={true}
                                validateOnSubmit={true}
                                key={attachmentFormKey}
                                defaultValues={attachmentEditing}
                                validateError={this.errorValidatorAttachment}
                                onSubmit={(values) => this.addAttachment(values)}>
                                {(formApi) => (
                                    <form onSubmit={formApi.submitForm}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Typography gutterBottom>
                                                    Only these file types can be directly uploaded as an attachment. All other file types should be
                                                    put into a .zip file and then uploaded. Direct supported file types are: PDF (.pdf), Microsoft
                                                    Word (.doc, .docx), Microsoft Excel (.xls, .xlsx), Images (.png, .jpg, .jpeg, .tiff, .bmp),
                                                    Microsoft Powerpoint (.ppt, .pptx), Zip files (.zip).
                                                </Typography>
                                                <FileInput field="file" id="file_upl" fullWidth label="File *" />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField field="name" label="Name *" fullWidth />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Select
                                                    field="_type"
                                                    label="Type"
                                                    options={MAKE_OPTIONS([
                                                        "Civil Plans (Grading Plan/Drainage Plan/Utility Plan)",
                                                        "Project Narrative",
                                                        "H&H Model Native Form",
                                                        "Water Quality Model - Native Form",
                                                        "Stormwater Management Plan",
                                                        "Erosion Prevention and Sediment Control Plan",
                                                        "Inspection Plan",
                                                        "Chloride Plan",
                                                        "Maintenance Declaration",
                                                        "Maintenance Agreement",
                                                        "Variance Discussion",
                                                        "Other",
                                                    ])}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField field="notes" label="Notes" fullWidth />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Button fullWidth onClick={() => this.setState({ attachmentDialogOpen: false })}>
                                                    Cancel
                                                </Button>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Button fullWidth type="submit" variant="contained" color="primary">
                                                    Save
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </form>
                                )}
                            </Form>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
                <Dialog open={contactDialogOpen} classes={{ paper: classes.minWidth }}>
                    <Toolbar>
                        <Typography variant="h6" className={classes.flex}>
                            Other Contact
                        </Typography>
                        <IconButton aria-label="Close Dialog" onClick={() => this.setState({ contactDialogOpen: false })}>
                            <Close />
                        </IconButton>
                    </Toolbar>
                    <DialogContent className={classes.dialogMin}>
                        <DialogContentText>
                            <Form
                                dontValidateOnMount={true}
                                validateOnSubmit={true}
                                key={formKey}
                                defaultValues={contactEditing}
                                validateError={this.errorValidatorContact}
                                onSubmit={(values) => this.addContact(values)}>
                                {(formApi) => (
                                    <form onSubmit={formApi.submitForm}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={6}>
                                                <TextField field="first_name" label="First Name *" fullWidth />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField field="last_name" label="Last Name *" fullWidth />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField field="organization" label="Organization / Company" fullWidth />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField field="mailing_address" label="Mailing Address" fullWidth />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField field="mailing_address2" label="Mailing Address 2" fullWidth />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField field="city" label="City" fullWidth />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Select field="state" label="State" options={states} fullWidth />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField field="zip" label="Zip" fullWidth />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField field="phone" label="Phone *" fullWidth />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField field="email" label="Email" fullWidth />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Select
                                                    field="type"
                                                    label="Type"
                                                    options={MAKE_OPTIONS(["Secondary Owner", "Contractor", "Other"])}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={6} />
                                            <Grid item xs={6}>
                                                <Button fullWidth onClick={() => this.setState({ contactDialogOpen: false })}>
                                                    Cancel
                                                </Button>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Button fullWidth type="submit" variant="contained" color="primary">
                                                    Save
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </form>
                                )}
                            </Form>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
            </AppContainerApplication>
        );
    }
}

Srwd = connect(
    (state, ownProps) => ({
        application: getApplication(state, ownProps),
        authState: state.auth, // Admins will have an authstate
    }),
    {
        ...ApplicationRecord.actions,
        ...ApplicationRecordStatus.actions,
        ...SrwdPermit.actions,
        ...SrwdPermitAttachment.actions,
        ...SrwdPermitContact.actions,
    }
)(Srwd);

export default withStyles(styles)(withRouter(Srwd));
