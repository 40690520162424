import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Form } from "react-form";
import { connect } from "react-redux";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepButton from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Divider from "@material-ui/core/Divider";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Toolbar from "@material-ui/core/Toolbar";
import { withStyles } from "@material-ui/core";

import ViewIcon from "@material-ui/icons/Visibility";
import Close from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";

import AppContainerApplication from "../../common/AppContainerApplication";
import TextField from "../../common/TextField";
import Select from "../../common/Select";
import FileInput from "../../common/FileInput";
import Checkbox from "../../common/Checkbox";
import CheckboxGroup from "../../common/CheckboxGroup";
import { createSelector } from "../../common/orm";
import states from "../../common/statesmn.json";
import EnhancedTableHead from "../../common/EnhancedTableHead";
import CustomTableCell from "../../common/TableCell";
import { ApplicationRecord, ApplicationRecordStatus, CrwdPermit, CrwdPermitAttachment } from "../../page/models";

const styles = theme => ({
    deleteWidth: {
        minWidth: 34,
        width: 34,
        marginRight: 8,
        marginTop: 2,
        marginBottom: 2
    },
    centerAlign: {
        textAlign: "center"
    },
    centerAlign2: {
        textAlign: "center",
        display: "inline-block",
        width: "100%"
    },
    nowrap: {
        whiteSpace: "nowrap"
    },
    rightAlign: {
        textAlign: "right"
    },
    flex: {
        flex: 1
    },
    printDivider: {
        height: 8,
        backgroundColor: "rgba(0, 0, 0, 0.42)",
        marginTop: 16,
        marginBottom: 8
    },
    dialogMin: {
        minWidth: 400
    },
    minWidth: {
        minWidth: 600
    }
});

var columnDataAttachments = [
    { id: "actions", numeric: false, label: "", allowSort: false, width: "20px" },
    { id: "name", numeric: false, label: "Name", allowSort: true },
    { id: "date_uploaded", numeric: false, label: "Date Added", allowSort: true, date: true },
    { id: "_type", numeric: false, label: "Type", allowSort: true },
    { id: "notes", numeric: false, label: "Notes", allowSort: true }
];

function MAKE_OPTIONS(table) {
    return table.map(row => ({
        label: row,
        value: row
    }));
}

const getApplication = createSelector(
    (state, ownProps) => ownProps.match.params["hash"],
    (session, hash) => {
        return session.CrwdPermit.filter(p => p.hash === hash).toModelArray()[0];
    }
);

class CrwdPermitComp extends Component {
    state = {
        activeStep: 0,
        attachmentDialogOpen: false,
        attachmentEditing: null,
        attachmentFormKey: null,
        attachmentOrder: "desc",
        attachmentOrderBy: null,
        temp_attachments: [],
        loading: false,
        new_save: false,
        new_save2: false,
        mainFormKey: null,
        whatthef: null
    };

    componentDidMount() {
        const { match, ormCrwdPermitLoadDetailChild, ormCrwdPermitAttachmentLoadDetailChild } = this.props;
        var component = this;

        if (match.params && match.params.hash) {
            ormCrwdPermitLoadDetailChild(match.params.hash, function(hmm) {
                component.setState({ whatthef: hmm[0], mainFormKey: window.performance.now() });
            });
            ormCrwdPermitAttachmentLoadDetailChild(match.params.hash, function(data) {
                component.setState({ temp_attachments: data });
            });
        }
        if (match.params && match.params.print) {
            component.setState({ activeStep: "PRINT" });
        }
    }

    errorValidator = values => {
        const isRequired = val => {
            return !val ? "Required" : null;
        };
        const validateEmail = email => {
            var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return !re.test(email) ? "Invalid Email Address" : null;
        };
        const validateEmailNotRequired = email => {
            var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!email)
                return null;
            return !re.test(email) ? "Invalid Email Address" : null;
        };
        var valObj = {
            name: isRequired(values.name),
            address: isRequired(values.address),
            city: isRequired(values.city),
            disturbed_area: isRequired(values.disturbed_area),
            reconstructed_impervious_area: isRequired(values.reconstructed_impervious_area),
            proposed_work: isRequired(values.proposed_work),
            owner_name: isRequired(values.owner_name),
            owner_email: validateEmail(values.owner_email),
            engi_email: validateEmailNotRequired(values.engi_email)
        };

        var alerts = "";
        var ai = 0;
        if (valObj["name"] === "Required") {
            alerts += "Name of Proposed Project (Step 1)\n";
        }
        if (valObj["address"] === "Required") {
            alerts += "Address/Intersection (Step 1)\n";
        }
        if (valObj["city"] === "Required") {
            alerts += "City (Step 1)\n";
        }
        if (valObj["disturbed_area"] === "Required") {
            alerts += "Disturbed Area (Step 1)\n";
        }
        if (valObj["reconstructed_impervious_area"] === "Required") {
            alerts += "New and Reconstructed Impervious Area (Step 1)\n";
        }
        if (valObj["proposed_work"] === "Required") {
            alerts += "Proposed Work (Step 1)\n";
        }
        if (valObj["owner_name"] === "Required") {
            alerts += "Site Owner Name (Step 3)\n";
            ai = 2;
        }
        if (valObj["owner_email"] === "Invalid Email Address") {
            alerts += "Site Owner Email (Step 3)\n";
            ai = 2;
        }
        if (valObj["engi_email"] === "Invalid Email Address") {
            alerts += "Consulting Engineer Email (Step 3)\n";
            ai = 2;
        }

        
        if (alerts) {
            alert("The following fields are missing or invalid:\n" + alerts);
            this.setState({ activeStep: ai });
        }

        return valObj;
    };

    errorValidatorAttachment = values => {
        const isRequired = val => {
            return !val ? "Required" : null;
        };
        var valObj = {
            file: isRequired(values.file),
            name: isRequired(values.name)
        };

        return valObj;
    };

    submitApplication = values => {
        const {
            ormApplicationRecordCreate,
            ormApplicationRecordStatusCreate,
            ormCrwdPermitCreate,
            ormCrwdPermitAttachmentCreate,
            ormCrwdPermitUpdatePublic
        } = this.props;
        const { temp_attachments, whatthef } = this.state;

        const _this = this;
        this.setState({ loading: true });

        // Editing
        if (whatthef) {
            var new_status = "";
            if (whatthef.apprec.status === "Incomplete")
                new_status = "Received";

            if (new_status) {
                ormApplicationRecordStatusCreate({
                    applicationrecord: whatthef.apprec.id,
                    status: new_status
                }).then(statusrecord => {
                    ormCrwdPermitUpdatePublic({
                        id: whatthef.id,
                        foo: "bar",
                        ...values
                    });
                    var i = 0;
                    var j = 0;
                    temp_attachments.forEach(function(tc) {
                        if (tc.id) {
                        } else {
                            i++;
                            ormCrwdPermitAttachmentCreate({
                                crwdpermit_id: whatthef.id,
                                ...tc
                            }).then(_z => {
                                j++;
                                if (i === j) _this.setState({ loading: false, new_save2: true });
                            });
                        }
                    });
                    if (i === 0) {
                        // No attachments or contacts - its finished loading
                        _this.setState({ loading: false, new_save2: true });
                    }
                });
            } else {
                ormCrwdPermitUpdatePublic({
                    id: whatthef.id,
                    foo: "bar",
                    ...values
                });
                var i = 0;
                var j = 0;
                temp_attachments.forEach(function(tc) {
                    if (tc.id) {
                    } else {
                        i++;
                        ormCrwdPermitAttachmentCreate({
                            crwdpermit_id: whatthef.id,
                            ...tc
                        }).then(_z => {
                            j++;
                            if (i === j) {
                                if (whatthef.apprec.status === "Approve" || whatthef.apprec.status === "Table" || whatthef.apprec.status === "Approval Recommended") {
                                    ormApplicationRecordStatusCreate({
                                        applicationrecord: whatthef.apprec.id,
                                        status: "Under Review"
                                    });
                                }
                                _this.setState({ loading: false, new_save2: true });
                            }
                        });
                    }
                });
                if (i === 0) {
                    if (whatthef.apprec.status === "Approve" || whatthef.apprec.status === "Table" || whatthef.apprec.status === "Approval Recommended") {
                        setTimeout(function() {
                            ormApplicationRecordStatusCreate({
                                applicationrecord: whatthef.apprec.id,
                                status: "Under Review"
                            });
                        }, 300);
                    }
                    // No attachments or contacts - its finished loading
                    _this.setState({ loading: false, new_save2: true });
                }
            }
        } 
        else {
            // New
            ormApplicationRecordCreate({
                application: 4
            }).then(ar => {
                ormApplicationRecordStatusCreate({
                    applicationrecord: ar.id,
                    status: "Received"
                }).then(statusrecord => {
                    ormCrwdPermitCreate({
                        applicationrecord: ar.id,
                        ...values
                    }).then(rpb => {
                        var i = 0;
                        var j = 0;
                        temp_attachments.forEach(function(tc) {
                            i++;
                            ormCrwdPermitAttachmentCreate({
                                crwdpermit_id: rpb.id,
                                ...tc
                            }).then(_z => {
                                j++;
                                if (i === j) _this.setState({ loading: false, new_save: true });
                            });
                        });
                        if (i === 0) {
                            // No attachments or contacts - its finished loading
                            _this.setState({ loading: false, new_save: true });
                        }
                    });
                });
            });
        }
    };

    addAttachment = values => {
        var { attachmentEditing, temp_attachments } = this.state;

        if (!attachmentEditing) {
            temp_attachments.push(values);
            this.setState({ temp_attachments: temp_attachments });
        } else {
            var temp = temp_attachments.filter(tc => tc !== attachmentEditing);
            temp.push(values);
            this.setState({ temp_attachments: temp });
        }

        this.setState({ attachmentDialogOpen: false });
    };

    formatDate = date_string => {
        if (!date_string)
            return null;
        const d = new Date(date_string + " 12:00:00");
        return d.toLocaleDateString();
    };

    handleRequestSort2 = (event, property) => {
        const orderBy = property;
        let order = "desc";

        if (this.state.orderBy === property && this.state.order === "desc") {
            order = "asc";
        }
        this.state.temp_attachments.sort(function(a, b) {
            var date = columnDataAttachments.find(cD => cD.id === orderBy).date;

            if (date) {
                if (order === "desc") return new Date(b[orderBy]) < new Date(a[orderBy]) ? -1 : 1;
                else return new Date(a[orderBy]) < new Date(b[orderBy]) ? -1 : 1;
            } else {
                if (order === "desc")
                    return (b[orderBy] || "").toUpperCase() < (a[orderBy] || "").toUpperCase() ? -1 : 1;
                else return (a[orderBy] || "").toUpperCase() < (b[orderBy] || "").toUpperCase() ? -1 : 1;
            }
        });

        this.setState({ order, orderBy });
    };

    handleNext = index => {
        const { activeStep } = this.state;
        if (index || index === 0) {
            this.setState({ activeStep: index });
        } else if (activeStep < 2) this.setState({ activeStep: activeStep + 1 });
        else {
            this.formApi.submitForm();
        }
    };

    render() {
        const { classes, application, match, authState, ormCrwdPermitAttachmentDelete } = this.props;
        const {
            activeStep,
            attachmentDialogOpen,
            attachmentEditing,
            attachmentFormKey,
            attachmentOrder,
            attachmentOrderBy,
            temp_attachments,
            loading,
            new_save,
            mainFormKey,
            new_save2, whatthef
        } = this.state;

        var is_read_only = false;
        var is_read_only_page_1_and_3 = false
        // Restricted: Received, Fake/Invalid, Issued
        // Editable Statuses: Approve, Table, Incomplete, Complete, Under Review, Approval Recommended
        // Incomplete -> Received
        // Approve/Table -> Under Review
        // Approval Recommended/Under Review/Complete -> No Change
        if (authState && authState.user) {
            // Staff
        }
        else if (whatthef && whatthef.apprec && whatthef.apprec.status !== "Approve" && whatthef.apprec.status !== "Table" && whatthef.apprec.status !== "Complete" && whatthef.apprec.status !== "Incomplete"  && whatthef.apprec.status !== "Approval Recommended"  && whatthef.apprec.status !== "Under Review"  && whatthef.apprec.status !== "Received") {
            is_read_only = true;
        }
        if(whatthef && whatthef.apprec && whatthef.allstatus.find(s=>s === 'Complete')){//if marked complete before make these pages read only going forward
            // if (!authState) { 
                is_read_only_page_1_and_3 = true 
            // }
        } 

        if (new_save) {
            return (
                <AppContainerApplication city_id={"10"} page_loading={loading}>
                    <Grid container spacing={1} style={{ padding: "0 12px" }}>
                        <Grid item xs={12} lg={10} xl={8}>
                            <Typography>
                                Thank you! Your permit application has been successfully submitted. Capitol Region Watershed District staff has been
                                notified of your application. Expect staff to contact you in the coming days.
                            </Typography>
                        </Grid>
                    </Grid>
                </AppContainerApplication>
            );
        }

        if (new_save2) {
            return (
                <AppContainerApplication city_id={"10"} page_loading={loading}>
                    <Grid container spacing={1} style={{ padding: "0 12px" }}>
                        <Grid item xs={12} lg={10} xl={8}>
                            <Typography>This is a confirmation that the permit application has been resubmitted successfully.</Typography>
                        </Grid>
                    </Grid>
                </AppContainerApplication>
            );
        }

        var rules_array = [];
        if (!whatthef || whatthef.apprec.id > 1117) {
            rules_array = [
                "Rule C - Stormwater Management ($2100)",
                "Rule D - Flood Control ($2100 only if no other Rules apply)",
                "Rule E - Wetland Management ($2100)",
                "Rule F - Erosion and Sediment Control ($700)",
                "Rule G - Illicit Discharge and Connection Management ($700)"
            ]
        } else {
            rules_array = [
                "Rule C - Stormwater Management ($1500)",
                "Rule D - Flood Control ($1500 only if no other Rules apply)",
                "Rule E - Wetland Management ($1500)",
                "Rule F - Erosion and Sediment Control ($500)",
                "Rule G - Illicit Discharge and Connection Management ($500)"
            ]
        }

        return (
            <AppContainerApplication city_id={"10"} page_loading={loading} printing={activeStep === "PRINT"}>
                <Form
                    getApi={el => (this.formApi = el)}
                    defaultValues={application}
                    key={mainFormKey}
                    dontValidateOnMount={true}
                    validateOnSubmit={true}
                    validateError={values => this.errorValidator(values)}
                    onSubmit={values => this.submitApplication(values)}>
                    {formApi => (
                        <form onSubmit={formApi.submitForm}>
                            <Grid container spacing={1} style={{ padding: "0 12px" }}>
                                <Grid item xs={12} lg={10} xl={8}>
                                    <Typography className={classes.centerAlign} variant="h6">
                                        Permit Application Form
                                    </Typography>
                                    <Typography className={classes.centerAlign}>
                                        <b>IMPORTANT:</b> The entire permit application must be filled out at one time and progress will not be saved
                                        so please plan accordingly.
                                    </Typography>
                                    <Typography variant="caption" className={classes.centerAlign2}>
                                        Required Fields are marked with an asterisk (*)
                                    </Typography>
                                </Grid>
                                {activeStep !== "PRINT" && (
                                    <Grid item xs={12} lg={10} xl={8}>
                                        <Stepper style={{ padding: 8 }} alternativeLabel nonLinear activeStep={activeStep}>
                                            {["Project Details", "Attach Documents", "Applicant Information"].map((label, index) => {
                                                return (
                                                    <Step key={label}>
                                                        <StepButton style={{ cursor: "pointer" }} onClick={() => this.handleNext(index)}>
                                                            {label}
                                                        </StepButton>
                                                    </Step>
                                                );
                                            })}
                                        </Stepper>
                                    </Grid>
                                )}
                                {(activeStep === 0 || activeStep === "PRINT") && (
                                    <Grid item xs={12} lg={10} xl={8}>
                                        <Typography variant="subtitle1" gutterBottom>
                                            Project Details {whatthef && whatthef.permit_number && (<span><b># {whatthef.permit_number}</b>; Current Status: {whatthef.history && <b>{whatthef.history[0].status}</b>}</span>)}
                                        </Typography>
                                        
                                        <Grid container spacing={1} style={{ marginTop: 0 }}>
                                            {whatthef && whatthef.history && (
                                                <Grid item xs={12}>
                                                    <Typography>Status History</Typography>
                                                    <table>
                                                        {whatthef.history.map((n, z) => {
                                                            return (
                                                                <tr>
                                                                    <td style={{paddingRight: 8}}><Typography>{n.status}</Typography></td>
                                                                    <td><Typography>{n.date}</Typography></td>
                                                                </tr>
                                                            );
                                                        })}
                                                    </table>
                                                    <hr/>
                                                </Grid>
                                            )}
                                            <Grid item xs={12}>
                                                <TextField label="Name of Proposed Project *" field="name" disabled={is_read_only || is_read_only_page_1_and_3} fullWidth />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField label="Address/Intersection *" field="address" disabled={is_read_only || is_read_only_page_1_and_3} fullWidth />
                                            </Grid>
                                            <Grid item xs={6}>
                                                {/* <TextField label="City *" field="city" disabled={is_read_only || is_read_only_page_1_and_3} fullWidth /> */}
                                                <Select field="city" label="City *" options={MAKE_OPTIONS(["FALCON HEIGHTS","LAUDERDALE","MAPLEWOOD","ROSEVILLE","SAINT PAUL","Other"])} disabled={is_read_only || is_read_only_page_1_and_3} fullWidth />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    useNumberFormat3
                                                    label="Disturbed Area *"
                                                    field="disturbed_area"
                                                    unitsVisual="ac"
                                                    disabled={is_read_only || is_read_only_page_1_and_3}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    useNumberFormat3
                                                    label="New and Reconstructed Impervious Area *"
                                                    field="reconstructed_impervious_area"
                                                    unitsVisual="ac"
                                                    disabled={is_read_only || is_read_only_page_1_and_3}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField multiline rows={8} label="Proposed Work *" field="proposed_work" disabled={is_read_only || is_read_only_page_1_and_3} fullWidth />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Checkbox disabled={is_read_only || is_read_only_page_1_and_3} label="Is this a public project?" field="public_project" />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <CheckboxGroup
                                                    field="rules"
                                                    label="Rule Applicability and Application Fees (Check all that apply)"
                                                    row={true}
                                                    width={205}
                                                    disabled={is_read_only || is_read_only_page_1_and_3}
                                                    options={MAKE_OPTIONS(rules_array)}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )}
                                {activeStep === "PRINT" && (
                                    <Grid item xs={12} lg={10} xl={8}>
                                        <Divider className={classes.printDivider} />
                                    </Grid>
                                )}
                                {activeStep === 1 && (
                                    <Grid item xs={12} lg={10} xl={8}>
                                        <Typography variant="subtitle1">Attachments</Typography>
                                        <Button
                                            onClick={() =>
                                                this.setState({
                                                    attachmentDialogOpen: true,
                                                    attachmentEditing: null,
                                                    attachmentFormKey: window.performance.now()
                                                })
                                            }
                                            disabled={is_read_only}
                                            variant="contained"
                                            color="primary">
                                            Add Attachment
                                        </Button>
                                        {whatthef && (
                                            <>
                                            <Button
                                                onClick={() =>
                                                    window.open("/applications/zip/" + match.params.hash)
                                                }
                                                style={{marginLeft:16}}
                                                variant="contained"
                                                color="primary">
                                                Download All Documents as .zip
                                            </Button>
                                            <Button
                                                onClick={() =>
                                                    window.open("/applications/zip/latest/" + match.params.hash)
                                                }
                                                style={{marginLeft:16}}
                                                variant="contained"
                                                color="primary">
                                                Download Latest Documents as .zip
                                            </Button>
                                            </>
                                        )}
                                        <Table style={{ marginTop: 8 }}>
                                            <EnhancedTableHead
                                                columnData={columnDataAttachments}
                                                order={attachmentOrder}
                                                orderBy={attachmentOrderBy}
                                                onRequestSort={this.handleRequestSort2}
                                            />
                                            <TableBody>
                                                {temp_attachments.map(n => {
                                                    return (
                                                        <TableRow hover>
                                                            <CustomTableCell className={classes.nowrap}>
                                                                {n.file.includes("https") && (
                                                                    <Tooltip title="Open Link">
                                                                        <Button
                                                                            color="primary"
                                                                            onClick={() => {
                                                                                window.open(n.file);
                                                                            }}
                                                                            className={classes.deleteWidth}>
                                                                            <ViewIcon color="primary" />
                                                                        </Button>
                                                                    </Tooltip>
                                                                )}
                                                                <Tooltip title="Delete">
                                                                    <Button
                                                                        color="primary"
                                                                        disabled={is_read_only}
                                                                        onClick={() => {
                                                                            ormCrwdPermitAttachmentDelete(n.id);
                                                                            this.setState({
                                                                                temp_attachments: temp_attachments.filter(tc => tc !== n)
                                                                            })
                                                                        }}
                                                                        className={classes.deleteWidth}>
                                                                        <DeleteIcon color="primary" />
                                                                    </Button>
                                                                </Tooltip>
                                                            </CustomTableCell>
                                                            <CustomTableCell>{n.name}</CustomTableCell>
                                                            <CustomTableCell>{this.formatDate(n.date_uploaded)}</CustomTableCell>
                                                            <CustomTableCell>{n._type}</CustomTableCell>
                                                            <CustomTableCell>{n.notes}</CustomTableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                                {temp_attachments.length < 1 && (
                                                    <TableRow>
                                                        <CustomTableCell colSpan={columnDataAttachments.length} className={classes.centerAlign}>
                                                            No Attachments Added
                                                        </CustomTableCell>
                                                    </TableRow>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </Grid>
                                )}
                                {(activeStep === 2 || activeStep === "PRINT") && (
                                    <Grid item xs={12} lg={10} xl={8}>
                                        <Typography gutterBottom>
                                            <u>
                                                <b>The undersigned</b>
                                            </u>{" "}
                                            hereby acknowledges by signing this Permit Application, the undersigned, its agents, assigns, and/or
                                            representatives (hereinafter "Permittee")
                                            <u>
                                                <b>shall abide by</b>
                                            </u>{" "}
                                            all the standard conditions and special terms and conditions of the Capitol Region Watershed District
                                            Permit as set forth in the District Rules and approved project specific plan documents.
                                        </Typography>
                                        <Typography gutterBottom>
                                            Any work which{" "}
                                            <u>
                                                <b>violates the terms of the permit</b>
                                            </u>{" "}
                                            may result in the Watershed District issuing a{" "}
                                            <u>
                                                <b>Stop Work Order</b>
                                            </u>{" "}
                                            which shall immediately cause the work on the project relating to the permit to cease and desist. All work
                                            on the project shall cease until the permit conditions are met and approved by the Capitol Region
                                            Watershed District staff.
                                        </Typography>
                                        <Typography gutterBottom>
                                            Any attorneys'{" "}
                                            <u>
                                                <b>fees, costs or other expenses</b>
                                            </u>{" "}
                                            incurred on behalf of the Watershed District in enforcing the terms of the permit shall be the sole{" "}
                                            <u>
                                                <b>expense of the undersigned</b>
                                            </u>
                                            . Costs shall be payable from the Permittee's escrow account. If said fees exceed the surety amount, the
                                            Permittee shall have ten (10) days from the date of the receipt of the invoice from the Capitol Region
                                            Watershed District to pay for the costs incurred in enforcing the permit, by which to pay the Capitol
                                            Region Watershed District for said costs.
                                        </Typography>
                                        <Typography gutterBottom>
                                            The undersigned applicant hereby{" "}
                                            <u>
                                                <b>agrees to be bound by the terms</b>
                                            </u>{" "}
                                            of the final permit, standard conditions, and special conditions as may be required by the Watershed
                                            District Board for approval of the permit. The undersigned further acknowledges that it has the authority
                                            to bind the permit holder, the owner of the property, and/or any entity performing work on the property
                                            pursuant to the terms of the Capitol Region Watershed District permit, and hereby shall be responsible for
                                            complying with the terms of the Capitol Region Watershed District permit.
                                        </Typography>
                                        <Grid container spacing={2} style={{ marginTop: 0 }}>
                                            <Grid item xs={6}>
                                                <Typography variant="subtitle1" gutterBottom>
                                                    Site Owner or Project Developer
                                                </Typography>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={12}>
                                                        <TextField disabled={is_read_only || is_read_only_page_1_and_3} field="owner_name" label="Name of Applicant *" fullWidth />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            disabled={is_read_only || is_read_only_page_1_and_3}
                                                            field="owner_organization"
                                                            label="Organization Name"
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField disabled={is_read_only || is_read_only_page_1_and_3} field="owner_address" label="Address" fullWidth />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField disabled={is_read_only || is_read_only_page_1_and_3} field="owner_city" label="City" fullWidth />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Select
                                                            disabled={is_read_only || is_read_only_page_1_and_3}
                                                            field="owner_state"
                                                            label="State"
                                                            options={states}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField disabled={is_read_only || is_read_only_page_1_and_3} field="owner_zip" label="Zip" fullWidth />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField disabled={is_read_only || is_read_only_page_1_and_3} field="owner_phone" label="Phone" fullWidth />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField disabled={is_read_only || is_read_only_page_1_and_3} field="owner_email" label="Email *" fullWidth />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="subtitle1" gutterBottom>
                                                    Consulting Engineer or Project Rep
                                                </Typography>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={12}>
                                                        <TextField disabled={is_read_only || is_read_only_page_1_and_3} field="engi_name" label="Name of Applicant" fullWidth />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            disabled={is_read_only || is_read_only_page_1_and_3}
                                                            field="engi_organization"
                                                            label="Organization Name"
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField disabled={is_read_only || is_read_only_page_1_and_3} field="engi_address" label="Address" fullWidth />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField disabled={is_read_only || is_read_only_page_1_and_3} field="engi_city" label="City" fullWidth />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Select disabled={is_read_only || is_read_only_page_1_and_3} field="engi_state" label="State" options={states} fullWidth />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField disabled={is_read_only || is_read_only_page_1_and_3} field="engi_zip" label="Zip" fullWidth />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField disabled={is_read_only || is_read_only_page_1_and_3} field="engi_phone" label="Phone" fullWidth />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField disabled={is_read_only || is_read_only_page_1_and_3} field="engi_email" label="Email" fullWidth />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )}
                                {activeStep !== "PRINT" && (
                                    <Grid item xs={12} lg={10} xl={8} className={classes.rightAlign}>
                                        <Button
                                            disabled={activeStep === 0}
                                            onClick={() => this.setState({ activeStep: activeStep - 1 })}
                                            className={classes.button}>
                                            Back
                                        </Button>
                                        <Button disabled={activeStep === 2 && is_read_only} onClick={() => this.handleNext()} variant="contained" color="primary" style={{ marginLeft: 16 }}>
                                            {activeStep === 2 ? "Submit Permit Application to CRWD" : "Next"}
                                        </Button>
                                    </Grid>
                                )}
                            </Grid>
                        </form>
                    )}
                </Form>
                <Dialog open={attachmentDialogOpen} classes={{ paper: classes.minWidth }}>
                    <Toolbar>
                        <Typography variant="h6" className={classes.flex}>
                            Attachment
                        </Typography>
                        <IconButton aria-label="Close Dialog" onClick={() => this.setState({ attachmentDialogOpen: false })}>
                            <Close />
                        </IconButton>
                    </Toolbar>
                    <DialogContent className={classes.dialogMin}>
                        <DialogContentText>
                            <Form
                                dontValidateOnMount={true}
                                validateOnSubmit={true}
                                key={attachmentFormKey}
                                defaultValues={attachmentEditing}
                                validateError={this.errorValidatorAttachment}
                                onSubmit={values => this.addAttachment(values)}>
                                {formApi => (
                                    <form onSubmit={formApi.submitForm}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Typography>
                                                    Only these file types can be directly uploaded as an attachment. All other file types should be
                                                    put into a .zip file and then uploaded. Direct supported file types are: PDF (.pdf), Microsoft
                                                    Word (.doc, .docx), Microsoft Excel (.xls, .xlsx), Images (.png, .jpg, .jpeg, .tiff, .bmp),
                                                    Microsoft Powerpoint (.ppt, .pptx), Zip files (.zip).
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <FileInput field="file" id="file_upl" fullWidth label="File *" />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField field="name" label="Name *" fullWidth />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Select field="_type" label="Type" options={MAKE_OPTIONS(["Photo", "Other"])} fullWidth />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField field="notes" label="Notes" fullWidth />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Button fullWidth onClick={() => this.setState({ attachmentDialogOpen: false })}>
                                                    Cancel
                                                </Button>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Button fullWidth type="submit" variant="contained" color="primary">
                                                    Save
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </form>
                                )}
                            </Form>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
            </AppContainerApplication>
        );
    }
}

CrwdPermitComp = connect(
    (state, ownProps) => ({
        application: getApplication(state, ownProps),
        authState: state.auth // Admins will have an authstate
    }),
    {
        ...ApplicationRecord.actions,
        ...ApplicationRecordStatus.actions,
        ...CrwdPermit.actions,
        ...CrwdPermitAttachment.actions
    }
)(CrwdPermitComp);

export default withStyles(styles)(withRouter(CrwdPermitComp));
