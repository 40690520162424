import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Form } from "react-form";
import { connect } from "react-redux";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
 
import IconButton from "@material-ui/core/IconButton";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepButton from "@material-ui/core/StepLabel";
import Dialog from "@material-ui/core/Dialog";
import Divider from "@material-ui/core/Divider";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Accordion from "@material-ui/core/Accordion";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Toolbar from "@material-ui/core/Toolbar";
import { withStyles } from "@material-ui/core";
import ViewIcon from "@material-ui/icons/Visibility";
import Close from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import Button from "@material-ui/core/Button";
import AppContainerApplication from "../../common/AppContainerApplication";
import TextField from "../../common/TextField";
import RadioGroup from "../../common/RadioGroup";
import Select from "../../common/Select";
import CheckboxGroup from "../../common/CheckboxGroup";
import FileInput from "../../common/FileInput";
import Checkbox from "../../common/Checkbox";
import HelpLabel from "../../common/HelpLabel";
import { createSelector } from "../../common/orm";
import states from "../../common/statesmn.json";
import EnhancedTableHead from "../../common/EnhancedTableHead";
import CustomTableCell from "../../common/TableCell";
import BaseMapControlApplication from "../esri/BaseMapControlApplication";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ApplicationRecord, ApplicationRecordStatus, CLWDPermit, CLWDAttachment } from "../../page/models";

const styles = (theme) => ({
    deleteWidth: {
        minWidth: 34,
        width: 34,
        marginRight: 8,
        marginTop: 2,
        marginBottom: 2,
    },
    centerAlign: {
        textAlign: "center",
    },
    centerAlign2: {
        textAlign: "center",
        display: "inline-block",
        width: "100%",
    },
    nowrap: {
        whiteSpace: "nowrap",
    },
    rightAlign: {
        textAlign: "right",
        marginRight: 15,
    },
    flex: {
        flex: 1,
    },
    printDivider: {
        height: 8,
        backgroundColor: "rgba(0, 0, 0, 0.42)",
        marginTop: 16,
        marginBottom: 8,
    },
    dialogMin: {
        minWidth: 400,
    },
    minWidth: {
        minWidth: 600,
    },
    root: {
        flexBasis: "100%",
        maxWidth: "100%",
    },
});

function MAKE_OPTIONS(table) {
    return table.map((row) => ({
        label: row,
        value: row,
    }));
}

const getApplication = createSelector(
    (state, ownProps) => ownProps.match.params["hash"],
    (session, hash) => {
        return session.CLWDPermit.filter((p) => p.hash === hash).toModelArray()[0];
    }
);

class CLWD extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeStep: 0,
            formKey: null,
            order: "desc",
            orderBy: null,
            attachmentDialogOpen: false,
            attachmentEditing: null,
            attachmentFormKey: null,
            attachmentOrder: "desc",
            attachmentOrderBy: null,
            temp_attachments: [],
            loading: false,
            new_save: false,
            mainFormKey: window.performance.now(),
            entryError: false,
            projectType: [],
            dialogLoading:false,
            createPoint:false,
            drawingFeature:false,
            other_permits: false,
        };
}

    componentDidMount() {
        const { match, ormCLWDPermitLoadDetailChild, ormCLWDAttachmentLoadDetailChild } = this.props;
        var component = this;

        if (match.params && match.params.hash) {
            ormCLWDPermitLoadDetailChild(match.params.hash, function (wtf) {
                component.setState({ mainFormKey: window.performance.now(), projectType:wtf[0].project_types, other_permits: wtf[0].other_permits });
            });
            ormCLWDAttachmentLoadDetailChild(match.params.hash, function (data) {
                component.setState({ temp_attachments: data });
            });
        }
    }

    errorValidator = (values) => {
        const isRequired = (val) => {
            return !val ? "Required" : null;
        };

        var valObj = {
            appl_first_name: isRequired(values.appl_first_name),
            appl_last_name: isRequired(values.appl_last_name),
            appl_phone: isRequired(values.appl_phone),
            parcel_number: isRequired(values.parcel_number),
            project_types: isRequired(values.project_types),
            project_description: isRequired(values.project_description),
            understand1: isRequired(values.understand1), 
        };

        var alerts = "";
        var ai = 6;
        if (valObj["appl_first_name"] === "Required") {
            alerts += "Applicant First Name (Step 2)\n";
            ai = 1;
        }
        if (valObj["appl_last_name"] === "Required") {
            alerts += "Applicant Last Name (Step 2)\n";
            ai = 1;
        }
        if (valObj["appl_phone"] === "Required") {
            alerts += "Applicant Phone  (Step 2)\n";
            ai = 1;
        }
        if (valObj["parcel_number"] === "Required") {
            alerts += "Parcel Number  (Step 3)\n";
            ai = 2;
        }
        if (valObj["project_types"] === "Required") {
            alerts += "Project types (Step 4)\n";
            ai = 3;
        }
        if (valObj["project_description"] === "Required") {
            alerts += "Project description (Step 4)\n";
            ai = 3;
        }
        if (values.project_types.includes("Tree Permit")) {
            valObj["tree_reason"] = isRequired(values.tree_reason);
            valObj["tree_replaced"] = isRequired(values.tree_replaced);
            valObj["tree_distance"] = isRequired(values.tree_distance);
            if (valObj["tree_reason"] === "Required") {
                alerts += "Reason for removing tree (Step 5)\n";
                ai = 4;
            }
            if (valObj["tree_replaced"] === "Required") {
                alerts += "To be replaced by (Step 5)\n";
                ai = 4;
            }
            if (valObj["tree_distance"] === "Required") {
                alerts += "Estimated distance from OHWL (Ordinary High Water Line) (Step 5)\n";
                ai = 4;
            }
        }
    
        if (valObj["understand1"] === "Required") {
            alerts += "Understand Agreement (Step 7)\n";
            ai = 6;
        }
        if (alerts) {
            alert("The following fields are missing or invalid:\n" + alerts);
            this.setState({ activeStep: ai });
        }

        return valObj;
    };

    errorValidatorAttachment = (values) => {
        const isRequired = (val) => {
            return !val ? "Required" : null;
        };
        var valObj = {
            file: isRequired(values.file),
            name: isRequired(values.name),
        };

        return valObj;
    };

    submitApplication = (values) => {
        const {
            application,
            ormApplicationRecordCreate,
            ormApplicationRecordStatusCreate,
            ormCLWDPermitCreate,
            ormCLWDAttachmentCreate,
        } = this.props;
        const { temp_attachments } = this.state;

        const _this = this;
        this.setState({ loading: true });

        // Editing
        if (application) {
            
        } else {
            // New
            ormApplicationRecordCreate({
                application: 15,
            }).then((ar) => {
                ormApplicationRecordStatusCreate({
                    applicationrecord: ar.id,
                    status: "Received",
                });
                ormCLWDPermitCreate({
                    applicationrecord: ar.id,
                    ...values,
                }).then((rpb) => {
                    if (rpb && rpb.error) {
                        this.setState({ entryError: true, loading: false });
                        fetch("/error/post", {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                            },
                            body: JSON.stringify({
                                error: JSON.stringify(values),
                                error_info: rpb.error.toString(),
                            }),
                        });
                    } else {
                        var i = 0;
                        var j = 0;

                        temp_attachments.forEach(function (tc) {
                            i++;
                            ormCLWDAttachmentCreate({
                                clwdpermit_id: rpb.id,
                                ...tc,
                            }).then((_z) => {
                                j++;
                                if (i === j) _this.setState({ loading: false, new_save: true });
                            });
                        });
                        if (i === 0) {
                            // No attachments or contacts - its finished loading
                            _this.setState({ loading: false, new_save: true });
                        }
                    }
                });
            });
        }
    };

    addAttachment = (values) => {
        var { attachmentEditing, temp_attachments } = this.state;

        if (!attachmentEditing) {
            temp_attachments.push(values);
            this.setState({ temp_attachments: temp_attachments });
        } else {
            var temp = temp_attachments.filter((tc) => tc !== attachmentEditing);
            temp.push(values);
            this.setState({ temp_attachments: temp });
        }

        this.setState({ attachmentDialogOpen: false });
    };

    handleRequestSort2 = (event, property) => {
        const orderBy = property;
        let order = "desc";

        if (this.state.orderBy === property && this.state.order === "desc") {
            order = "asc";
        }

        order === "desc"
            ? this.state.temp_attachments.sort((a, b) => (b[orderBy].toUpperCase() < a[orderBy].toUpperCase() ? -1 : 1))
            : this.state.temp_attachments.sort((a, b) => (a[orderBy].toUpperCase() < b[orderBy].toUpperCase() ? -1 : 1));

        this.setState({ order, orderBy });
    };

    handleNext = (index) => {
        const { activeStep } = this.state;
        if (index || index === 0) {
            this.setState({ activeStep: index });
        } else if (activeStep < 6) this.setState({ activeStep: activeStep + 1 });
        else {
            this.formApi.submitForm();
        }
    };

    setDialogLoading = (e) =>{
        this.setState({dialogLoading:e})
    };

    setDrawingFeature = (geom) =>{
        if(geom){
            this.formApi.setValue('geometry', geom);
                
            // Parcel Number 
            fetch(
                `https://services5.arcgis.com/UzeEWniNW4z8z71J/arcgis/rest/services/Wild_Rice_MS4Front_Map_WFL1/FeatureServer/23/query?geometry=${geom.coordinates[0]},${geom.coordinates[1]}&outFields=*&geometryType=esriGeometryPoint&inSR=4326&returnGeometry=false&f=pjson`,
                {method : "GET"}
            )
            .then(resp=> resp.json())
            .then(data=>{
                if(data.features.length === 0) {
                    console.log("out of district")
                } else {
                    let res = data.features[0].attributes 
                    this.formApi.setValue('parcel_number',res.county_pin)
                }
            })
                
            // Township Name 
            fetch(
                `https://services5.arcgis.com/UzeEWniNW4z8z71J/arcgis/rest/services/Wild_Rice_MS4Front_Map_WFL1/FeatureServer/26/query?geometry=${geom.coordinates[0]},${geom.coordinates[1]}&outFields=*&geometryType=esriGeometryPoint&inSR=4326&returnGeometry=false&f=pjson`,
                {method : "GET"}
            )
            .then(resp=> resp.json())
            .then(data=>{
                if(data.features.length === 0) {
                    console.log("out of district")
                } else {
                    let res = data.features[0].attributes 
                    this.formApi.setValue('project_township',res.MapLabel + " Township")
                }
            })
        }
    };

    render() {
        const { classes, application } = this.props;
        const {
            activeStep,
            attachmentDialogOpen,
            attachmentEditing,
            attachmentFormKey,
            attachmentOrder,
            attachmentOrderBy,
            temp_attachments,
            loading,
            new_save,
            mainFormKey,
            entryError, 
            projectType,
            drawingFeature,
            createPoint,
            dialogLoading,
            other_permits
        } = this.state;

        //this has all parameters for the basemapcontrolapplication
        var layers = [
        {attribution: "", city_id: 52,city_label: "CLWD",id:52, is_basemap: false,
        layer_type: "Web Map", layernumbers: "3", layerurl: null,
        name: "CLWD Area",on_by_default: true,opacity: 1,order: 2,proxyurl: "",show_popup: false, subdomains: "",
        tileddynamic: "Feature Service",token: "",webmap_id: "677d92f62bec4f90a4abf6312927f33d", wmslayers: "", x: -96.080031, y: 46.772419,
        geocoder_left:[], geocoder_bottom:[], geocoder_right:[],geocoder_top:[], map_level:12 }]
    
        var is_read_only = false;
        if (
            application &&
            application.applicationrecord &&
            (application.applicationrecord.status !== ""
        ))
            is_read_only = true;

        var columnDataAttachments = [
            { id: "actions", numeric: false, label: "", allowSort: false, width: "20px" },
            { id: "name", numeric: false, label: "Name", allowSort: true },
            { id: "_type", numeric: false, label: "Type", allowSort: true },
            { id: "notes", numeric: false, label: "Notes", allowSort: true },
        ];
 
        if (new_save) {
            return (
                <AppContainerApplication city_id={72} page_loading={loading}>
                    <Grid container spacing={1} style={{ padding: "0 12px" }}>
                        <Grid item xs={12} xl={10}>
                            <Typography>
                                This is a confirmation that the permit application has been submitted successfully. A copy of the permit application
                                has been emailed to the primary property owner's email address. Please check your spam and junk folders if you have
                                not received it or contact the district. The district will contact you as the application is being reviewed.
                            </Typography>
                        </Grid>
                    </Grid>
                </AppContainerApplication>
            );
        }
        if (entryError) {
            return (
                <AppContainerApplication city_id={72} page_loading={loading}>
                    <Grid container spacing={1} style={{ padding: "0 12px" }}>
                        <Grid item xs={12} xl={10}>
                            <Typography>
                                This permit application was 'not' successfully saved. Please contact the permit 'managing' staff to alert them of the
                                entry failure.
                            </Typography>
                        </Grid>
                    </Grid>
                </AppContainerApplication>
            );
        }
        if (activeStep === "PRINT")
            return (
                <AppContainerApplication city_id={"72"} page_loading={loading}>
                    <Grid container spacing={1} style={{ padding: "0 12px" }}>
                        <Grid item xs={12} xl={10}>
                            <Typography>Permit Printed.</Typography>
                        </Grid>
                    </Grid>
                </AppContainerApplication>
            );

        return (
            <AppContainerApplication
                city_id={"72"}
                page_loading={loading}
                printing={activeStep === "PRINT"}>
                <Form
                    getApi={(el) => (this.formApi = el)}
                    defaultValues={application || {"county": "Becker"}}
                    key={mainFormKey}
                    pure={false}
                    dontValidateOnMount={true}
                    validateOnSubmit={true}
                    validateError={(values) => this.errorValidator(values)}
                    onSubmit={(values) => this.submitApplication(values)}>
                    {(formApi) => (
                        <form onSubmit={formApi.submitForm}>
                            <>
                                {application && application.applicationrecord && application.applicationrecord.status && (
                                    <Grid container spacing={1} style={{ backgroundColor: "#a3a3a3", marginTop: -15 }}>
                                        <Grid item xs={12} xl={10} classes={{ root: classes.root }}>
                                            <Typography className={classes.centerAlign} variant="h6">
                                                Permit Status: {application && application.applicationrecord.status}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                )}
                                <Grid container spacing={1} style={{ padding: "0 12px" }}>
                                    <Grid item xs={12} xl={10} classes={{ root: classes.root }}>
                                        <Typography className={classes.centerAlign} variant="h6">
                                            Permit Application Form {application && application.permit_number && "#" + application.permit_number}
                                        </Typography>{" "}
                                        <Grid item xs={12} xl={10} className={classes.rightAlign} classes={{ root: classes.root }}></Grid>
                                        <Typography className={classes.centerAlign}>
                                            <b>IMPORTANT:</b> The entire permit application must be filled out at one time and progress will not be
                                            saved so please plan accordingly.
                                        </Typography>
                                        <Typography variant="caption" className={classes.centerAlign2}>
                                            Required Fields are marked with an asterisk (*)
                                        </Typography>
                                    </Grid>
                                    {activeStep !== "PRINT" && (
                                        <Grid item xs={12} xl={10} classes={{ root: classes.root }}>
                                            <Stepper style={{ padding: 8 }} alternativeLabel nonLinear activeStep={activeStep}>
                                                {[
                                                    "Instructions",
                                                    "Applicant Information",
                                                    "Project Location",
                                                    "Project Type",
                                                    "Project Type Details",
                                                    "Document Upload",
                                                    "Landowner Acknowledgement and Submittal",
                                                ].map((label, index) => {
                                                    return (
                                                        <Step key={label}>
                                                            <StepButton style={{ cursor: "pointer" }} onClick={() => this.handleNext(index)}>
                                                                {label}
                                                            </StepButton>
                                                        </Step>
                                                    );
                                                })}
                                            </Stepper>
                                        </Grid>
                                    )}
                                    {activeStep === 0 && (
                                        <Grid item xs={12} xl={10} style={{ marginLeft: 15 }} classes={{ root: classes.root }}>
                                            <Typography variant="subtitle1">
                                                <b>
                                                    {"1. "}
                                                    <u>Preparation</u>
                                                </b>
                                            </Typography>
                                            <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                {`\u2022`} As you work through the online application you will not be able to save your progress for
                                                submittal at a later time.
                                            </Typography>
                                            <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                {`\u2022`} Please have all the required information on hand to ensure you are able to submit a
                                                complete application.
                                            </Typography>
                                            <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                {`\u2022`} For guidance about the required information, please see the{" "}
                                                <a
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    href="https://clwd.org/permits"
                                                    >
                                                    Cormorant Lakes Watershed District Rules
                                                </a>{" "}
                                            </Typography>
                                            <br />
                                            <Typography variant="subtitle1">
                                                <b>
                                                    {"2. "}
                                                    <u>Submit Application</u>
                                                </b>
                                            </Typography>
                                            <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                {`\u2022`} Once you have compiled the required information, complete the permit application and
                                                submit. Again, your progress will not be saved for submittal at a later time.
                                            </Typography>
                                            <br />
                                            <Typography variant="subtitle1">
                                                <b>
                                                    {"3. "}
                                                    <u>Application Review</u>
                                                </b>
                                            </Typography>
                                            <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                {`\u2022`} Once your application has been submitted, CLWD staff will notify you within 15 days if more information is required. 
                                            </Typography>
                                            <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                {`\u2022`} Once all required information has been received, application will be brought to CLWD Board for approval.
                                            </Typography>
                                        </Grid>
                                    )}
                                    {activeStep === 1 && (
                                        <Grid item xs={12} xl={10} style={{ marginLeft: 15 }} classes={{ root: classes.root }}>
                                            <Typography>Please add contacts associated with the application.</Typography>
                                            <Accordion defaultExpanded>
                                                <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ backgroundColor: "#ddd" }}>
                                                    <Typography variant="subtitle1" gutterBottom>
                                                        Primary Property Owner (required)
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails style={{ paddingBottom: 8, backgroundColor: "f6f0f0" }}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="caption">Project Location is on the next page.</Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                disabled={is_read_only}
                                                                field="appl_first_name"
                                                                label="First Name *"
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                disabled={is_read_only}
                                                                field="appl_last_name"
                                                                label="Last Name *"
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                disabled={is_read_only}
                                                                field="appl_affiliation"
                                                                label={
                                                                    <HelpLabel
                                                                        inputLabel="Affiliation"
                                                                        helpText="Please list government unit or private entity that 
                                                                        is applying on behalf of the landowner, if applicable.
                                                                        "
                                                                    />
                                                                }
                                                                fullWidth
                                                            />
                                                        </Grid>
                                               
                                                        <Grid item xs={6}>
                                                            <TextField disabled={is_read_only} field="appl_email" label="Email" fullWidth />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField disabled={is_read_only} field="appl_phone" label="Phone *" fullWidth usePhoneNumberFormat />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField disabled={is_read_only} field="appl_mailing_address" label="Address" fullWidth />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField disabled={is_read_only} field="appl_mailing_address2" label="Address 2" fullWidth />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField disabled={is_read_only} field="appl_city" label="City" fullWidth />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Select
                                                                disabled={is_read_only}
                                                                field="appl_state"
                                                                label="State"
                                                                options={states}
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField disabled={is_read_only} field="appl_zip" label="Zip" fullWidth />
                                                        </Grid>
                                                
                                                    </Grid>
                                                </AccordionDetails>
                                            </Accordion>
                                            <Accordion defaultExpanded>
                                                <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ backgroundColor: "#ddd" }}>
                                                    <Typography variant="subtitle1" gutterBottom>
                                                        Contractor Information
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails style={{ paddingBottom: 8, backgroundColor: "f6f0f0" }}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                disabled={is_read_only}
                                                                field="landowner_first_name"
                                                                label="First Name"
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                disabled={is_read_only}
                                                                field="landowner_last_name"
                                                                label="Last Name"
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                disabled={is_read_only}
                                                                field="landowner_company"
                                                                label="Company Name"
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                disabled={is_read_only}
                                                                field="landowner_email"
                                                                label="Email"
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                disabled={is_read_only}
                                                                field="landowner_phone"
                                                                label="Phone"
                                                                fullWidth
                                                                usePhoneNumberFormat
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField disabled={is_read_only} field="landowner_mailing_address" label="Address" fullWidth />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField disabled={is_read_only} field="landowner_mailing_address2" label="Address 2" fullWidth />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField disabled={is_read_only} field="landowner_city" label="City" fullWidth />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Select
                                                                disabled={is_read_only}
                                                                field="landowner_state"
                                                                label="State"
                                                                options={states}
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField disabled={is_read_only} field="landowner_zip" label="Zip" fullWidth />
                                                        </Grid>
                                                    </Grid>
                                                </AccordionDetails>
                                            </Accordion>{" "}
                                        </Grid>
                                    )}
                                    {activeStep === "PRINT" && (
                                        <>
                                            <div style={{ height: 50 }}></div>
                                            <Grid item xs={12} xl={10} style={{ marginLeft: 15 }} classes={{ root: classes.root }}>
                                                <Divider className={classes.printDivider} />
                                            </Grid>
                                        </>
                                    )}
                                    {activeStep === 2 && (
                                        <Grid item xs={12} xl={10} style={{ marginLeft: 15 }} classes={{ root: classes.root }}>
                                            <Grid container spacing={2}>
                                            <Grid item xs={12} xl={10} classes={{ root: classes.root }}>
                                                <Typography variant="subtitle1" gutterBottom style={{marginBottom:-7, marginLeft:14}}>
                                                    <b>Instructions:</b> 
                                                </Typography>
                                                <Typography variant="subtitle1" gutterBottom style={{ marginLeft:16}}>
                                                    Step 1: Use the + or - buttons to zoom the map to your permit locations. Alternatively you can type an address or road intersection into the search box to zoom the map to the general area of your permit.
                                                </Typography>
                                                 <Typography variant="subtitle1" gutterBottom style={{  marginLeft:16}}>
                                                 Step 2: Click on the Mapping Tools button labeled "Add a New Permit Location". This will change your mouse cursor to a blue dot. Click on the map to place your location of your permit. For linear projects place it on the approximate mid-point of the project. For other projects place the point on the best place that represents the location of your project.
                                                </Typography>
                                                <Typography variant="subtitle1" gutterBottom style={{  marginLeft:16}}>
                                                Step 3: After you place the point on the map, the Project Location fields will automatically populate. Verify those are the correct information. 
                                                </Typography>
                                                <Typography variant="subtitle1" gutterBottom style={{  marginLeft:16}}>
                                                Step 4: If you need to modify the location of the point location on the map, Click Delete Project Location button and repeat Step 2. Otherwise click the Next button to proceed to the Project Type step. 
                                                </Typography> 
                                            </Grid>
                                            <Grid item xs={12} xl={10} style={{ marginLeft: 15, marginRight:15 }} classes={{ root: classes.root }}>
                                                <BaseMapControlApplication
                                                    geometry={application && application.geometry?application.geometry:null}
                                                    disableSearch={application && application.geometry?true:false}
                                                    viewOnly
                                                    application={application}
                                                    setDialogLoading={this.setDialogLoading} 
                                                    setDrawingFeature={this.setDrawingFeature}
                                                    drawingFeature={drawingFeature}
                                                    createPoint={createPoint}
                                                    setPoint={this.setPoint}
                                                    layers={layers}
                                                    cityID={"72"}  
                                                /> 
                                                {dialogLoading && ( 
                                                    <CircularProgress
                                                        size={72}
                                                        style={{ display: "table", marginLeft: "auto", marginRight: "auto", zIndex:90000 }}
                                                    /> 
                                                )}
                                            </Grid> 
                                                <Grid item xs={12}>
                                                    <Typography variant="subtitle1" gutterBottom>
                                                        <b>Project Location (Parcel Number is required)</b>
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <TextField disabled={is_read_only} field="parcel_number" label="Parcel Number *" fullWidth />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <TextField disabled={is_read_only} field="project_address" label="Parcel Address" fullWidth />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Select
                                                        disabled={is_read_only}
                                                        field="lake"
                                                        label="Lake"
                                                        options={MAKE_OPTIONS(["Arrow","Big Cormorant","Bijou","Dahlberg","Eilertson","Erickson","Hougland","Larson","Leaf","Middle Cormorant","Mollar","Nelson","Round","Tub","Upper Cormorant","West"])}
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Select
                                                        disabled={is_read_only}
                                                        field="county"
                                                        label="County"
                                                        options={MAKE_OPTIONS(["Becker"])}
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Select
                                                        disabled={is_read_only}
                                                        field="project_township"
                                                        label="Township Name"
                                                        options={MAKE_OPTIONS(["Audubon Township", "Cormorant Township", "Lake Eunice Township", "Lake Park Township"])}
                                                        fullWidth
                                                    />
                                                </Grid>   
                                            </Grid>
                                        </Grid>
                                    )}
                                     {activeStep === 3 && (
                                        <Grid container spacing={2} style={{ marginLeft: 15 }} classes={{ root: classes.root }}>
                                            <Grid item xs={12} lg={10} xl={8}>
                                                <CheckboxGroup
                                                    field="project_types"
                                                    label="Select Project Types: (Check all that apply)"
                                                    required
                                                    row={true}
                                                    disabled={is_read_only}
                                                    eventHandle={(e) => {
                                                        this.setState({projectType:e})
                                                    }}
                                                    options={MAKE_OPTIONS([
                                                        "Land Development", "Tree Permit"
                                                    ])}
                                                />
                                            </Grid>
                                            <Grid item xs={12} lg={10} xl={8}>
                                                <TextField
                                                    field="project_description"
                                                    label={
                                                        <HelpLabel
                                                            inputLabel="Provide description of proposed project *:"
                                                            helpText="Applicant must provide a detailed project description. Application will be considered <u>incomplete</u> until adequate information is provided."
                                                        />
                                                    }
                                                    multiline
                                                    rows={8}
                                                    disabled={is_read_only}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={12} lg={10} xl={8}>
                                                <Checkbox disabled={is_read_only} label="Site visit requested with CLWD Staff/Managers" field="site_visit" />
                                                <Checkbox disabled={is_read_only}
                                                    eventHandle={(e) => {
                                                        this.setState({other_permits:e})
                                                    }} label="Applying for other permits with Becker County or other jurisdictions" field="other_permits" />
                                            </Grid>
                                            {other_permits && (
                                                <Grid item xs={12} lg={10} xl={8}>
                                                    <TextField
                                                        field="other_permits_explain"
                                                        label="Please explain"
                                                        multiline
                                                        rows={8}
                                                        style={{marginLeft:40}}
                                                        disabled={is_read_only}
                                                        fullWidth
                                                    />
                                                </Grid>
                                            )}
                                        </Grid>
                                    )}
                                    {activeStep === "PRINT" && (
                                        <Grid item xs={12} xl={10} style={{ marginLeft: 15 }} classes={{ root: classes.root }}>
                                            <Divider className={classes.printDivider} />
                                        </Grid>
                                    )}
                                    {activeStep === 4 && projectType.length === 0 && (
                                        <Grid item xs={12} xl={10} style={{ marginLeft: 15 }} classes={{ root: classes.root }}>
                                            <Typography variant="subtitle1" gutterBottom style={{textAlign: "center", textDecoration: "underline",fontWeight: "bold"}}>
                                                No Project Types have been selected. Refer to Step 4 and select a 'Project Type'
                                            </Typography>
                                        </Grid>
                                    )}
                                    {activeStep === 4 && projectType.length > 0 && projectType.find(t=>t==="Tree Permit") && (   
                                        <Grid item xs={12} xl={10} style={{ marginLeft: 15 }} classes={{ root: classes.root }}>
                                           <Accordion defaultExpanded>
                                                <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ backgroundColor: "#ddd" }}>
                                                    <Typography variant="subtitle1" gutterBottom>
                                                        Tree Permit
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails style={{ paddingBottom: 8, backgroundColor: "f6f0f0" }}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12} lg={6} xl={4}>
                                                            <TextField
                                                                disabled={is_read_only}
                                                                field="tree_reason"
                                                                label="Reason for removing tree *"
                                                                fullWidth
                                                            />
                                                        </Grid> 
                                                        <Grid item xs={12} lg={6} xl={4}>
                                                            <TextField
                                                                disabled={is_read_only}
                                                                field="tree_replaced"
                                                                label="To be replaced by *"
                                                                fullWidth
                                                            />
                                                        </Grid> 
                                                        <Grid item xs={12} lg={6} xl={4}>
                                                            <TextField
                                                                disabled={is_read_only}
                                                                field="tree_distance"
                                                                label="Estimated distance from OHWL (Ordinary High Water Line) *"
                                                                fullWidth
                                                                units="ft"
                                                                isInteger
                                                            />
                                                        </Grid> 
                                                        <Grid item xs={12} lg={6} xl={4}>
                                                            <TextField
                                                                disabled={is_read_only}
                                                                field="tree_species"
                                                                label="Species of tree to be removed"
                                                                fullWidth
                                                            />
                                                        </Grid> 
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                disabled={is_read_only}
                                                                field="tree_other"
                                                                label="Other Information"
                                                                fullWidth
                                                                multiline
                                                                rows={8}
                                                            />
                                                        </Grid> 
                                                    </Grid>
                                                </AccordionDetails>
                                            </Accordion>
                                        </Grid>
                                    )}
                                    
                                    {activeStep === 4 && projectType.length > 0 && projectType.find(t=>t==="Land Development") && (   
                                        <Grid item xs={12} xl={10} style={{ marginLeft: 15 }} classes={{ root: classes.root }}>
                                           <Accordion defaultExpanded>
                                                <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ backgroundColor: "#ddd" }}>
                                                    <Typography variant="subtitle1" gutterBottom>
                                                        Land Development
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails style={{ paddingBottom: 8, backgroundColor: "f6f0f0" }}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12} md={6} xl={4}>
                                                            <RadioGroup
                                                                field="l_stormsewer"
                                                                disabled={is_read_only}
                                                                fullWidth
                                                                options={[
                                                                    { label: "Yes", value: "Yes" },
                                                                    { label: "No", value: "No" },
                                                                ]}
                                                                alignment={true}
                                                                label="Project is a municipal storm sewer project"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} md={6} xl={4}>
                                                            <RadioGroup
                                                                field="l_runoff"
                                                                disabled={is_read_only}
                                                                fullWidth
                                                                options={[
                                                                    { label: "Yes", value: "Yes" },
                                                                    { label: "No", value: "No" },
                                                                ]}
                                                                alignment={true}
                                                                label="Project design incorporates stormwater ponding or other runoff reduction methods"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} xl={9}>
                                                            <TextField
                                                                disabled={is_read_only}
                                                                field="l_info"
                                                                multiline
                                                                rows={4}
                                                                label="Additional Information for Land Development Project"
                                                                fullWidth
                                                            />
                                                        </Grid> 
                                                        <Grid item xs={12} md={6} xl={4}>
                                                            <TextField
                                                                disabled={is_read_only}
                                                                field="cubic_yards"
                                                                label="How many cubic yards:"
                                                                units="yd3"
                                                                useNumberFormat3 
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} md={6} xl={4}>
                                                            <TextField
                                                                disabled={is_read_only}
                                                                field="material"
                                                                label={
                                                                    <HelpLabel
                                                                        inputLabel="Material"
                                                                        helpText="Material(s) to be used in project"
                                                                    />
                                                                }
                                                                fullWidth
                                                            />
                                                        </Grid> 
                                                        <Grid item xs={12} md={6} xl={4}>
                                                            <Checkbox disabled={is_read_only} label="Work is being done in shore impact zone (50' from OHW)" field="impact_zone" />
                                                        </Grid> 
                                                        <Grid item xs={12} md={6} xl={4}>
                                                            <TextField
                                                                disabled={is_read_only}
                                                                field="far_back"
                                                                label="How far back from OHWL (Ordinary High Water Line) is project:"
                                                                units="ft"
                                                                useNumberFormat3 
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} md={6} xl={4}>
                                                            <TextField
                                                                disabled={is_read_only}
                                                                field="shoreline_length"
                                                                label="Shoreline Length"
                                                                units="ft"
                                                                useNumberFormat3 
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} md={6} xl={4}>
                                                            <TextField
                                                                disabled={is_read_only}
                                                                field="lot_depth"
                                                                label="Lot Depth"
                                                                units="ft"
                                                                useNumberFormat3 
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </AccordionDetails>
                                            </Accordion>
                                        </Grid>
                                    )}
                                    {activeStep === "PRINT" && (
                                        <Grid item xs={12} xl={10} classes={{ root: classes.root }}>
                                            <Divider className={classes.printDivider} />
                                        </Grid>
                                    )}
                                    {activeStep === 5 && ( 
                                        <Grid item xs={12} xl={10} style={{ marginLeft: 15 }} classes={{ root: classes.root }}>
                                            <Typography variant="subtitle1">Attachments</Typography>
                                            <Typography gutterBottom>
                                                
                                                Please attached all electronic files associated with your applications. If document is not available for upload, 
                                                it shall be emailed to the CLWD at <a
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                                href='mailto:admin@clwd.org'>
                                                                admin@clwd.org
                                                            </a>. Application will not be reviewed or considered complete until appropriate documents have been submitted. 
                                                Below is the list of expected documents and the information those documents should contain
                                            </Typography>
                                            <Button
                                                onClick={() =>
                                                    this.setState({
                                                        attachmentDialogOpen: true,
                                                        attachmentEditing: null,
                                                        attachmentFormKey: window.performance.now(),
                                                    })
                                                }
                                                disabled={is_read_only}
                                                variant="contained"
                                                color="primary">
                                                Add Attachment
                                            </Button>
                                            <Table style={{ marginTop: 8 }}>
                                                <EnhancedTableHead
                                                    columnData={columnDataAttachments}
                                                    order={attachmentOrder}
                                                    orderBy={attachmentOrderBy}
                                                    onRequestSort={this.handleRequestSort2}
                                                />
                                                <TableBody>
                                                    {temp_attachments.map((n,idx) => {
                                                        return (
                                                            <TableRow key={idx} hover>
                                                                <CustomTableCell className={classes.nowrap}>
                                                                    {n.file.includes("https") && (
                                                                        <Tooltip title="Open Link">
                                                                            <Button
                                                                                color="primary"
                                                                                onClick={() => {
                                                                                    window.open(n.file);
                                                                                }}
                                                                                className={classes.deleteWidth}>
                                                                                <ViewIcon color="primary" />
                                                                            </Button>
                                                                        </Tooltip>
                                                                    )}
                                                                    <Tooltip title="Delete">
                                                                        <Button
                                                                            color="primary"
                                                                            onClick={() =>
                                                                                this.setState({
                                                                                    temp_attachments: temp_attachments.filter((tc) => tc !== n),
                                                                                })
                                                                            }
                                                                            disabled={is_read_only}
                                                                            className={classes.deleteWidth}>
                                                                            <DeleteIcon color="primary" />
                                                                        </Button>
                                                                    </Tooltip>
                                                                </CustomTableCell>
                                                                <CustomTableCell>{n.name}</CustomTableCell>
                                                                <CustomTableCell>{n._type}</CustomTableCell>
                                                                <CustomTableCell>{n.notes}</CustomTableCell>
                                                            </TableRow>
                                                        );
                                                    })}
                                                    {temp_attachments.length < 1 && (
                                                        <TableRow>
                                                            <CustomTableCell colSpan={columnDataAttachments.length} className={classes.centerAlign}>
                                                                No Attachments Added
                                                            </CustomTableCell>
                                                        </TableRow>
                                                    )}
                                                </TableBody>
                                            </Table>
                                        </Grid>
                                    )}
                                    {activeStep === 5 && projectType.length > 0 && projectType.find(t=>t==="Land Development") && ( 
                                        <Grid item xs={12} xl={10} style={{ marginLeft: 15 }} classes={{ root: classes.root }}>
                                            <Accordion expanded>
                                                <AccordionSummary style={{ backgroundColor: "#ddd" }}>
                                                    <Typography variant="subtitle1" gutterBottom>
                                                        <b>Land Development Documents to Upload:</b>
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails style={{ paddingBottom: 8, backgroundColor: "f6f0f0" }}>
                                                    <Grid item xs={12} xl={10} style={{ marginLeft: 15 }} classes={{ root: classes.root }}>
                                                        <Typography variant="subtitle1" gutterBottom>
                                                            1.) Detailed construction plans.<br/> 
                                                        </Typography>
                                                        <Typography variant="subtitle1" gutterBottom>
                                                            2.) Site Map, must include:<br/> 
                                                        </Typography>
                                                        <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                            a.) Location of proposed work.
                                                        </Typography>
                                                        <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                            b.) Location of other special features.
                                                        </Typography>
                                                        <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                            c.) Receiving waters.
                                                        </Typography>  
                                                        <Typography variant="subtitle1" gutterBottom>
                                                            3.) Stormwater Report showing modeling results of pond design.<br/> 
                                                        </Typography>
                                                        <Typography variant="subtitle1" gutterBottom>
                                                            4.) Stormwater Pollution Prevention Plan (SWPPP).<br/> 
                                                        </Typography> 
                                                    </Grid>
                                                </AccordionDetails>
                                            </Accordion>
                                        </Grid>
                                    )}
                                    {activeStep === 5 && projectType.length > 0 && projectType.find(t=>t==="Tree Permit") && ( 
                                        <Grid item xs={12} xl={10} style={{ marginLeft: 15 }} classes={{ root: classes.root }}>
                                            <Accordion expanded>
                                                <AccordionSummary style={{ backgroundColor: "#ddd" }}>
                                                    <Typography variant="subtitle1" gutterBottom>
                                                        <b>Tree Permit Documents to Upload:</b>
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails style={{ paddingBottom: 8, backgroundColor: "f6f0f0" }}>
                                                    <Grid item xs={12} xl={10} style={{ marginLeft: 15 }} classes={{ root: classes.root }}>
                                                        <Typography variant="subtitle1" gutterBottom>
                                                            1.) Please upload a picture of each tree to be removed.<br/> 
                                                        </Typography>
                                                    </Grid>
                                                </AccordionDetails>
                                            </Accordion>
                                        </Grid>
                                    )}
                                    {activeStep === "PRINT" && (
                                        <Grid item xs={12} xl={10} classes={{ root: classes.root }}>
                                            <Divider className={classes.printDivider} />
                                        </Grid>
                                    )}
                                    {activeStep === 6 && (
                                        <Grid item xs={12} xl={10} style={{ marginLeft: 15 }} classes={{ root: classes.root }}>
                                            <Typography variant="subtitle1" gutterBottom>
                                                <b>I understand:</b>
                                            </Typography>
                                            <table>
                                                {" "}
                                                <tr>
                                                    <td>
                                                        <Typography gutterBottom style={{marginBottom:3, marginLeft:3}}>
                                                            1. Submitting this application in no way relieves the applicant or landowner from any 
                                                            responsibility or liability resulting from the construction, operation, or failure of the project.
                                                        </Typography> 
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <Typography gutterBottom style={{marginBottom:3, marginLeft:3}}>
                                                            2. The project may be field reviewed prior to the Board Meeting. 
                                                            The applicant grants permission to the CLWD and their representatives to review the work area within the permit application.
                                                        </Typography> 
                                                    </td>
                                                </tr> 
                                                <tr>
                                                    <td>
                                                        <Typography gutterBottom style={{marginBottom:3, marginLeft:3}}>
                                                            3. The application must be considered complete a minimum of 10 days prior to the Regular Board
                                                            Meeting to be considered. CLWD staff will contact you within 15 days if additional information is required.
                                                        </Typography> 
                                                    </td>
                                                </tr> 
                                                <tr>
                                                    <td>
                                                        <Typography gutterBottom style={{marginBottom:3, marginLeft:3}}>
                                                            4. The requirements of the Permit Rules of the CLWD.
                                                        </Typography> 
                                                    </td>
                                                </tr> 
                                                <tr>
                                                    <td>
                                                        <Typography gutterBottom style={{marginBottom:3, marginLeft:3}}>
                                                            5. This permit does not relieve the applicant of any requirements of other permits which may be necessary 
                                                            from any other permitting agency.
                                                        </Typography> 
                                                    </td>
                                                </tr> 
                                                <tr>
                                                    <td>
                                                        <Typography gutterBottom style={{marginBottom:3, marginLeft:3}}>
                                                            6. I acknowledge by submitting this permit application is equivalent to my manual/handwritten signature.
                                                        </Typography> 
                                                    </td>
                                                </tr>  
                                            </table>
                                            <table>
                                                <tr>
                                                    <td style={{ whiteSpace: "nowrap", paddingRight: 16 }}>
                                                        <Checkbox disabled={is_read_only} label="I AGREE *" field="understand1" />
                                                    </td>
                                                    <td>
                                                        <Typography gutterBottom style={{marginBottom:0}}>
                                                            In checking this box, I acknowledge the above statements.
                                                        </Typography> 
                                                    </td>
                                                </tr> 
                                            </table>
                                        </Grid>
                                    )}
                                    {activeStep !== "PRINT" && (
                                        <Grid item xs={12} xl={10} className={classes.rightAlign} classes={{ root: classes.root }}>
                                            <Button
                                                disabled={activeStep === 0}
                                                onClick={() => this.setState({ activeStep: activeStep - 1 })}
                                                className={classes.button}>
                                                Back
                                            </Button>
                                            <Button
                                                onClick={() => this.handleNext()}
                                                disabled={is_read_only && activeStep === 5}
                                                variant="contained"
                                                color="primary"
                                                style={{ marginLeft: 16 }}>
                                                {activeStep === 6 ? "Submit Permit Application" : "Next"}
                                            </Button>
                                        </Grid>
                                    )}
                                </Grid>
                            </>
                        </form>
                    )}
                </Form>
                <Dialog open={attachmentDialogOpen} classes={{ paper: classes.minWidth }}>
                    <Toolbar>
                        <Typography variant="h6" className={classes.flex}>
                            Attachment
                        </Typography>
                        <IconButton aria-label="Close Dialog" onClick={() => this.setState({ attachmentDialogOpen: false })}>
                            <Close />
                        </IconButton>
                    </Toolbar>
                    <DialogContent className={classes.dialogMin}>
                        <DialogContentText>
                            <Form
                                dontValidateOnMount={true}
                                validateOnSubmit={true}
                                key={attachmentFormKey}
                                defaultValues={attachmentEditing}
                                validateError={this.errorValidatorAttachment}
                                onSubmit={(values) => this.addAttachment(values)}>
                                {(formApi) => (
                                    <form onSubmit={formApi.submitForm}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Typography gutterBottom>
                                                    Only these file types can be directly uploaded as an attachment. All other file types should be
                                                    put into a .zip file and then uploaded. Direct supported file types are: PDF (.pdf), Microsoft
                                                    Word (.doc, .docx), Microsoft Excel (.xls, .xlsx), Images (.png, .jpg, .jpeg, .tiff, .bmp),
                                                    Microsoft Powerpoint (.ppt, .pptx), Zip files (.zip).
                                                </Typography>
                                                <FileInput field="file" id="file_upl" fullWidth label="File *" />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField field="name" label="Name *" fullWidth />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Select
                                                    field="_type"
                                                    label="Type"
                                                    options={MAKE_OPTIONS(["Site Map", "Downstream Landownder Notification", "Engineer's Report", "Detailed Construction Plans",
                                                            "Stormwater Report Showing MOdeling Results of Pond Design", "Stormwater Pollution Prevention Plan (SWPPP)", "Detailed Project Plans",
                                                            "Photo", "Other"])}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField field="notes" label="Notes" fullWidth />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Button fullWidth onClick={() => this.setState({ attachmentDialogOpen: false })}>
                                                    Cancel
                                                </Button>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Button fullWidth type="submit" variant="contained" color="primary">
                                                    Save
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </form>
                                )}
                            </Form>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
            </AppContainerApplication>
        );
    }
}

CLWD = connect(
    (state, ownProps) => ({
        application: getApplication(state, ownProps),
        authState: state.auth, // Admins will have an authstate
    }),
    {
        ...ApplicationRecord.actions,
        ...ApplicationRecordStatus.actions,
        ...CLWDPermit.actions,
        ...CLWDAttachment.actions
    }
)(CLWD);

export default withStyles(styles)(withRouter(CLWD));
