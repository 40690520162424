import React, { Component } from "react";
import { loadModules } from "esri-loader";
import classNames from "classnames";
import { Tooltip, withStyles } from "@material-ui/core";

const styles = (theme) => ({
    panel1: {
        backgroundColor: "#f3f3f3",
        position: "absolute",
        top: 145,
        right: "8%",
        width: 350,
        boxShadow: "0 1px 2px rgba(0, 0, 0, 0.3)",
        "& > div": {
            margin: 8,
        },
        "& > ul": {
            padding: 0,
        },
        "& > ul > li": {
            backgroundColor: "#fff",
            margin: "3px 0px",
            borderBottom: "1px solid rgba(110,110,110,0.3)",
        },
        "& > ul > li > div": {
            padding: "6px 3px 6px 5px",
        },
    },
    panel1Large: {
        backgroundColor: "#f3f3f3",
        position: "absolute",
        top: "60px",
        right: "8%",
        width: 400,
        boxShadow: "0 1px 2px rgba(0, 0, 0, 0.3)",
        "& > div": {
            margin: 8,
        },
        "& > ul": {
            padding: 0,
        },
        "& > ul > li": {
            backgroundColor: "#fff",
            margin: "3px 0px",
            borderBottom: "1px solid rgba(110,110,110,0.3)",
        },
        "& > ul > li > div": {
            padding: "6px 3px 6px 5px",
        },
        zIndex: -1
    },
    section: {
        marginLeft: 5,
    },
    list: {
        listStyleType: "none",
        paddingInlineStart: 0,
        maxHeight: "calc(100vh - 160px)",
        overflowY: "auto",
        margin: 0,
    },
    listSmall: {
        listStyleType: "none",
        paddingInlineStart: 0,
        maxHeight: 350,
        overflowY: "auto",
        margin: 0,
    },
    list1: {
        listStyleType: "none",
        paddingInlineStart: "0px",
    },
    text: {
        fontSize: 14,
        fontFamily: '"Avenir Next","Helvetica Neue",Helvetica,Arial,sans-serif',
        color: "#323232",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    text1: {
        fontSize: 14,
        fontFamily: '"Avenir Next","Helvetica Neue",Helvetica,Arial,sans-serif',
        color: "#9b9090",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    flex: {
        display: "flex",
    },
    legendsquare: {
        width: 9,
        height: 9,
        marginTop: 4,
        minWidth: 9,
    },

    legendcircle: {
        width: 9,
        height: 9,
        marginTop: 4,
        minWidth: 9,
        borderRadius: "50%",
    },

    legendtriangle: {
        width: 0,
        height: 0,
        borderLeft: "6px solid transparent",
        borderRight: "6px solid transparent",
        borderBottom: "12px solid",
        marginTop: 3
    },

    legenddiamond: {
        marginTop: 4,
        height: 9,
        textAlign: "center",
        transform:"rotate(45deg)",
        width:9
    },

    legendcross3: {
        height: 15,
        position: "relative",
        width: 3,
        marginLeft: 5,
        marginRight: 5
    },
    legendcross4: {
        height: 3,
        width: 15,
        marginLeft: -14,
        marginTop: 6
    },

    legendcross: {
        height: 10,
        position: "relative",
        width: 2,
        marginLeft: 6,
        marginRight: 4,
        marginTop: 4,
    },
    legendcross2: {
        height: 2,
        left: -4,
        position: "absolute",
        top: 4,
        width: 10,
    },
    legendmulti: {
        width: 4,
        height: 4,
        marginTop: 4,
        minWidth: 4,
        borderRadius: "50%",
    },
    legendmulti2: {
        width: 4,
        height: 4,
        marginTop: 4,
        minWidth: 4,
        marginLeft: 4,
        borderRadius: "50%",
    },
    legendmulti3: {
        width: 4,
        height: 4,
        marginTop: 12,
        minWidth: 4,
        marginRight: 4,
        marginLeft: -8,
        borderRadius: "50%",
    },
    legendpolygon: {
        position: "relative",
        width: 10.8,
        boxSizing: "content-box",
        borderWidth: "10px 3.6px 0",
        borderStyle: "solid",
        marginTop: 6,
    },
    legendpolygon2: {
        position: "absolute",
        height: 0,
        width: 0,
        top: -17,
        left: -3.6,
        borderWidth: "0 9px 7px",
        borderStyle: "solid",
    },
    item_li: {
        wordBreak: "break-word",
        display: "flex",
    },
    pushLeft: {
        marginLeft: 15,
    },
    pushLeft2: {
        marginLeft: 25,
    },
    pushLeft3: {
        marginLeft: 35,
    },
    hidden: {
        display: "none !important"
    },

    tab: {
        overflow: "hidden",
        border: "1px solid #ccc",
        backgroundColor: "#f1f1f1",
        position: "absolute",
        top: 34,
        width: 398,
        right: 0
    },
    tabSmall: {
        overflow: "hidden",
        border: "1px solid #ccc",
        backgroundColor: "#f1f1f1",
        position: "absolute",
        top: 34,
        width: "auto",
        right: 0
    },
  
    tablinks: {
        outline: "none",
        cursor: "pointer",
        padding: "4px 2px",
        transition: "0.3s",
        "&:hover": {
            backgroundColor: "#ddd"
        },
    },
    tablinksSmall: {
        outline: "none",
        cursor: "pointer",
        padding: "4px 2px",
        transition: "0.3s",
        "&:hover": {
            backgroundColor: "#ddd"
        },
        width: "100%"
    },
    active: {
        backgroundColor: "#ccc"
    },
    tabcontent: {
        display: "none",
        padding: "6px 12px",
        border: "1px solid #ccc",
        borderTop: "none"
    },
});

const RANGE = [
    {
        value: 100000000,
        label: "World",
    },
    {
        value: 50000000,
        label: "Continet",
    },
    {
        value: 25000000,
        label: "Countries - Big",
    },
    {
        value: 12000000,
        label: "Countries - Small",
    },
    {
        value: 6000000,
        label: "States / Provinces",
    },
    {
        value: 3000000,
        label: "States/Province",
    },
    {
        value: 1500000,
        label: "Counties",
    },
    {
        value: 750000,
        label: "County",
    },
    {
        value: 320000,
        label: "Metropolitan Area",
    },
    {
        value: 160000,
        label: "Cities",
    },
    {
        value: 80000,
        label: "City",
    },
    {
        value: 40000,
        label: "Town",
    },
    {
        value: 20000,
        label: "Neighborhood",
    },
    {
        value: 10000,
        label: "Streets",
    },
    {
        value: 5000,
        label: "Street",
    },
    {
        value: 2500,
        label: "Buildings",
    },
    {
        value: 1250,
        label: "Building",
    },
    {
        value: 800,
        label: "Small Building",
    },
    {
        value: 400,
        label: "Rooms",
    },
    {
        value: 100,
        label: "Room",
    },
];
function CloserIndex(i, scale, range) {
    var j = i > 0 ? i - 1 : i;
    var k = i === range.length - 1 ? i : i + 1;
    if (Math.abs(range[j] - scale) > Math.abs(range[k] - scale)) {
        return j;
    } else {
        return k;
    }
}
function getMin(scale) {
    //var value = RANGE[0].value;
    var j = 0;
    for (var i = RANGE.length - 1; i >= 0; i--) {
        if (RANGE[i].value < scale) {
            //value = RANGE[i - 1].label;
            j = i - 1;
        }
    }
    //return value;
    return RANGE[CloserIndex(j, scale, RANGE)].label;
}
function getMax(scale) {
    var value = RANGE[RANGE.length - 1].value;
    for (var i = 0; i < RANGE.length; i++) {
        if (RANGE[i].value > scale) {
            value = RANGE[i].label;
        }
    }
    return value;
}

function invertColor(hex) {
    if (hex.indexOf("#") === 0) {
        hex = hex.slice(1);
    }
    // convert 3-digit hex to 6-digits.
    if (hex.length === 3) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    if (hex.length !== 6) {
        throw new Error("Invalid HEX color.");
    }
    var r = parseInt(hex.slice(0, 2), 16);
    var g = parseInt(hex.slice(2, 4), 16);
    var b = parseInt(hex.slice(4, 6), 16);
    if ((r*0.299 + g*0.587 + b*0.114) > 149) 
        return "#000000";
    else 
        return "#FFFFFF";
}

function invertColor2(hex) {
    if (hex.indexOf("#") === 0) {
        hex = hex.slice(1);
    }
    // convert 3-digit hex to 6-digits.
    if (hex.length === 3) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    if (hex.length !== 6) {
        throw new Error("Invalid HEX color.");
    }
    var r = parseInt(hex.slice(0, 2), 16);
    var g = parseInt(hex.slice(2, 4), 16);
    var b = parseInt(hex.slice(4, 6), 16);
    if ((r*0.299 + g*0.587 + b*0.114) > 149) 
        return "#FFFFFF";
    else 
        return "#000000";
}

function hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
        ? {
              r: parseInt(result[1], 16),
              g: parseInt(result[2], 16),
              b: parseInt(result[3], 16),
          }
        : null;
}

class Control extends Component {
    state = {
        layerClosed: false,
        drainageClosed: false,
        searchClosed: true,
        baseClosed: true,
        referenceClosed: true,
        pt: null,
        controlKey: performance.now(),
        baseMapIndex: this.props.intialBaselayerId,
        beginScale: 0,
    };
    ms4Layers = [];
    ms4SearchLayers = [];

    componentDidMount() {
        const { viewOnly, view } = this.props;
        if (viewOnly) this.setState({ layerClosed: true });

        loadModules(["esri/core/watchUtils"], { css: true }).then(([watchUtils]) => {
            const _this = this;
            this.scaleHandler = watchUtils.when(view, "stationary", function () {
                const beginScale = view.get("scale");
                _this.setState({ beginScale });
            });
        });
    }

    componentWillUnmount = () => {
        if (this.scaleHandler) {
            this.scaleHandler.remove();
        }
    };

    onToggle = (e, layer_id, layer_title, color, component, point_symbol, point_size) => {
        var toggle_layer = component.ms4Layers.find((a) => a.title === layer_title);
        // The layer is already loading, cancel request
        if (this.state["loading_" + layer_id] === true) {
            this.setState({ ["loading_" + layer_id]: false });
            this.state["controller_" + layer_id].abort();
            return;
        }
        if (!toggle_layer) {
            this.setState({ ["loading_" + layer_id]: true });
            this.loadGeometry(layer_id, layer_title, color, point_symbol, point_size);
        } else {
            toggle_layer.visible = e.target.checked;
            this.setState({ controlKey: performance.now() });
        }
    };

    onToggleGroup = (e, layer_id, layer_title, color, component, point_symbol, point_size) => {
        var toggle_layer = component.ms4Layers.find((a) => a.title === layer_title + layer_id);
        // The layer is already loading, cancel request
        if (this.state["grouploading_" + layer_id] === true) {
            this.setState({ ["grouploading_" + layer_id]: false });
            this.state["groupcontroller_" + layer_id].abort();
            return;
        }
        if (!toggle_layer) {
            this.setState({ ["grouploading_" + layer_id]: true });
            this.loadGeometryGroup(layer_id, layer_title, color, point_symbol, point_size);
        } else {
            toggle_layer.visible = e.target.checked;
            this.setState({ controlKey: performance.now() });
        }
    };

    onToggleAdv = (e, layer_id, layer_title, color, component) => {
        var toggle_layer = component.ms4SearchLayers.find((a) => a.title === layer_title);
        // The layer is already loading, cancel request
        if (this.state["loading_" + layer_id] === true) {
            this.setState({ ["loading_" + layer_id]: false });
            this.state["controller_" + layer_id].abort();
            return;
        }
        if (!toggle_layer && !this.state["loading_" + layer_id]) {
            this.setState({ ["loading_" + layer_id]: true });
            this.loadGeometryAdv(layer_id, layer_title, color);
        } else {
            toggle_layer.visible = e.target.checked;
            this.setState({ controlKey: performance.now() });
        }
    };

    onToggleReference = (e, x) => {
        x.visible = e.target.checked;
        this.setState({ controlKey: performance.now() });
    };

    layerToggle = () => {
        const { layerClosed } = this.state;
        this.setState({ layerClosed: !layerClosed });
    };

    radioSwitch = (evt, name, id, newBaseMap) => {
        const { view, /*map, changeBaseMap*/ } = this.props;
        //map.add(newBaseMap)
        loadModules(["esri/widgets/BasemapToggle/BasemapToggleViewModel"], { css: true }).then(([BasemapToggleVM]) => {
            //changeBaseMap(name);

            var basemapToggle = new BasemapToggleVM({
                view: view,
                nextBasemap: newBaseMap,
            });
            basemapToggle.toggle();
            // This is really hacky
            // As of esri js api 4.24 .toggle() needs to be called twice for it to load correctly, but only the first time
            // But the default basemaps work fine (thumbnailUrl)
            if (!newBaseMap.loaded && !newBaseMap.thumbnailUrl) {
                setTimeout(function() {
                    basemapToggle.toggle();
                }, 300)
            }
            this.setState({ baseMapIndex: id });
        })
    };

    getClusterConfig = (type, color, point_symbol) => {
        return {
            type: "cluster",
            clusterRadius: "100px",
            popupEnabled: false,
            clusterMinSize: "28px",
            clusterMaxSize: "28px",
            labelingInfo: [
                {
                    deconflictionStrategy: "none",
                    labelExpressionInfo: {
                        expression: "Text($feature.cluster_count, '#,###')",
                    },
                    symbol: {
                        type: "text",
                        color: invertColor(color),
                        haloSize: 1,
                        haloColor: invertColor2(color),
                        font: {
                            weight: "bold",
                            family: "Noto Sans",
                            size: "12px",
                        },
                    },
                    labelPlacement: "center-center",
                },
            ],
        };
    };

    getRenderer = (color, type, point_symbol, point_size) => {
        switch (type) {
            case "Point":
                return {
                    type: "simple",
                    symbol: {
                        type: "simple-marker",
                        size: point_size ? point_size : (point_symbol === "X" || point_symbol === "Cross" || point_symbol === "Square") ? 8 : 12,
                        style: point_symbol.toLowerCase(),
                        color: color,
                        outline: {
                            color: point_symbol === "X" || point_symbol === "Cross" ? color : "rgba(255, 255, 255, 0.5)",
                            width: 3,
                        },
                    },
                };
            case "Line":
            case "LineString":
            case "MultiLineString":
                return {
                    type: "simple",
                    symbol: {
                        type: "simple-line",
                        width: "3px",
                        color: color,
                    },
                };
            case "Polygon":
            case "MultiPolygon":
                var rgb = hexToRgb(color);
                return {
                    type: "simple",
                    symbol: {
                        type: "simple-fill",
                        style: "solid",
                        color: "rgba(" + rgb.r + ", " + rgb.g + ", " + rgb.b + ", 0.2)",
                        opacity: 0.75,
                        outline: {
                            color: "rgba(" + rgb.r + ", " + rgb.g + ", " + rgb.b + ", 1)",
                            width: 1,
                        },
                    },
                };
            default:
                return {
                    type: "simple",
                    symbol: {
                        type: "simple-marker",
                        size: 20,
                        color: color,
                        outline: {
                            color: "rgba(255, 255, 255, 0.5)",
                            width: 5,
                        },
                    },
                };
        }
    };

    loadGeometry = (layer_id, layer_title, color, point_symbol, point_size) => {
        const { authState, map, view, activeRecordsection, mapSettings } = this.props;
        const _this = this;
        var layer_info = layer_id.split("|");
        loadModules(["esri/layers/GeoJSONLayer", "esri/request", "esri/layers/support/LabelClass"], { css: true }).then(([GeoJSONLayer, esriRequest, LabelClass]) => {
            const controller = new AbortController();
            const signal = controller.signal;
            let options = {};
            options.timeout = 600000;
            options.headers = {
                Authorization: "Token " + authState.user.auth_token,
            };
            options.signal = signal;
            _this.setState({ ["controller_" + layer_id]: controller });
            var excl = "";
            if (!isNaN(activeRecordsection))
                excl = `&exclude=${activeRecordsection}`;
            esriRequest(`/api/db/recordsections?format=geojson${excl}&section=${layer_info[1]}&t=${Date.now()}`, options).then(function (response) {
                var geojson = response.data;
                //var locstor = {geojson: geojson, time: new Date().getTime()};
                //localStorage.setItem('A' + layer_id, JSON.stringify(locstor));
                var geometry_type = response.data.features[0] && response.data.features[0].geometry.type;

                const blob = new Blob([JSON.stringify(geojson)], { type: "application/json" }); //lmao screw esri
                const url = URL.createObjectURL(blob);
                const statesLabelClass = new LabelClass({
                    labelExpressionInfo: { expression: "$feature.identifier" },
                    labelPlacement: "above-center",
                    minScale: mapSettings && mapSettings.label_scale ? mapSettings.label_scale : 50000,
                    symbol: {
                        type: "text",  // autocasts as new TextSymbol()
                        color: "black",
                        haloSize: 1,
                        haloColor: "white"
                    }
                });
                var layer = new GeoJSONLayer({
                    url: url,
                    labelingInfo: [statesLabelClass],
                    listMode: "hide",
                    //minScale: 500000,
                    featureReduction: view.scale > (mapSettings && mapSettings.cluster_scale ? mapSettings.cluster_scale : 50000) && geometry_type === "Point" ? _this.getClusterConfig(layer_title, color, point_symbol, point_size) : null,
                    popupTemplate: {
                        title: layer_title,
                        //content: "{columns}",
                        content: function(_from_data) {
                            let options2 = {};
                            options2.timeout = 600000;
                            options2.headers = {
                                Authorization: "Token " + authState.user.auth_token,
                            };
                            var object_id = _from_data.graphic.attributes["__OBJECTID"];
                            if (typeof object_id === 'string')
                                object_id = object_id.substring(0, object_id.indexOf('_'));
                            return esriRequest("/loadattributes/" + object_id, options2).then(function (response) {
                                return response.data.attributes;
                            });
                        },
                        outFields: ["*"],
                        actions: [
                            {
                                title: "View Form",
                                id: "go-form",
                                section_id: layer_info[1],
                                page_id: layer_info[0],
                                className: "esri-icon-documentation"
                            },
                            {
                                title: "View Map",
                                id: "go-map",
                                section_id: layer_info[1],
                                page_id: layer_info[0],
                                className: "esri-icon-maps"
                            },
                        ],
                    },
                    renderer: _this.getRenderer(color, geometry_type, point_symbol, point_size),
                    title: layer_title,
                    visible: true,
                });
                map.add(layer);
                view.watch("scale", (scale) => {
                    layer.featureReduction = view.scale > (mapSettings && mapSettings.cluster_scale ? mapSettings.cluster_scale : 50000) && geometry_type === "Point" ? _this.getClusterConfig(layer_title, color, point_symbol, point_size) : null;
                });

                _this.ms4Layers.push(layer);
                _this.setState({ ["loading_" + layer_id]: false });
            });
        });
    };

    loadGeometryGroup = (layer_id, layer_title, color, point_symbol, point_size) => {
        const { authState, map, view, activeRecordsection, mapSettings } = this.props;
        const _this = this;
        var layer_info = layer_id.split("|");
        loadModules(["esri/layers/GeoJSONLayer", "esri/request", "esri/layers/support/LabelClass"], { css: true }).then(([GeoJSONLayer, esriRequest, LabelClass]) => {
            const controller = new AbortController();
            const signal = controller.signal;
            let options = {};
            options.timeout = 600000;
            options.headers = {
                Authorization: "Token " + authState.user.auth_token,
            };
            options.signal = signal;
            _this.setState({ ["groupcontroller_" + layer_id]: controller });
            var excl = "";
            if (!isNaN(activeRecordsection))
                excl = `&exclude=${activeRecordsection}`;
            esriRequest(`/api/db/recordsections?format=geojson${excl}&section=${layer_info[1]}&groupvalue=${layer_info[3]}&t=${Date.now()}`, options).then(function (response) {
                var geojson = response.data;
                //var locstor = {geojson: geojson, time: new Date().getTime()};
                //localStorage.setItem('B' + layer_id, JSON.stringify(locstor));
                var geometry_type = response.data.features[0] && response.data.features[0].geometry.type;

                const blob = new Blob([JSON.stringify(geojson)], { type: "application/json" }); //lmao screw esri
                const url = URL.createObjectURL(blob);
                const statesLabelClass = new LabelClass({
                    labelExpressionInfo: { expression: "$feature.identifier" },
                    labelPlacement: "above-center",
                    minScale: mapSettings && mapSettings.label_scale ? mapSettings.label_scale : 50000,
                    symbol: {
                        type: "text",  // autocasts as new TextSymbol()
                        color: "black",
                        haloSize: 1,
                        haloColor: "white"
                    }
                });
                var layer = new GeoJSONLayer({
                    url: url,
                    labelingInfo: [statesLabelClass],
                    listMode: "hide",
                    featureReduction: view.scale > (mapSettings && mapSettings.cluster_scale ? mapSettings.cluster_scale : 50000) && geometry_type === "Point" ? _this.getClusterConfig(layer_title, color, point_symbol, point_size) : null,
                    popupTemplate: {
                        title: layer_title,
                        //content: "{columns}",
                        content: function(_from_data) {
                            let options2 = {};
                            options2.timeout = 600000;
                            options2.headers = {
                                Authorization: "Token " + authState.user.auth_token,
                            };
                            var object_id = _from_data.graphic.attributes["__OBJECTID"];
                            if (typeof object_id === 'string')
                                object_id = object_id.substring(0, object_id.indexOf('_'));
                            return esriRequest("/loadattributes/" + object_id, options2).then(function (response) {
                                return response.data.attributes;
                            });
                        },
                        outFields: ["*"],
                        actions: [
                            {
                                title: "View Form",
                                id: "go-form",
                                section_id: layer_info[1],
                                page_id: layer_info[0],
                                className: "esri-icon-documentation"
                            },
                            {
                                title: "View Map",
                                id: "go-map",
                                section_id: layer_info[1],
                                page_id: layer_info[0],
                                className: "esri-icon-maps"
                            },
                        ],
                    },
                    renderer: _this.getRenderer(color, geometry_type, point_symbol, point_size),
                    title: layer_title + layer_id,
                    visible: true,
                });
                map.add(layer);
                view.watch("scale", (scale) => {
                    layer.featureReduction = view.scale > (mapSettings && mapSettings.cluster_scale ? mapSettings.cluster_scale : 50000) && geometry_type === "Point" ? _this.getClusterConfig(layer_title, color, point_symbol, point_size) : null;
                });

                _this.ms4Layers.push(layer);
                _this.setState({ ["grouploading_" + layer_id]: false });
            });
        });
    };

    loadGeometryAdv = (layer_id, layer_title, color) => {
        const { authState, map, view, mapSettings } = this.props;
        var layer_info = layer_id.split("||");
        loadModules(["esri/layers/GeoJSONLayer", "esri/request", "esri/layers/support/LabelClass"], { css: true }).then(([GeoJSONLayer, esriRequest, LabelClass]) => {
            const controller = new AbortController();
            const signal = controller.signal;
            let options = {};
            options.method = "post";
            options.timeout = 600000;
            options.headers = {
                Authorization: "Token " + authState.user.auth_token,
            };
            options.signal = signal;
            const _this = this;
            _this.setState({ ["controller_" + layer_id]: controller });
            esriRequest(`page/${layer_info[0]}/advancedsearch/${layer_info[1]}?format=geojson&t=${Date.now()}`, options).then(function (response) {
                var geojson = response.data;
                //var locstor = {geojson: geojson, time: new Date().getTime()};
                //localStorage.setItem('C' + layer_id, JSON.stringify(locstor));
                var geometry_type = response.data.features[0] && response.data.features[0].geometry.type;
                if (response.data.features.length > 0) {
                    // Hack to separate parentrecord values
                    response.data.features.forEach(function (f) {
                        f.properties.parent_label = f.properties.parentrecord.label;
                        f.properties.parent_id = f.properties.parentrecord.id;
                        // When calling api/db/recordsection multipoints are automatically converted to a single point
                        // Not sure where that it is happening on so just convert multipoints to point here
                        if (f.geometry.type === "MultiPoint") {
                            f.geometry.coordinates = f.geometry.coordinates[0];
                            f.geometry.type = "Point";
                        }
                    });
                }
                if (geometry_type === "MultiPoint") {
                    geometry_type = "Point";
                }

                const blob = new Blob([JSON.stringify(geojson)], { type: "application/json" }); //lmao screw esri
                const url = URL.createObjectURL(blob);
                const statesLabelClass = new LabelClass({
                    labelExpressionInfo: { expression: "$feature.identifier" },
                    labelPlacement: "above-center",
                    minScale: mapSettings && mapSettings.label_scale ? mapSettings.label_scale : 50000,
                    symbol: {
                        type: "text",  // autocasts as new TextSymbol()
                        color: "black",
                        haloSize: 1,
                        haloColor: "white"
                    }
                });
                var layer = new GeoJSONLayer({
                    url: url,
                    labelingInfo: [statesLabelClass],
                    listMode: "hide",
                    featureReduction: view.scale > (mapSettings && mapSettings.cluster_scale ? mapSettings.cluster_scale : 50000) && geometry_type === "Point" ? _this.getClusterConfig(layer_title, color, "Circle") : null,
                    popupTemplate: {
                        title: layer_title,
                        content: "{parent_label}",
                        outFields: ["*"],
                        actions: [
                            {
                                title: "View Form",
                                id: "go-form2",
                                page_id: layer_info[0],
                                className: "esri-icon-documentation"
                            },
                            {
                                title: "View Map",
                                id: "go-map2",
                                page_id: layer_info[0],
                                className: "esri-icon-maps"
                            },
                        ],
                    },
                    renderer: _this.getRenderer(color, geometry_type, "Circle"),
                    title: layer_title,
                    visible: true,
                });
                layer.on("layerview-create", function(event) {
                    layer.queryExtent().then(function(response) {
                        layer.queryFeatureCount().then(function(numCount) { 
                            if (numCount === 1) {  
                                view.when(() => {
                                    view.goTo({
                                        target: response.extent.center,
                                        zoom: 15,
                                    }, { duration: 500 })
                                })
                            } else {
                                view.when(() => {
                                    view.goTo(response.extent, { duration: 500 })
                                })
                            }
                        })
                    });
                });
                map.add(layer);


                view.watch("scale", (scale) => {
                    layer.featureReduction = view.scale > (mapSettings && mapSettings.cluster_scale ? mapSettings.cluster_scale : 50000) && geometry_type === "Point" ? _this.getClusterConfig(layer_title, color, "Circle") : null;
                });

                _this.ms4SearchLayers.push(layer);
                _this.setState({ ["loading_" + layer_id]: false });
            });
        });
    };

    getLegendIcon = (type, color, classes, point_symbol) => {
        if (type === "Point" || type === "MultiPoint") {
            if (point_symbol === "Circle")
                return <span className={classes.legendcircle} style={{ backgroundColor: color }}></span>;
            if (point_symbol === "Triangle")
                return <span className={classes.legendtriangle} style={{ borderBottomColor: color }}></span>;
            if (point_symbol === "Square")
                return <span className={classes.legendsquare} style={{ backgroundColor: color }}></span>;
            if (point_symbol === "Cross")
                return (
                    <>
                    <span className={classes.legendcross3} style={{ backgroundColor: color }}></span>
                    <span className={classes.legendcross4} style={{ backgroundColor: color }}></span>
                    </>
                );
            if (point_symbol === "Diamond")
                return <span className={classes.legenddiamond} style={{ backgroundColor: color }}></span>;
            if (point_symbol === "X")
                return <span style={{ color: color }}>X</span>;
        }
        else if (type === "Line")
            return (
                <span className={classes.legendcross} style={{ backgroundColor: color }}>
                    <div className={classes.legendcross2} style={{ backgroundColor: color }}></div>
                </span>
            );
        else if (type === "Polygon")
            return (
                <span className={classes.legendpolygon} style={{ borderColor: color + " transparent" }}>
                    <div className={classes.legendpolygon2} style={{ borderColor: "transparent transparent " + color }}></div>
                </span>
            );
        else if (type === "MultiPoint")
            return (
                <>
                    <span className={classes.legendmulti} style={{ backgroundColor: color }}></span>
                    <span className={classes.legendmulti2} style={{ backgroundColor: color }}></span>
                    <span className={classes.legendmulti3} style={{ backgroundColor: color }}></span>
                </>
            );
        else return <span className={classes.legendsquare} style={{ backgroundColor: color }}></span>;
    };

    ReferenceLayer = ({ x }) => {
        const { classes, view } = this.props;

        var inVisibleRange = false;
        var c = classes.text1;
        if (x.layer.minScale === 0 && x.layer.maxScale === 0) {
            inVisibleRange = true;
        } else if (view.scale > x.layer.maxScale && view.scale < x.layer.minScale) {
            inVisibleRange = true;
        }

        const hidden = this.state[x.layer.id] || !x.layer.visible || !inVisibleRange;

        if (!(!x.layer.visible || !inVisibleRange)) {
            c = classes.text;
        }

        const range = `${getMin(x.layer.minScale || 100000000)} - ${getMax(x.layer.maxScale)}`;

        return (
            <li>
                <div className={classes.item_li}>
                    <input type="checkbox" checked={!!x.layer.visible} onChange={(e) => this.onToggleReference(e, x.layer)}></input>
                    <span>
                        <span
                            onClick={() => {
                                this.setState({ [[x.layer.id]]: !this.state[x.layer.id] });
                            }}
                            style={{ cursor: "pointer", display: !this.state[x.layer.id] ? "none" : "" }}
                            className="esri-icon-right-triangle-arrow"></span>
                        <span
                            onClick={() => {
                                this.setState({ [[x.layer.id]]: !this.state[x.layer.id] });
                            }}
                            style={{ cursor: "pointer", display: this.state[x.layer.id] ? "none" : "" }}
                            className="esri-icon-down-arrow"></span>
                    </span>
                    <Tooltip title={range}>
                        <div className={classNames(c, classes.section)}>{x.layer.title}</div>
                    </Tooltip>
                </div>
                {x.legend.domNode && (
                    <div
                        hidden={hidden}
                        ref={(nodeElement) => {
                            nodeElement && nodeElement.appendChild(x.legend.domNode);
                        }}
                    />
                )}
            </li>
        );
    };

    tabClick = (tab) => {
        if (tab === "base") {
            this.setState({baseClosed: false, drainageClosed: true, searchClosed: true, referenceClosed: true });
        }
        if (tab === "ms4") {
            this.setState({baseClosed: true, drainageClosed: false, searchClosed: true, referenceClosed: true });
        }
        if (tab === "search") {
            this.setState({baseClosed: true, drainageClosed: true, searchClosed: false, referenceClosed: true });
        }
        if (tab === "reference") {
            this.setState({baseClosed: true, drainageClosed: true, searchClosed: true, referenceClosed: false });
        }
     }

    render() {
        const { classes, baseLayerObj, referenceLayerObj, pages, showChecked, searches, allSearches, mapGroups, groupValues, has_webmap, viewOnly, page_id } = this.props;
        const { baseMapIndex, baseClosed, referenceClosed, drainageClosed, searchClosed, layerClosed } = this.state;

        const sortedReferenceLayers = referenceLayerObj.sort((a, b) => a.order - b.order);

        return (
            <>
                <div title="Layers" className="esri-icon-layers esri-widget--button esri-widget esri-interactive" onClick={this.layerToggle}></div>
                <div className={classNames(viewOnly ? classes.tabSmall : classes.tab, layerClosed && classes.hidden)}>
                    <button className={classNames(viewOnly ? classes.tablinksSmall : classes.tablinks, !baseClosed && classes.active)} onClick={() => this.tabClick("base")}>Base Map</button>
                    <button className={classNames(viewOnly ? classes.tablinksSmall : classes.tablinks, !drainageClosed && classes.active)} onClick={() => this.tabClick("ms4")}>MS4Front Layers</button>
                    <button className={classNames(viewOnly ? classes.tablinksSmall : classes.tablinks, !searchClosed && classes.active)} onClick={() => this.tabClick("search")}>Saved Searches</button>
                    <button className={classNames(viewOnly ? classes.tablinksSmall : classes.tablinks, !referenceClosed && classes.active)} onClick={() => this.tabClick("reference")}>Reference Layers</button>
                </div>
                <div id={viewOnly ? "smallref" : "largeref"} hidden={layerClosed} className={viewOnly ? classes.panel1 : classes.panel1Large}>
                    <ul className={viewOnly ? classes.listSmall : classes.list}>
                        <li>
                            <div className={baseClosed ? classes.hidden: ""}>
                                <ul hidden={baseClosed} className={classes.list1}>
                                    {baseLayerObj
                                        .filter((x) => x)
                                        .map((x) => (
                                            <li key={x.id} className={classes.item_li}>
                                                <input
                                                    type="radio"
                                                    name="basemap"
                                                    checked={x.id === baseMapIndex ? "checked" : ""}
                                                    onChange={(e) => this.radioSwitch(e, x.name, x.id, x.map_l)}></input>
                                                <div className={classNames(classes.text, classes.section)}>{x.name}</div>
                                            </li>
                                        ))}
                                </ul>
                            </div>
                            <div className={drainageClosed ? classes.hidden: ""}>
                                <ul hidden={drainageClosed} className={classes.list1}>
                                    {pages &&
                                        pages.map((p) => {
                                            var sections = p.sections ? p.sections.toRefArray() : null;
                                            // eslint-disable-next-line
                                            return sections.sort((a,b) => (b.order > a.order ? - 1 : 0)).map((s) => {
                                                if (
                                                    s.has_geometry && s.display_list !== false && (
                                                        p.id === page_id || !page_id || (
                                                            s.display_module && (
                                                                s.display_module_page.includes("All Modules") || s.display_module_page.includes(page_id.toString()) 
                                                            )
                                                        )
                                                    )
                                                ) {
                                                    var collapseNameSection = s.id + "section"; 
                                                    var parent_section = sections.find(_s => _s.parent === true && _s.page_id === s.page_id);
                                                    if (parent_section)
                                                        var collapseNameSectionParent = parent_section.id + "section"; 
                                                    var color = s.geo_color ? s.geo_color : "#EF8D2F";
                                                    var point_symbol = s.point_symbology ? s.point_symbology : "Circle";

                                                    var toggle_layer = this.ms4Layers.find((a) => a.title === p.name + ": " + s.name);
                                                    var _mgs = mapGroups.filter(mG => mG.section === s.id)
                                                    if (
                                                        s.on_by_default !== false &&
                                                        showChecked && (showChecked === s.id || showChecked === "All") && 
                                                        this.state["preloaded_" + p.id + "|" + s.id] !== true && _mgs.length === 0
                                                    ) {
                                                        this.setState({ ["preloaded_" + p.id + "|" + s.id]: true });
                                                        this.onToggle(null, p.id + "|" + s.id, p.name + ": " + s.name, color, this, point_symbol, s.point_size);
                                                    }

                                                    var code = _mgs.map((mG) => {
                                                        var collapseName = mG.id + "group"; 

                                                        if (mG.collapsed_by_default && this.state["grouppreloaded2_" + p.id + "|" + s.id + "|" + mG.id] !== true) {
                                                            this.setState({ ["grouppreloaded2_" + p.id + "|" + s.id + "|" + mG.id]: true,  [collapseName]: true });
                                                        }

                                                        var code2 = groupValues.filter(gV => gV.sectionmapgrouping === mG.id).map((gV) => {
                                                            var color2 = gV.geo_color ? gV.geo_color : "#EF8D2F";
                                                            var point_symbol2 = gV.point_symbology ? gV.point_symbology : "Circle";
                                                            var toggle_layer = this.ms4Layers.find((a) => a.title === gV.label + p.id + "|" + s.id + "|" + mG.id + "|" + gV.id);
                                                            if (
                                                                gV.on_by_default !== false &&
                                                                showChecked && (showChecked === s.id || showChecked === "All") && 
                                                                this.state["grouppreloaded_" + p.id + "|" + s.id + "|" + mG.id + "|" + gV.id] !== true
                                                            ) {
                                                                this.setState({ ["grouppreloaded_" + p.id + "|" + s.id + "|" + mG.id + "|" + gV.id]: true });
                                                                this.onToggleGroup(null, p.id + "|" + s.id + "|" + mG.id + "|" + gV.id, gV.label, color2, this, point_symbol2, gV.point_size);
                                                            }
                                                            return (
                                                                <li className={classNames(s.parent ? classes.pushLeft2 : classes.pushLeft3, (this.state[collapseName] || this.state[collapseNameSection] || this.state[collapseNameSectionParent]) && classes.hidden)} style={{ display: "flex" }}>
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={toggle_layer ? toggle_layer.visible : this.state["grouploading_" + p.id + "|" + s.id + "|" + mG.id + "|" + gV.id]}
                                                                        onChange={(e) =>
                                                                            this.onToggleGroup(e, p.id + "|" + s.id + "|" + mG.id + "|" + gV.id, gV.label, color2, this, point_symbol2, gV.point_size)
                                                                        }></input>
                                                                    {this.state["grouploading_" + p.id + "|" + s.id + "|" + mG.id + "|" + gV.id] ? (
                                                                        <span className="loader"></span>
                                                                    ) : (
                                                                        this.getLegendIcon(s.geometry_type, color2, classes, point_symbol2)
                                                                    )}
                                                                    <div title={gV.label} className={classNames(classes.text, classes.section)}>
                                                                        {gV.label}
                                                                    </div>
                                                                </li>
                                                            );
                                                        })
                                                        code2.unshift(
                                                            <div title={mG.group_name} className={classNames(classes.text, s.parent ? classes.pushLeft : classes.pushLeft2, (this.state[collapseNameSection] || this.state[collapseNameSectionParent]) && classes.hidden)}>
                                                                <span>
                                                                    <span
                                                                        onClick={() => {
                                                                            this.setState({ [collapseName]: !this.state[collapseName] });
                                                                        }}
                                                                        style={{ cursor: "pointer", display: !this.state[collapseName] ? "none" : "" }}
                                                                        className="esri-icon-right-triangle-arrow"></span>
                                                                    <span
                                                                        onClick={() => {
                                                                            this.setState({ [collapseName]: !this.state[collapseName] });
                                                                        }}
                                                                        style={{ cursor: "pointer", display: this.state[collapseName] ? "none" : "" }}
                                                                        className="esri-icon-down-arrow"></span>
                                                                </span>
                                                                <span 
                                                                    style={{cursor: "pointer"}}
                                                                    onClick={() => {
                                                                        this.setState({ [collapseName]: !this.state[collapseName] });
                                                                    }}>
                                                                    <b>{mG.group_name}</b>
                                                                </span>
                                                            </div>
                                                        );
                                                        return code2;
                                                    })

                                                    code.unshift(
                                                        <li className={!s.parent ? classes.pushLeft: ""} style={{ display: "flex" }}>
                                                            
                                                            {_mgs.length === 0 && (
                                                                <input
                                                                    type="checkbox"
                                                                    checked={toggle_layer ? toggle_layer.visible : this.state["loading_" + p.id + "|" + s.id]}
                                                                    onChange={(e) =>
                                                                        this.onToggle(e, p.id + "|" + s.id, p.name + ": " + s.name, color, this, point_symbol, s.point_size)
                                                                    }></input>
                                                            )}
                                                            {this.state["loading_" + p.id + "|" + s.id] ? (
                                                                <span className="loader"></span>
                                                            ) : _mgs.length === 0 && (
                                                                this.getLegendIcon(s.geometry_type, color, classes, point_symbol)
                                                            )}
                                                            <div title={p.name + ": " + s.name} className={classNames(classes.text, _mgs.length === 0 && classes.section, (this.state[collapseNameSectionParent] && !s.parent) && classes.hidden)}>
                                                                {_mgs.length > 0 && (
                                                                    <span>
                                                                        <span
                                                                            onClick={() => {
                                                                                this.setState({ [collapseNameSection]: !this.state[collapseNameSection] });
                                                                            }}
                                                                            style={{ cursor: "pointer", display: !this.state[collapseNameSection] ? "none" : "" }}
                                                                            className="esri-icon-right-triangle-arrow"></span>
                                                                        <span
                                                                            onClick={() => {
                                                                                this.setState({ [collapseNameSection]: !this.state[collapseNameSection] });
                                                                            }}
                                                                            style={{ cursor: "pointer", display: this.state[collapseNameSection] ? "none" : "" }}
                                                                            className="esri-icon-down-arrow"></span>
                                                                    </span>
                                                                )}
                                                                {(s.parent && _mgs.length > 0) ? (
                                                                    <span
                                                                        style={{cursor: "pointer"}}
                                                                        onClick={() => {
                                                                            this.setState({ [collapseNameSection]: !this.state[collapseNameSection] });
                                                                        }}>
                                                                        <b>{p.name}</b>
                                                                    </span>
                                                                ) : s.parent ? (
                                                                    <span>
                                                                        <b>{p.name}</b>
                                                                    </span>
                                                                ) : _mgs.length > 0 ? (
                                                                    <span
                                                                        style={{cursor: "pointer"}}
                                                                        onClick={() => {
                                                                            this.setState({ [collapseNameSection]: !this.state[collapseNameSection] });
                                                                        }}>
                                                                        <b>{s.name}</b>
                                                                    </span>
                                                                ) : (
                                                                    s.name
                                                                )}
                                                            </div>
                                                        </li>
                                                    );

                                                    return code;
                                                }
                                            });
                                        })}
                                </ul>
                            </div>
                            <div className={searchClosed ? classes.hidden: ""}>
                                <ul hidden={searchClosed} className={classes.list1}>
                                    {searches &&
                                        searches.map((advG) => {
                                            if (searches.length > 0) {
                                                // eslint-disable-next-line
                                                return advG.advancedsearch.map((advS) => {
                                                    var pageSections = this.props.sections.filter((s) => s.page === advG.page);
                                                    var hasGeometry = pageSections.find(
                                                        //check to see if geometry is apart of parent or child if we are on a child section
                                                        (s) =>
                                                            (s.parent === true && s.has_geometry === true) ||
                                                            (advS.section === s.id && s.has_geometry === true)
                                                    );
                                                    const { id, is_city_admin } = this.props.authState.user;

                                                    if (
                                                        // only allow geometries that have access
                                                        hasGeometry &&
                                                        ((advS.accessby === "Creator" && parseInt(advS.accesscreatorid) === id) ||
                                                            (advS.accessby === "Admin" && is_city_admin === true) ||
                                                            advS.accessby === "All")
                                                    ) {
                                                        var toggle_layer = this.ms4SearchLayers.find((a) => a.title === advS.name);
                                                        var color = advS.geo_color
                                                            ? advS.geo_color.toLowerCase().replace(" ", "")
                                                            : "#EF8D2F";

                                                        if (this.state["preloaded_" + advG.page + "||" + advS.id] !== true) {
                                                            this.setState({ ["preloaded_" + advG.page + "||" + advS.id]: true });
                                                            this.onToggleAdv(null, advG.page + "||" + advS.id, advS.name, color, this);
                                                        }

                                                        return (
                                                            <li style={{ display: "flex" }}>
                                                                <input
                                                                    type="checkbox"
                                                                    checked={
                                                                        !!(toggle_layer && toggle_layer.visible) ||
                                                                        this.state["loading_" + advG.page + "||" + advS.id]
                                                                    }
                                                                    onChange={(e) =>
                                                                        this.onToggleAdv(e, advG.page + "||" + advS.id, advS.name, color, this)
                                                                    }></input>
                                                                {this.state["loading_" + advG.page + "||" + advS.id] ? (
                                                                    <span className="loader"></span>
                                                                ) : (
                                                                    <span className={classes.legendsquare} style={{ backgroundColor: color }}></span>
                                                                )}
                                                                <div title={advS.name} className={classNames(classes.text, classes.section)}>
                                                                    {advS.name}
                                                                </div>
                                                            </li>
                                                        );
                                                    }
                                                });
                                            } else return null;
                                        })}
                                    {allSearches &&
                                        allSearches.map((advG) => {
                                            if (allSearches.length > 0) {
                                                // eslint-disable-next-line
                                                return advG.advancedsearch.map((advS) => {
                                                    var pageSections = this.props.sections.filter((s) => s.page === advG.page);
                                                    var hasGeometry = pageSections.find(
                                                        //check to see if geometry is apart of parent or child if we are on a child section
                                                        (s) =>
                                                            (s.parent === true && s.has_geometry === true) ||
                                                            (advS.section === s.id && s.has_geometry === true)
                                                    );
                                                    const { id, is_city_admin } = this.props.authState.user;

                                                    if (
                                                        // only allow geometries that have access
                                                        hasGeometry &&
                                                        ((advS.accessby === "Creator" && parseInt(advS.accesscreatorid) === id) ||
                                                            (advS.accessby === "Admin" && is_city_admin === true) ||
                                                            advS.accessby === "All")
                                                    ) {
                                                        var toggle_layer = this.ms4SearchLayers.find((a) => a.title === advS.name);
                                                        var color = advS.geo_color
                                                            ? advS.geo_color.toLowerCase().replace(" ", "")
                                                            : "#EF8D2F";
                                                        return (
                                                            <li style={{ display: "flex" }}>
                                                                <input
                                                                    type="checkbox"
                                                                    checked={
                                                                        !!(toggle_layer && toggle_layer.visible) ||
                                                                        this.state["loading_" + advG.page + "||" + advS.id]
                                                                    }
                                                                    onChange={(e) =>
                                                                        this.onToggleAdv(e, advG.page + "||" + advS.id, advS.name, color, this)
                                                                    }></input>
                                                                {this.state["loading_" + advG.page + "||" + advS.id] ? (
                                                                    <span className="loader"></span>
                                                                ) : (
                                                                    <span className={classes.legendsquare} style={{ backgroundColor: color }}></span>
                                                                )}
                                                                <div title={advS.name} className={classNames(classes.text, classes.section)}>
                                                                    {advS.name}
                                                                </div>
                                                            </li>
                                                        );
                                                    }
                                                });
                                            } else return null;
                                        })}
                                </ul>
                            </div>
                            <div className={referenceClosed && classes.hidden}>
                                <ul hidden={referenceClosed} className={classes.list1}>
                                    <div id="referencecontainer"></div>
                                    {!has_webmap && sortedReferenceLayers.map((x) => (
                                        <this.ReferenceLayer x={x} />
                                    ))}
                                    {!has_webmap && sortedReferenceLayers.length === 0 && (
                                        <>No reference layers defined. Visit the map settings to configure.</>
                                    )}
                                </ul>
                            </div>
                        </li>
                    </ul>
                </div>
            </>
        );
    }
}

export default withStyles(styles)(Control);
