import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Form } from "react-form";
import { connect } from "react-redux";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
// import Stepper from "@material-ui/core/Stepper";
// import Step from "@material-ui/core/Step";
// import StepButton from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button"; 
import Divider from "@material-ui/core/Divider"; 
import Accordion from "@material-ui/core/Accordion"; 
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary"; 
import { withStyles } from "@material-ui/core";

 

import AppContainerApplication from "../../common/AppContainerApplication";
 
import Select from "../../common/Select"; 
import HelpLabel from "../../common/HelpLabel";
// import { createSelector } from "../../common/orm";
 
// import {
//     ApplicationRecord,
//     ApplicationRecordStatus,
//     ColumbusApplication,
//     ColumbusApplicationContact,
//     ColumbusApplicationAttachment
// } from "../../page/models";

const styles = theme => ({
    deleteWidth: {
        minWidth: 34,
        width: 34,
        marginRight: 8,
        marginTop: 2,
        marginBottom: 2
    },
    centerAlign: {
        textAlign: "center"
    },
    centerAlign2: {
        textAlign: "center",
        display: "inline-block",
        width: "100%"
    },
    nowrap: {
        whiteSpace: "nowrap"
    },
    rightAlign: {
        textAlign: "right"
    },
    flex: {
        flex: 1
    },
    printDivider: {
        height: 8,
        backgroundColor: "rgba(0, 0, 0, 0.42)",
        marginTop: 16,
        marginBottom: 8
    },
    dialogMin: {
        minWidth: 400
    },
    minWidth: {
        minWidth: 600
    },
    root: {
        flexBasis: "100%",
        maxWidth: "100%",
    },
    height: {
        height: "600px",
        width: "100%"
    }
});


// const getApplication = createSelector(
//     (state, ownProps) => ownProps.match.params["hash"],
//     (session, hash) => {
//         return session.ColumbusApplication.filter(p => p.hash === hash).toModelArray()[0];
//     }
// );

class Columbus extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeStep: 0,
            contactDialogOpen: false,
            contactEditing: null,
            formKey: null,
            order: "desc",
            orderBy: null,
            temp_contacts: [],
            attachmentDialogOpen: false,
            attachmentEditing: null,
            attachmentFormKey: null,
            attachmentOrder: "desc",
            attachmentOrderBy: null,
            temp_attachments: [],
            loading: false,
            new_save: false,
            new_save2: false,
            mainFormKey: null,
            applicant_same_NO: true,
            applicant_same_secondary_NO: true,
            permittee_same_NO: true,
            loaded_record: null
        };
    }

   

    
 

    submitApplication = values => {
        const { history } = this.props
        if (values && values.land_disturbance === 'Blue'){
            history.push("/applications/columbus/permit/blue");  
        }else if (values && values.land_disturbance === 'Green'){
            history.push("/applications/columbus/permit/green");  
        }else if (values && values.land_disturbance === 'Individual Residential Lot'){
            history.push("/applications/columbus/permit/single");  
        }
  
    }
  

    render() {
        const { classes } = this.props;
        const {
            activeStep, 
            loading, 
            mainFormKey, 
        } = this.state;

 

        let options = [  {
            "label": ">= 0.5 acre",
            "value": "Blue"
            },
            {
                "label": "≥ 0.25 acre and < 0.5 acre",
                "value": "Green"
            },
            {
                "label": "Individual Residential Lot",
                "value": "Individual Residential Lot"
            },
        ]

        return (
            <AppContainerApplication
                city_id="11"
                page_loading={loading}
                printing={activeStep === "PRINT"}>
                <Form
                    getApi={el => (this.formApi = el)}
                    defaultValues={{'land_disturbance':""}}
                    key={mainFormKey}
                    dontValidateOnMount={true}  
                    onSubmit={values => this.submitApplication(values)}>
                    {formApi => (
                        <form onSubmit={formApi.submitForm}>
                            <Grid container spacing={1} style={{ padding: "0 12px" }}>
                                <Grid item xs={12} lg={10} xl={8} classes={{ root: classes.root }}>
                                    <Typography className={classes.centerAlign} variant="h6">
                                        Permit Application Form
                                    </Typography>
                                    <Typography className={classes.centerAlign}>
                                        <b>IMPORTANT:</b> The entire permit application must be filled out at one time and progress will not be saved
                                        so please plan accordingly.
                                    </Typography>
                                    <Typography variant="caption" className={classes.centerAlign2}>
                                        Required Fields are marked with an asterisk (*)
                                    </Typography>
                                </Grid>
                                
                                <Grid item xs={12} lg={10} xl={8} classes={{ root: classes.root }}>
                                    <hr/>
                                    <br></br>
                             
                                </Grid>
                                {(activeStep === 0 || activeStep === "PRINT") && (
                                    <Grid item xs={12} xl={10} style={{ marginLeft: 15 }} classes={{ root: classes.root }}>
                                        <Typography variant="subtitle1" gutterBottom>
                                            <b>Introduction</b>
                                        </Typography>
                                        <Typography gutterBottom>
                                            Welcome to the Columbus Stormwater Management Permit Application Portal. This Permit Portal
                                            will guide you through the Stormwater Permit Application process and keep you on track to complete your
                                            project. Throughout this process you will be working with Columbus staff to determine permit eligibility and to provide technical assistance.
                                        </Typography>
                                        <Typography gutterBottom>
                                            Visit our website to determine if{" "}
                                            <a href="https://www.columbus.in.gov/engineering/stormwater/" target="_blank" rel="noopener noreferrer">
                                                your are within the city of Columbus
                                            </a>
                                            , and to learn more about our{" "}
                                            <a href="https://www.columbus.in.gov/engineering/stormwater-permitting/" target="_blank" rel="noopener noreferrer">
                                                permit programs
                                            </a>
                                            .
                                        </Typography>
                                        <Typography gutterBottom>
                                            Questions email: <a href="mailto: lchristie@columbus.in.gov">lchristie@columbus.in.gov</a>  
                                        </Typography>
                                    </Grid>
                                )}
                                {(activeStep === 0 || activeStep === "PRINT") && (
                                    <Grid item xs={12} lg={10} xl={8} classes={{ root: classes.root }}>
                             
                                        <Accordion defaultExpanded>
                                            <AccordionSummary   style={{ backgroundColor: "#ddd" }}>
                                                <Typography variant="subtitle1">
                                                    Select total land that will be disturbed
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails style={{ backgroundColor: "f6f0f0" }}>
                                                <Grid container spacing={2} style={{ marginTop: 0 }}>
                                            
                                                    <Grid item xs={6}>
                                                        <Select
                                                            // disabled={is_read_only}
                                                            field="land_disturbance"
                                                            label={
                                                                <HelpLabel
                                                                    inputLabel="How much is the proposed total land disturbance?"
                                                                    helpText="Land disturbance shall be calculated as a surface area that includes the outermost boundary of the area planned to be disturbed by construction, material storage, grading, grubbing, landscaping or other land disturbing activities. Total land disturbance associated with a project includes off-site work such as utility work, storage areas, stockpiles and borrow sites." 
                                                                />
                                                            }
                                                            options={options}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                 
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        <Typography gutterBottom>
                                                            Note: The total land disturbance selection determines which permit application will load when 'Next' is pressed.
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion>
                                 
                               
                                    </Grid>
                                )}
                                {activeStep === "PRINT" && (
                                    <Grid item xs={12} lg={10} xl={8} classes={{ root: classes.root }}>
                                        <Divider className={classes.printDivider} />
                                    </Grid>
                                )} 
                                {activeStep !== "PRINT" && (
                                    <Grid item xs={12} lg={10} xl={8} className={classes.rightAlign} classes={{ root: classes.root }} > 
                                        <Button type='submit' variant="contained" color="primary" style={{ marginLeft: 16 }}>
                                            {"Next"}
                                        </Button>
                                    </Grid>
                                )}
                            </Grid>
                        </form>
                    )}
                </Form> 
            </AppContainerApplication>
        );
    }
}

Columbus = connect(
    (state, ownProps) => ({
        // application: getApplication(state, ownProps),
        // authState: state.auth // Admins will have an authstate
    }),
    {
        // ...ApplicationRecord.actions,
        // ...ApplicationRecordStatus.actions,
        // ...ColumbusApplication.actions,
        // ...ColumbusApplicationContact.actions,
        // ...ColumbusApplicationAttachment.actions
    }
)(Columbus);

export default withStyles(styles)(withRouter(Columbus));
