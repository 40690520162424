import React, { Component } from "react";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Form as Formm } from "react-form";

import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import Table from "@material-ui/core/Table";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Toolbar from "@material-ui/core/Toolbar";
import Dialog from "@material-ui/core/Dialog";
import CircularProgress from "@material-ui/core/CircularProgress";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Close from "@material-ui/icons/Close";
import NumberFormat from "react-number-format";
import { evaluate } from "mathjs";

import HelpLabel from "../../common/HelpLabel";
import EnhancedTableHead from "../../common/EnhancedTableHead";
import CustomTableCell from "../../common/TableCell";
import WarningDialog from "../../common/WarningDialog";
import TextField from "../../common/TextField";
import ReactSelect from "../../common/ReactSelect";
import ReactSelectAsync from "../../common/ReactSelectAsync";
import DatePicker from "../../common/DatePicker";
import TimePicker from "../../common/TimePicker";
import RadioGroup from "../../common/RadioGroup";

import TablePaginationActionsWrapped from "../../common/Paginator";
import { Section, RepeatableTable, RepeatableField, RecordSection } from "../models";
import { createSelector } from "../../common/orm";

// CSS in JS styles
const styles = (theme) => ({
    table: {
        minWidth: 500,
    },
    dialogMin: {
        minWidth: 420,
        minHeight: 145,
    },
    flex: {
        flex: 1,
    },
    minWidth: {
        maxWidth: 507,
        minWidth: 489,
    },
    minHeight: {
        minHeight: 500,
    },
    gutterBottom: {
        marginBottom: ".4em",
    },
    root: {
        width: "100%",
        overflowX: "auto",
        backgroundColor: "#f0f0f0",
    },
    deleteWidth: {
        minWidth: 34,
        width: 34,
        margin: "0 8px 0 0",
    },
    centerAlign: {
        textAlign: "center",
        marginLeft: "50%",
    },
    nowrap: {
        whiteSpace: "nowrap",
    },
    iconAdd: {
        backgroundColor: "#34aa20",
        color: "white",
        height: "25px",
        width: "25px",
        paddingTop: "0px",
        fontSize: "100%",
        borderRadius: 12,
    },
    progress: {
        display: "table",
        marginLeft: "auto",
        marginRight: "auto",
    },
    floatLeft: {
        float: "left",
        marginLeft: 10,
    },
    floatRight: {
        float: "right",
        marginRight: 10,
    },
    ellipsis: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        WebkitLineClamp: 15,
        WebkitBoxOrient: "vertical",
    },
});

//get permissions
const getPermission = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["id"]), //input selector which matches ownProps page :id in route i.e index.js ("return id" will be injected into "pageID" in the result function args)
    (state, ownProps) => parseInt(ownProps.match.params["pageId"]),
    (session, id, pageID) => {
        if (isNaN(id)) {
            return session.Permission.filter((p) => p.page === pageID).toRefArray();
        } else {
            return session.Permission.filter((p) => p.page === id).toRefArray();
        }
    }
);

class CreateRepeatableFields extends Component {
    state = {
        count: 0,
        loadingFields: false,
        calculationInfo: {},
        actCalcFields: [],
    };

    createNewFields = (form) => {
        const { groupFields, recordSection, ormRepeatableTableCreate, ormRepeatableFieldCreate } = this.props;
        const { count } = this.state;

        this.setState({
            loadingFields: true,
            count: count + 1,
        });

        var createdFields = [];
        if (groupFields.length > 0 && count === 0) {
            ormRepeatableTableCreate({
                recordsection_id: recordSection,
                group_id: groupFields[0].group,
            }).then((table) => {
                let i = 0;
                let j = groupFields.length;
                //loop through each group, pull only form[0] for parent form
                groupFields.map((g) => {
                    //only create for static fields
                    ormRepeatableFieldCreate({
                        repeatabletable_id: table.id,
                        field_id: g.id, //get each field id
                        //userinput: undefined
                    })
                        // eslint-disable-next-line
                        .then((f) => {
                            let t = {};
                            t["id"] = f.id;
                            t["userinput"] = f.userinput;
                            t["field"] = g.id;
                            createdFields.push(t); //get the field
                            i++;
                            if (i >= j) {
                                this.props.handleCreateRepeatRecord(createdFields);
                                this.setState({ count: 0, loadingFields: false });
                            }
                        });
                    return null;
                });
                this.props.handleSetRepeatableTableId(table.id); //set repeatable table id for deleting of record
            });
        } else {
            this.setState({ loadingFields: false });
        }
    };
    componentDidUpdate = () => {
        const { calculationInfo } = this.state;
        const { editRepeatableRecord, groupFields } = this.props;
        if (JSON.stringify(calculationInfo) === "{}" && editRepeatableRecord && groupFields) this.checkForCalcFields();
    };
    calculate = (fc, value) => {
        const { actCalcFields, calculationInfo } = this.state;

        var actvalue = value.toString().split(",").join(""); //remove comma
        actvalue = actvalue.replace(/[^0-9.]/g, ""); //remove any units with field

        if ((actCalcFields.length > 0 && actvalue !== "" && actvalue !== "-" && actvalue.match(/^[0-9.-]+$/)) || fc.type === "Calculated") {
            //check that not blank input field and no characters
            var calculation = [];
            var temp = {};
            var newActCalcFields = actCalcFields,
                newCalcState = [];

            calculationInfo.forEach((c, i) => {
                //loop through user saved calculation string and remove brackets
                return c.calculation.forEach((calcfield, index) => {
                    var foundfield = calcfield.match(/^\[[a-zA-Z0-9._\s]+\]+$/); //has brackets

                    if (foundfield && foundfield.length > 0) {
                        let removedbracketsfield = foundfield[0].replace(/[^-+*/\w\s]/gi, ""); //remove brackets from found fields

                        if (removedbracketsfield === "Internal_ID") {
                            calculation.push(newActCalcFields[0].recordsection.id);
                        } else {
                            // eslint-disable-next-line
                            var dummy = newActCalcFields.find((afield) => {
                                //chk state for field name
                                if (afield.fieldname === removedbracketsfield) {
                                    if (afield.fieldname === fc.fieldname || (fc.field && afield.fieldname === fc.fieldname)) {
                                        //fc.field.name is from repeatable
                                        //match
                                        // eliminate "" in database to zero as we cant calculate a blank string
                                        if (actvalue === "") actvalue = 0;

                                        temp = { ...afield }; //clone
                                        temp["userinput"] = actvalue; //set new value
                                        return calculation.push(actvalue);
                                    } else {
                                        //nonmatch eliminate "" in database to zero as we cant calculate a blank string
                                        if (afield.userinput === "" || !afield.userinput) {
                                            afield.userinput = 0;
                                        }
                                        var removeUnits = afield.userinput.toString().replace(/[^0-9.]/g, ""); //remove any units with userinput that was already saved
                                        return calculation.push(removeUnits);
                                    }
                                } else {
                                    return null;
                                }
                            });
                        }
                    } else {
                        //operator/static values in calculation formula push
                        calculation.push(calcfield);
                    }

                    //route to 'eval'method as this is the end of the calculation formula so evaluate it
                    if (index === c.calculation.length - 1) {
                        var result = this.eval(calculation, c, actvalue, evaluate);
                        calculation = []; //next calc array

                        //recreate updated calc fields for state
                        newActCalcFields = newActCalcFields.map((af) => {
                            if (af.id === temp.id) {
                                let t = { ...temp }; //clone
                                t["userinput"] = temp.userinput; //set new value
                                return t;
                            } else if (af.id === c.recordfield_id) {
                                let t = { ...af }; //clone
                                t["userinput"] = result; //set new value
                                return t;
                            } else {
                                return af; //return non-touched field
                            }
                        });

                        c.recordfield_userinput = result; //update calculation recordfield

                        newCalcState = [];
                        calculationInfo.forEach((ci) => {
                            ci.id === c.id ? newCalcState.push(c) : newCalcState.push(ci);
                        });
                    }
                });
            });
            this.setState({ calculationInfo: newCalcState, actCalcFields: newActCalcFields });
        }
    };

    //helper method to evaluate calculation fields--made to handle 2 or more calc fields, called from 'calculate' method
    eval = (calculation, c, value, evaluate) => {
        var resultString = ""; //build calculation string from the above array. This will be ONE string (i.e "field1 + 2...")
        calculation.forEach((f) => {
            resultString += f;
        });

        //evaluate the calculation formula
        if (resultString) {
            resultString = resultString.replace(/,/g, ""); //remove commas from numerical before evaluating
            var result = evaluate(resultString).toString();
            if (c.measurement_unit === "Percent") {
                result = result * 100;
            }

            //set the calc field on the formAPI to the new value
            this.formApi.setValue(`${c.recordfield_id}`, result);
            return result;
        }

        //chk not blank, not has numbers and chars, or chars only in fields else throw error
        if (value === "" || value.match(/^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$/) || value.match(/^[a-zA-Z]+$/)) {
            this.setState({ snackbarOpen: true });
        }
    };

    //check if there are calc datatypes if so set state for 'calculations' and 'allfields'
    checkForCalcFields = () => {
        const { groupFields, fieldsToEdit } = this.props;
        const { actCalcFields } = this.state;

        var hascalcfield = false;
        var calculationInfo = []; //includes field id, name, type, calculation, recordfield id and recordfield username this is used matching up fields
        var allfields = [];

        //check for cal field datatype
        groupFields.forEach((fi) => {
            if (fi.type === "Calculated") {
                hascalcfield = true;
                return;
            }
        });

        //create two objects. One is calculation info
        if (hascalcfield) {
            groupFields.forEach((fi) => {
                var rf = fieldsToEdit.find((f) => f.field === fi.id);
                if (rf !== undefined && fi.type === "Calculated") {
                    var temp = {};
                    temp = { ...fi }; //clone field props to a new object
                    temp["calculation"] = fi.default_value.split(",").map((t) => {
                        var i = t.trim();
                        return i;
                    });
                    temp["recordfield_id"] = rf.id;
                    temp["recordfield_userinput"] = rf.userinput;
                    calculationInfo.push(temp);
                }

                if (rf) {
                    //push all fields into an array for removing non-calc'd below
                    rf["fieldname"] = fi.name;
                    rf["type"] = fi.type;
                    rf["calculation"] = calculationInfo["calculation"];
                    allfields.push(rf);
                }
            });

            //save actual fields used in calculation, save calculation field with custom attachment props, cleaned up field names.
            if (allfields && actCalcFields.length === 0) {
                this.setState((s) => {
                    return { actCalcFields: allfields, calculationInfo };
                });
            }
        }
    };
    checkType = (groupfield, fc, readOnly) => {
        var isReadOnly;
        const { authState, applicationReadOnly } = this.props;
        if (groupfield) if (groupfield.is_read_only || readOnly || applicationReadOnly) isReadOnly = true;

        const { actCalcFields } = this.state;
        var chkforCalcFlag = false;
        var calcType;
        actCalcFields.forEach((f) => {
            if (f.id === fc.id) {
                chkforCalcFlag = true;
            }
            //set type of calc type (i.e currency or numeric)
            if (f.type === "Currency") {
                calcType = "Currency";
            } else if (f.type === "Numeric") {
                return calcType === "Currency" ? "" : (calcType = "Numeric");
            }
        });

        var repeatableStyling = groupfield.name.length > 32 ? true : false; //used for styling of txtfields component

        if (groupfield.type === "Date") {
            return (
                <DatePicker
                    field={fc.id.toString()}
                    label={groupfield.name}
                    // autoPopulate={groupfield.auto_populate}
                    required={groupfield.is_required}
                    disabled={isReadOnly}
                    fullWidth
                />
            );
        }
        if (groupfield.type === "Time") {
            return (
                <TimePicker
                    field={fc.id.toString()}
                    label={groupfield.name}
                    autoPopulate={groupfield.auto_populate}
                    required={groupfield.is_required}
                    disabled={isReadOnly}
                    fullWidth
                />
            );
        }

        if (groupfield.type === "Text") {
            return (
                <TextField
                    // label={groupfield.name}
                    label={
                        groupfield.description_desktop ? (
                            <HelpLabel expansionPanel={true} inputLabel={groupfield.name} helpText={groupfield.description_input} />
                        ) : (
                            groupfield.name
                        )
                    }
                    repeatable={repeatableStyling}
                    field={fc.id.toString()}
                    multiline={true}
                    units={groupfield.measurement_unit}
                    disabled={isReadOnly}
                    required={groupfield.is_required}
                    fullWidth
                />
            );
        }
        if (groupfield.type === "Related Field") {
            return (
                <TextField
                    repeatable={repeatableStyling}
                    label={groupfield.name}
                    field={fc.id.toString()}
                    multiline={true}
                    units={groupfield.measurement_unit}
                    disabled={fc.userinput ? true : false}
                    required={groupfield.is_required}
                    fullWidth
                />
            );
        }

        if (groupfield.type === "Currency") {
            return (
                <TextField
                    repeatable={repeatableStyling}
                    label={groupfield.name}
                    field={fc.id.toString()}
                    disabled={isReadOnly}
                    required={groupfield.is_required}
                    useTextFormat={true}
                    fullWidth
                    eventHandle={(value) => {
                        if (chkforCalcFlag && value === null) value = "0";
                        return chkforCalcFlag === true ? this.calculate(fc, value) : "";
                    }}
                />
            );
        }
        if (groupfield.type === "Phone Number") {
            return (
                <TextField
                    repeatable={repeatableStyling}
                    label={
                        groupfield.description_desktop ? (
                            <HelpLabel inputLabel={groupfield.name} helpText={groupfield.description_input} />
                        ) : (
                            groupfield.name
                        )
                    }
                    field={fc.id.toString()} //fc.id is an arrayid with userinput from DB. This is displayed on the screen
                    disabled={isReadOnly}
                    required={groupfield.is_required}
                    fullWidth
                    usePhoneNumberFormat={true}
                />
            );
        }

        if (groupfield.type === "Numeric") {
            return (
                <TextField
                    repeatable={repeatableStyling}
                    useNumberFormat
                    identification={groupfield.identification}
                    label={groupfield.name}
                    field={fc.id.toString()} //fc.id is an arrayid with userinput from DB. This is displayed on the screen
                    units={groupfield.measurement_unit}
                    disabled={isReadOnly}
                    required={groupfield.is_required}
                    fullWidth
                    eventHandle={(value) => {
                        if (chkforCalcFlag && value === null) value = "0";
                        return chkforCalcFlag === true ? this.calculate(fc, value) : "";
                    }} 
                />
            );
        }
        if (groupfield.type === "Calculated") {
            return (
                <TextField
                    repeatable={repeatableStyling}
                    type="calculated"
                    field={fc.id.toString()}
                    expansionPanel={groupfield.description_desktop ? true : false} //for custom styling of label
                    label={
                        groupfield.description_desktop ? (
                            <HelpLabel expansionPanel={true} inputLabel={groupfield.name} helpText={groupfield.description_input} />
                        ) : (
                            groupfield.name
                        )
                    }
                    disabled={groupfield.is_read_only}
                    required={groupfield.is_required}
                    useTextFormat={groupfield.measurement_unit === "Currency" ? true : false}
                    useNumberFormat={groupfield.measurement_unit !== "Currency" ? true : false}
                    decimals={groupfield.decimals}
                    fullWidth
                    margin="none"
                    units={groupfield.measurement_unit === "Percent" ? "%" : ""}
                />
            );
        }
        if (groupfield.type === "Yes/No") {
            return (
                <RadioGroup
                    field={fc.id.toString()}
                    name={fc.id}
                    disabled={isReadOnly}
                    required={groupfield.is_required}
                    fullWidth
                    options={[
                        { label: "Yes", value: "Yes" },
                        { label: "No", value: "No" },
                    ]}
                    alignment={true}
                    label={groupfield.name}
                />
            );
        }
        if (groupfield.type === "Drop Down" || groupfield.type === "Multi Select") {
            var label;
            if (groupfield.is_required) {
                label = groupfield.name.concat(" *");
            } else {
                label = groupfield.name;
            }

            if (groupfield.values_csv && !groupfield.user_dropdown) {
                let tempArr = [];

                //has numbers with commas escapes for thousand values
                if (groupfield.values_csv.includes("\\,")) {
                    let str = groupfield.values_csv;
                    let wtf = "";
                    let shouldPass = false;
                    for (let i = 0; i < str.length; i++) {
                        if (str[i] === "\\") {
                            shouldPass = true;
                        } else if (shouldPass) {
                            //record this comma as & before it
                            wtf = wtf + str[i];
                            shouldPass = false;
                        } else if (str[i] === ",") {
                            //not & before it so record selection
                            tempArr.push(wtf);
                            wtf = "";
                        } else {
                            wtf = wtf + str[i];
                            if (i === str.length - 1) {
                                //last selection save
                                tempArr.push(wtf);
                            }
                        }
                    }
                } else {
                    //no numbers with & escape characters so just split
                    tempArr = groupfield.values_csv.split(",");
                }

                return (
                    <ReactSelect
                        field={fc.id.toString()}
                        options={this.dropDownValues(tempArr)}
                        disabled={isReadOnly}
                        label={groupfield.description_desktop ? <HelpLabel inputLabel={label} helpText={groupfield.description_input} /> : label}
                        isMulti={groupfield.type === "Drop Down" ? false : true}
                        required={groupfield.is_required}
                        fullWidth
                        eventHandle={(e) => {
                            // if (window.location.origin.includes("ms4prod")) {
                            if (fc.field === 38372) {
                                //custom swrd to mirror to another field in repeatable (Special Condition Default Options = special condition text)
                                let id = this.props.fieldsToEdit.find((f) => f.field === 38352).id;
                                if (id) this.formApi.setValue(id.toString(), e);
                            }
                            // } else if (fc.field === 38398) {
                            //     //dev custom swrd to mirror to another field in repeatable (Special Condition Default Options = special condition text)
                            //     let id = this.props.fieldsToEdit.find((f) => f.field === 38352).id;
                            //     if (id) this.formApi.setValue(id.toString(), e);
                            // }
                        }}
                        repeatable={true}
                        valuesCsv={tempArr}
                    />
                );
            } else if (groupfield.user_dropdown) {
                //userdropdown gets all users into list
                const { allUsers } = this.props;
                const nameUsers = allUsers.map((u) => {
                    return u.name;
                });

                return (
                    <ReactSelect
                        label={groupfield.description_desktop ? <HelpLabel inputLabel={label} helpText={groupfield.description_input} /> : label}
                        field={fc.id.toString()}
                        options={this.dropDownValues(nameUsers)}
                        disabled={isReadOnly}
                        isMulti={groupfield.type === "Drop Down" ? false : true}
                        required={groupfield.is_required}
                        autoPopulate={groupfield.auto_populate ? authState.user.name : null}
                        repeatable={true}
                        fullWidth
                    />
                );
            }
        }

        if (groupfield.type === "Radio") {
            if (groupfield.values_csv) {
                let tempArr = [];
                let options = [];
                tempArr = groupfield.values_csv.split(",");
                tempArr.forEach((t) => {
                    options.push({ label: t, value: t });
                });
                return (
                    <RadioGroup
                        field={fc.id.toString()}
                        name={fc.id}
                        disabled={isReadOnly}
                        required={groupfield.is_required}
                        fullWidth
                        options={options}
                        alignment={true}
                        label={groupfield.name}
                    />
                );
            }
        }
    };
    //value function for dropdown datatype. This is the options property.
    dropDownValues = (table) => {
        return table.map((row) => ({
            label: row,
            value: row,
        }));
    };

    defaultValue = () => {
        const { fieldsToEdit, groupFields } = this.props;
        var defaultValues = {};

        if (fieldsToEdit) {
            fieldsToEdit.forEach((f) => {
                var autoField = groupFields.find((gf) => {
                    return gf.id === f.field;
                });

                if (autoField.default_value && !f.userinput && autoField.type !== 'Calculated') {
                    //set default value if not prev set
                    defaultValues[f.id] = autoField.default_value;
                } else {
                    //related field put into just userinput form not <a> tag
                    if (groupFields && groupFields[0].type === "Related Field" && f.userinput) {
                        let start = f.userinput.indexOf(">", 0) + 1;
                        var end = f.userinput.indexOf("</", 78);
                        defaultValues[f.id] = f.userinput.slice(start, end);
                    } else defaultValues[f.id] = f.userinput; //needs to be a string for form
                }
            });
        }

        return defaultValues;
    };

    saveRecord = (values) => {
        const {
            ormRepeatableFieldThenUpdate,
            ormRepeatableFieldUpdate,
            fieldDialogClose,
            handleResetCreateNewState, 
            groupFields,
            parentRS,
            isParent,
            ormRecordSectionUpdateLocalOnly,
            ormRepeatableFieldLoadDetailChild,
        } = this.props;

        //close first as calc can take longer to close window for creating new state
        // fieldDialogClose();
        var _this = this;
        let totalLength = Object.keys(values).length
        var counter = 0  

        // CUSTOM CRWD and SRWD, Rochester
        // If we are on the status table reload the record section to get the expired date
        if (groupFields[0].id === 16240 || groupFields[0].id === 38282 || groupFields[0].id === 47854 || groupFields[0].id === 50242 || (groupFields[7] && groupFields[7].id === 52837) ||
            (groupFields[1] && groupFields[1].id === 12208) || (groupFields[0] && groupFields[0].id === 69647)) {  
            for (const v in values) { 
                let obj ={}
                obj['id'] = v
                obj['userinput'] = values[v] 
                counter++
                //eslint-disable-next-line
                ormRepeatableFieldThenUpdate(obj, function () { 
                    // update other outside fields
                    if((groupFields[0].id === 16240 || groupFields[0].id === 38282 || groupFields[0].id === 47854 || groupFields[0].id === 50242 || (groupFields[7] && groupFields[7].id === 52837) || 
                        (groupFields[1] && groupFields[1].id === 12208) || (groupFields[0] && groupFields[0].id === 69647)) && counter === totalLength){// notes 
                        
                        if (groupFields[7] && groupFields[7].id === 52837){ //rochester update only on custom repeatabletable
                            ormRepeatableFieldLoadDetailChild(_this.props.recordSection);
                        }
                        ormRecordSectionUpdateLocalOnly({
                            id: _this.props.recordSection,
                            has_children_loaded: false,
                        });      
                        if (groupFields[0].id === 51126){ //update to rochester summations
                            setTimeout(()=>{
                                _this.props.resetCounter(null,null,true)  
                            },500) 
                        }   
                       
                    } 
                }); 
            } 
        } else{ //STUPID i know to not just use above but I give up for repeatable summation timing this way works the .then above gives issues with updating the outside fields properly (probably timing)...
            for (const v in values) { 
                ormRepeatableFieldUpdate({
                    id: v,
                    userinput: values[v],
                });
            }
        } 


        //maybe change to check or set a flag on child field but this is quick so decided to not add a flag on the field model and just reload the parent rs only if this is a repeatable on a child section
        if (!isParent)
            setTimeout(function () {
                //update parent related mirror recordsection
                ormRecordSectionUpdateLocalOnly({
                    id: parentRS,
                    has_children_loaded: false,
                });
            }, 1100);

        fieldDialogClose();
        handleResetCreateNewState();//updates outside fields for summation
        this.setState({ calculationInfo: {}, actCalcFields: [] });
       
    };

    errorValidator = (values) => {
        const { groupFields, fieldsToEdit } = this.props;
        var valObj = {}; 
 
        fieldsToEdit.forEach(f=> {
            groupFields.find(gf=>{
                if(f.field===gf.id && gf.is_required){
                    return Object.keys(values).find((k) => {
                        if (parseInt(k)===f.id && !values[k]){      
                            return (valObj[f.id] = "Required Field");
                        }else{
                            return (valObj[f.id] = null);
                        }
                    })
                }else return null
            })
        }) 

        return valObj;
    };

    render() {
        const {
            classes,
            fieldDialogClose,
            fieldDialogStatus,
            createFields,
            editRepeatableRecord,
            fieldsToEdit,
            groupName,
            groupFields,
            handleResetCreateNewState,
            disabled,
            repeatableTableId,
            handleDeleteRepeatableTable, 
        } = this.props;
        const { count, loadingFields } = this.state;
        var relatedField = false;
        if (groupFields && groupFields[0].type === "Related Field" && fieldsToEdit && fieldsToEdit[0].userinput) relatedField = true;

        if (createFields && count === 0 && !editRepeatableRecord) this.createNewFields(); //create new record for repeatable

        if (editRepeatableRecord) {
            return (
                <Dialog open={fieldDialogStatus} className={{ root: classes.minHeight }}>
                    <Toolbar style={{ backgroundColor: "#d3d3d3" }}>
                        <Typography variant="h5" className={classes.flex}>
                            {groupName}
                        </Typography>
                        <IconButton
                            aria-label="Close Dialog"
                            onClick={() => {
                                fieldDialogClose();
                                handleResetCreateNewState();
                                this.setState({ calculationInfo: {}, actCalcFields: [] });
                            }}>
                            <Close />
                        </IconButton>
                    </Toolbar>
                    <DialogContent>
                        <DialogContentText>
                            <Formm
                                key={this.formKey}
                                getApi={(el) => (this.formApi = el)}
                                validateOnSubmit="true"
                                dontValidateOnMount={true}
                                defaultValues={this.defaultValue()}
                                validateError={this.errorValidator}
                                onSubmit={(values, e, formApi, fromRepeatable) => this.saveRecord(values)}
                                pure={false}>
                               
                                {(formApi) => (
                                    <form onSubmit={formApi.submitForm}>
                                        <Grid container spacing={2} style={{ paddingTop: 16 }}>
                                            {groupFields &&
                                                groupFields.map((gf) => {
                                                    var field = fieldsToEdit.find((f) => {
                                                        return gf.id === f.field ? gf : null;
                                                    });
                                                    if(field !== undefined)
                                                        return (
                                                            <Grid
                                                                item
                                                                xs={6}
                                                                style={{
                                                                    marginTop: gf.type === "Drop Down" || gf.type === "Multi Select" ? -12 : 0,
                                                                }}>
                                                                {gf && field && field.id &&  this.checkType(gf, field)}
                                                            </Grid>
                                                        );
                                                    else{
                                                        return null
                                                    }
                                                })}

                                            <Grid xs={12}>
                                                <div style={{ marginTop: 22 }}>
                                                    <Button 
                                                        disabled={disabled || relatedField}
                                                        onClick={(event) => {
                                                            this.setState({ calculationInfo: {}, actCalcFields: [] });
                                                            formApi.submitForm(); 
                                                        }}
                                                        variant="raised"
                                                        color="primary"
                                                        className={classes.floatRight}>
                                                        Save Record
                                                    </Button>
                                                    <Button
                                                        disabled={disabled}
                                                        onClick={() => {
                                                            this.setState({ calculationInfo: {}, actCalcFields: [] });
                                                            handleResetCreateNewState();
                                                            return handleDeleteRepeatableTable(repeatableTableId);
                                                        }}
                                                        variant="raised"
                                                        color="primary"
                                                        className={classes.floatLeft}>
                                                        Delete Record
                                                    </Button>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </form>
                                )}
                            </Formm>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
            );
        }
        if (loadingFields) {
            return (
                <Dialog open={loadingFields} classes={{ paper: classes.minWidth }}>
                    <Toolbar style={{ backgroundColor: "#d3d3d3" }}>
                        <Typography variant="h5" className={classes.flex} style={{ marginLeft: "15%" }}>
                            Group Record is being Created
                        </Typography>
                    </Toolbar>
                    <DialogContent className={classes.dialogMin}>
                        <DialogContentText>
                            <Grid container spacing={2}>
                                <Grid item xs={12} style={{ textAlign: "center" }}>
                                    <CircularProgress size={90} className={classes.progress} />
                                    <Typography variant="h6" style={{ textAlign: "center" }}>
                                        Loading... Please Wait
                                    </Typography>
                                </Grid>
                            </Grid>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
            );
        }
        return null;
    }
}

// The table component
class Repeatable_Table extends Component {
    constructor(props, context) {
        super(props, context);
        // Default states
        this.state = {
            order: "desc",
            orderBy: null,
            deleteDialogOpen: false,
            deleteDialogText: null,
            deleteDialogConfirmAction: null,
            fieldDialogStatus: false,
            page: 0, 
            createFields: false,
            fieldsToEdit: null,
            editRepeatableRecord: false,
            repeatableTableId: null,
            quickCreateRelated: false,
        };
    }

    quickSearchCreateFields = (searchfields) => {
        const { groupFields, recordSection, ormRepeatableTableCreate, ormRepeatableFieldCreate } = this.props;

        this.setState({
            quickCreateRelated: true,
        });

        if (groupFields.length > 0) {
            ormRepeatableTableCreate({
                recordsection_id: recordSection,
                group_id: groupFields[0].group,
            }).then((table) => {
                groupFields.map((g) => {
                    ormRepeatableFieldCreate({
                        repeatabletable_id: table.id,
                        field_id: g.id, //get each field id
                        userinput: searchfields.savedLabel,
                    }).then(() => {
                        this.setState({ quickCreateRelated: false });
                    });

                    return null;
                });
            });
        } else {
            this.setState({ quickCreateRelated: false });
        }
    };

    // sort the cells for the table
    handleRequestSort = (event, property) => {
        const { repeatableTableFields } = this.props;

        const orderBy = property;
        let order = "desc";

        if (this.state.orderBy === property && this.state.order === "desc") {
            order = "asc";
        }

        repeatableTableFields.sort((a1, b1) => {
            //match column field to "orderBy" which is columnID that was clicked on to sort, return "a" & "b objects
            var aFields = a1.repeatablefields.find(function (e) {
                if (e) return e.field === orderBy;
                else return undefined;
            });
            var bFields = b1.repeatablefields.find(function (e) {
                if (e) return e.field === orderBy;
                else return undefined;
            });

            if ((aFields && bFields) !== undefined && (aFields.field && bFields.field) === orderBy) {
                if (aFields.userinput === null && bFields.userinput === null) {
                    return 0; //chk null types
                } else if (bFields.userinput === null) {
                    return -1;
                } else if (aFields.userinput === null) {
                    return 1;
                }

                var a, b, d1, d2;
                if (aFields.userinput && bFields.userinput) {
                    //check for date types
                    aFields.userinput.includes("/") ? (a = aFields.userinput) : (a = aFields.userinput.toUpperCase());
                    bFields.userinput.includes("/") ? (b = bFields.userinput) : (b = bFields.userinput.toUpperCase());
                }
                if (order === "desc") {
                    if (!isNaN(a && b)) {
                        return a - b; //numerical sort
                    } else {
                        //string sort
                        if (a.includes(",") && b.includes(",")) {
                            // numerical string that has commas
                            a = parseFloat(a.replace(/,/g, ""));
                            b = parseFloat(b.replace(/,/g, ""));
                        } else if (typeof (a || b) !== "function" && a.includes("/") && b.includes("/")) {
                            d1 = Date.parse(a); //date sort
                            d2 = Date.parse(b);
                            return d1 < d2 ? -1 : d1 > d2 ? 1 : 0;
                        } else if (/\bAM\b/.test(a) || /\bPM\b/.test(a) || /\bAM\b/.test(b) || /\bPM\b/.test(b)) {
                            return new Date("1970/01/01 " + a) - new Date("1970/01/01 " + b); //time sort
                        }
                        return a === b ? 0 : a > b ? 1 : -1; //string sort
                    }
                } else {
                    //ascending
                    if (!isNaN(a && b)) {
                        return b - a; //numerical sort
                    } else {
                        //string sort
                        if (a.includes(",") && b.includes(",")) {
                            a = parseFloat(a.replace(/,/g, ""));
                            b = parseFloat(b.replace(/,/g, ""));
                        } else if (typeof (a || b) !== "function" && a.includes("/") && b.includes("/")) {
                            d1 = Date.parse(a); //date sort
                            d2 = Date.parse(b);
                            return d2 < d1 ? -1 : d2 > d1 ? 1 : 0;
                        } else if (/\bAM\b/.test(a) || /\bPM\b/.test(a) || /\bAM\b/.test(b) || /\bPM\b/.test(b)) {
                            return new Date("1970/01/01 " + b) - new Date("1970/01/01 " + a); //time sort
                        }
                        return a === b ? 0 : a < b ? 1 : -1; //string sort
                    }
                }
            }
            return a & b;
        });

        this.setState({ order, orderBy });
    };

    //close form dialog
    fieldDialogClose = () => {
        this.setState({ fieldDialogStatus: false });
    };

    // When the delete button is pressed
    deleteRepeatRecord = (n) => {
        this.setState({
            deleteDialogOpen: true,
            deleteDialogConfirmAction: n,
            deleteDialogText: "WARNING: This group record will be removed. Are you sure you wish to permanently delete this record?",
        });
    };

    handleChangePage = (event, page) => {
        this.setState({ page });
    };

    handleChangeRowsPerPage = (event) => {
        this.setState({ rowsPerPage: event.target.value, page: 0 });
    };

    handleSetRepeatableTableId = (repeatabletable_id) => {
        //set the repeatable table id for deletion
        if (repeatabletable_id) this.setState({ repeatableTableId: repeatabletable_id });
    };

    handleCreateRepeatableRecord = (fields) => {
        const { editRepeatableRecord } = this.state;
        if (!editRepeatableRecord) this.setState({ editRepeatableRecord: true, fieldDialogStatus: true, fieldsToEdit: fields });
    };
    handleResetCreateNewState = () => {
        const { editRepeatableRecord } = this.state;
        if (editRepeatableRecord) {
            this.props.resetCounter(); //reset used for putting calculations of repeatable into non-repeatable fields
            this.setState({ editRepeatableRecord: false, fieldDialogStatus: false, /*fieldsToEdit: null,*/ createFields: false });
        }
    };
    componentDidMount=()=>{
        const{rowsPerPage_}=this.props
        if (rowsPerPage_) this.setState({rowsPerPage:parseInt(rowsPerPage_)}) //we set default to be what is in the section config
    }

    render() {
        const {
            classes,
            ormRepeatableTableCreate,
            ormRepeatableTableDelete,
            ormRepeatableFieldCreate,
            ormRepeatableFieldUpdate,
            ormRepeatableFieldThenUpdate,
            authState,
            permission,
            showRepeatableTable,
            section,
            groupName,
            groupFields,
            repeatableTableFields,
            recordSection,
            allUsers,
            ormRecordSectionUpdateLocalOnly,
            parentRS,
            isParent,
            ormRepeatableFieldLoadDetailChild,
            applicationReadOnly
        } = this.props;
        const {
            order,
            orderBy,
            deleteDialogOpen,
            deleteDialogText,
            deleteDialogConfirmAction,
            rowsPerPage,
            page,
            createFields,
            fieldsToEdit,
            fieldDialogStatus,
            editRepeatableRecord,
            repeatableTableId,
            quickCreateRelated,
        } = this.state;

        var tableData;

        var columnRecord = [],
            isRelatedField = false,
            relatedFieldId = null;
        columnRecord.push({ id: "actions", label: "", allowSort: false }); //push the first action area to be blank in the header on the table to allow for alignment

        // populate the columns
        if (groupFields) {
            groupFields.forEach((gf) => {
                columnRecord.push({ id: gf.id, label: gf.name, allowSort: true, type: gf.type });
                if (gf.type === "Related Field") {
                    isRelatedField = true;
                    relatedFieldId = gf.populate_field;
                }
            });
        }

        if (authState.user) {
            var readOnly;
            var perm = permission.find((p) => {
                if (p.user === authState.user.id) {
                    return p;
                }
                return null;
            });
            if (perm) readOnly = perm.readonly || perm.readonlyexternal ? true : false;
            if (readOnly === undefined) readOnly = false;
            if(applicationReadOnly) readOnly = true
        }

        if (showRepeatableTable) {
            tableData = repeatableTableFields;
            if (tableData)
                return (
                    <>
                        <Paper className={classes.root}>
                            <Grid container spacing={1} style={{ marginTop: 10, marginBottom: 10, marginLeft: 10 }}>
                                <Grid item xs={4}>
                                    {isRelatedField && (
                                        <ReactSelectAsync
                                            disabled={readOnly}
                                            field={22}
                                            label={
                                                <HelpLabel
                                                    inputLabel="Search to Create a New Related Record"
                                                    helpText={`Add a new related record. 
                                
                                Instructions: 
                                Type the requested search parameters into the window, after typing has stopped the quick search tool will return the database results that contain those primary 'identification' or 'related' field characters. After you select the option from the dropdown a new related record will automatically be created.                                         
                                            `}
                                                />
                                            }
                                            relatedField={relatedFieldId}
                                            authState={authState}
                                            eventHandle={(searchvalue) => {
                                                if (searchvalue !== "") {
                                                    this.quickSearchCreateFields(searchvalue);
                                                }
                                            }}
                                        />
                                    )}
                                </Grid>
                            </Grid>
                            {!isRelatedField && (
                                <Button
                                    fullWidth
                                    disabled={readOnly}
                                    className={classes.gutterBottom}
                                    gutterBottom={true}
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        this.setState({ createFields: true });
                                    }}>
                                    <AddIcon className={classes.iconAdd} />
                                    &nbsp;&nbsp;&nbsp;Create New
                                </Button>
                            )}
                            <Table className={classes.table}>
                                <EnhancedTableHead
                                    key={section.id}
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={this.handleRequestSort}
                                    columnData={columnRecord}
                                />
                                <TableBody>
                                    {(() => {
                                        if (tableData.length < 1 || (tableData.length > 0 && tableData[0].repeatablefields.length === 0)) {
                                            return (
                                                <TableRow>
                                                    <CustomTableCell colSpan={columnRecord.length} className={classes.centerAlign}>
                                                        No Records Available
                                                    </CustomTableCell>
                                                </TableRow>
                                            );
                                        }

                                        return tableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((n) => {
                                            return (
                                                <TableRow hover key={n.id}>
                                                    <CustomTableCell className={classes.nowrap}>
                                                        <Tooltip title="Edit">
                                                            {/* EDIT RECORD will trigger createRepeatableField class*/}
                                                            <Button
                                                                onClick={() => {
                                                                    this.setState({
                                                                        fieldsToEdit: n.repeatablefields,
                                                                        editRepeatableRecord: true,
                                                                        fieldDialogStatus: true,
                                                                        repeatableTableId: n.repeatablefields[0].repeatabletable,
                                                                    });
                                                                }}
                                                                className={classes.deleteWidth}>
                                                                <EditIcon color="primary" />
                                                            </Button>
                                                        </Tooltip>

                                                        <Tooltip title="Delete">
                                                            <Button
                                                                disabled={readOnly}
                                                                onClick={() => this.deleteRepeatRecord(n.id)}
                                                                className={classes.deleteWidth}>
                                                                <DeleteIcon color="inherit" />
                                                            </Button>
                                                        </Tooltip>
                                                    </CustomTableCell>
                                                    {/* PRINTING OF THE DATA IN THE TABLE */}
                                                    {columnRecord.map((column, index) => {
                                                        if (index !== 0 && n.repeatablefields.length > 0) {
                                                            //dont print 0 as that is button location
                                                            var found = n.repeatablefields.find((f) => {
                                                                return f.field === column.id ? f : null;
                                                            });
                                                            if (column.type === "Phone Number") {
                                                                return (
                                                                    <CustomTableCell key={column.id}>
                                                                        <NumberFormat
                                                                            format="+1 (###) ###-####"
                                                                            allowEmptyFormatting
                                                                            mask="_"
                                                                            displayType={"text"}
                                                                            value={found ? found.userinput : "-"}
                                                                        />
                                                                    </CustomTableCell>
                                                                );
                                                            } else if (column.type === "Related Field") {
                                                                return (
                                                                    <CustomTableCell key={column.id}>
                                                                        <div
                                                                            onClick={() => {
                                                                                ormRecordSectionUpdateLocalOnly({
                                                                                    id: recordSection,
                                                                                    has_children_loaded: false,
                                                                                });
                                                                            }}
                                                                            dangerouslySetInnerHTML={{ __html: found ? found.userinput : "-" }}
                                                                        />
                                                                    </CustomTableCell>
                                                                );
                                                            } else if (column.type === "Currency") {
                                                                return (
                                                                    <CustomTableCell key={column.id}>
                                                                        <NumberFormat
                                                                            prefix="$"
                                                                            mask="_"
                                                                            displayType={"text"}
                                                                            value={found ? found.userinput : "-"}
                                                                            thousandSeparator={true}
                                                                        />
                                                                    </CustomTableCell>
                                                                );
                                                            } else {
                                                                if (!found || !found.userinput) {
                                                                    return (
                                                                        <CustomTableCell key={column.id}>{"-"}</CustomTableCell>
                                                                    );
                                                                }
                                                                if (found.userinput.length > 250) {
                                                                    return (
                                                                        <Tooltip title={found.userinput}>
                                                                            <CustomTableCell key={column.id}>
                                                                                <div className={classes.ellipsis}>
                                                                                    {found.userinput}
                                                                                </div>
                                                                            </CustomTableCell>
                                                                        </Tooltip>
                                                                    )
                                                                } else {
                                                                    return (
                                                                        <CustomTableCell key={column.id}>
                                                                            {found.userinput}
                                                                        </CustomTableCell>
                                                                    )
                                                                }
                                                            }
                                                        } else {
                                                            return null;
                                                        }
                                                    })}
                                                </TableRow>
                                            );
                                        });
                                    })()}
                                </TableBody>
                                {tableData.length > 1 && tableData[0].repeatablefields.length !== 0 && (
                                <TableFooter>
                                    <TableRow>
                                        <TablePagination
                                            colSpan={columnRecord.length}
                                            count={tableData.length}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            onChangePage={this.handleChangePage}
                                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                            ActionsComponent={TablePaginationActionsWrapped}
                                        />
                                    </TableRow>
                                </TableFooter>
                                )}
                            </Table>
                            <WarningDialog
                                confirmAction={() => {
                                    let isLastRepeatableRecord = tableData.length === 1 ? true : false; //use for recordfields to zero out as no more repeatable records
                                    let _this = this;
                                    ormRepeatableTableDelete(deleteDialogConfirmAction).then(() => {
                                        
                                        //custom crwd reupdate cap projects recordfields in BMP maintenance new section, also for rochester swmpac sum field
                                        if(tableData.length > 0 && (tableData[0].group === 9369 || tableData[0].group === 9711 || tableData[0].group === 2684)){ 
                                            ormRecordSectionUpdateLocalOnly({
                                                id: _this.props.recordSection,
                                                has_children_loaded: false,
                                            });
                                            if(tableData[0].group === 9369) //crwd only
                                            _this.props.resetCounter(null,null,true) 
                                        }
                                         
                                        
                                        this.setState({ deleteDialogOpen: false });
                                        this.fieldDialogClose();
                                        this.props.resetCounter(isLastRepeatableRecord, tableData);
                                    });
                                }}
                                cancelAction={() => this.setState({ deleteDialogOpen: false })}
                                open={deleteDialogOpen}
                                title="Delete Record"
                                text={deleteDialogText}
                            />
                            <CreateRepeatableFields
                                ormRepeatableFieldLoadDetailChild={ormRepeatableFieldLoadDetailChild}
                                disabled={readOnly}
                                createFields={createFields}
                                handleCreateRepeatRecord={this.handleCreateRepeatableRecord}
                                handleResetCreateNewState={this.handleResetCreateNewState}
                                editRepeatableRecord={editRepeatableRecord}
                                fieldsToEdit={fieldsToEdit}
                                fieldDialogStatus={fieldDialogStatus}
                                fieldDialogClose={this.fieldDialogClose}
                                groupName={groupName}
                                groupFields={groupFields}
                                handleSetRepeatableTableId={this.handleSetRepeatableTableId} //for initial creation and/or deleting
                                handleDeleteRepeatableTable={this.deleteRepeatRecord}
                                repeatableTableId={repeatableTableId}
                                recordSection={recordSection}
                                classes={classes}
                                ormRepeatableTableCreate={ormRepeatableTableCreate}
                                ormRepeatableFieldCreate={ormRepeatableFieldCreate}
                                ormRepeatableFieldUpdate={ormRepeatableFieldUpdate}
                                ormRecordSectionUpdateLocalOnly={ormRecordSectionUpdateLocalOnly}
                                ormRepeatableFieldThenUpdate={ormRepeatableFieldThenUpdate}
                                resetCounter={this.props.resetCounter}
                                allUsers={allUsers}
                                authState={authState}
                                parentRS={parentRS}
                                isParent={isParent}
                                applicationReadOnly={applicationReadOnly}
                            />
                            {quickCreateRelated && (
                                <Dialog open={quickCreateRelated} classes={{ paper: classes.minWidth }}>
                                    <Toolbar style={{ backgroundColor: "#d3d3d3" }}>
                                        <Typography variant="h5" className={classes.flex} style={{ marginLeft: "15%" }}>
                                            Related Record is being Created
                                        </Typography>
                                    </Toolbar>
                                    <DialogContent className={classes.dialogMin}>
                                        <DialogContentText>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} style={{ textAlign: "center" }}>
                                                    <CircularProgress size={90} className={classes.progress} />
                                                    <Typography variant="h6" style={{ textAlign: "center" }}>
                                                        Loading... Please Wait
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </DialogContentText>
                                    </DialogContent>
                                </Dialog>
                            )}
                        </Paper>
                    </>
                );

            return null;
        }
    }
}
Repeatable_Table.displayName = "Repeatable_Table";

Repeatable_Table = connect(
    (state, ownProps) => ({
        authState: state.auth, //component wide state prop "authState" gets derived info from database
        permission: getPermission(state, ownProps),
    }),
    {
        ...Section.actions,
        ...RepeatableTable.actions,
        ...RepeatableField.actions,
        ...RecordSection.actions,
    }
)(Repeatable_Table);

export default withStyles(styles)(withRouter(Repeatable_Table));
