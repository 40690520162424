import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import CircularProgress from "@material-ui/core/CircularProgress";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import ViewIcon from "@material-ui/icons/Visibility";
import { Form as Formm } from "react-form";
import Select from "../common/Select";
import Close from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import AddIcon from "@material-ui/icons/Add";
import Save from "@material-ui/icons/Save";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import Paper from "@material-ui/core/Paper";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import sub from "date-fns/sub";

import WarningDialog from "../common/WarningDialog";
import { createSelector } from "../common/orm";
import DatePicker from "../common/DatePicker";
import HelpLabel from "../common/HelpLabel";
import AppContainer from "../common/AppContainer";
import FileInput from "../common/FileInput";
import EnhancedTableHead from "../common/EnhancedTableHead";
import CustomTableCell from "../common/TableCell";
import { CustomReportPage, CustomReportRecord, CustomReportQueryField, CustomReportTableField, Page, Field } from "../page/models";
import TextField from "../common/TextField";

const styles = (theme) => ({
    deleteWidth: {
        minWidth: 34,
        width: 34,
        marginRight: 8,
        marginTop: 2,
        marginBottom: 2,
    },
    delete: {
        // backgroundColor: "lightgray",
        height: "1.7em",
        width: "1.7em",
        paddingTop: "4px",
        marginTop: "29px",
        paddingRight: 0,

        [theme.breakpoints.down("1782")]: {
            paddingRight: 0,
        },
    },
    deleteIcon: {
        paddingLeft: 5,
        [theme.breakpoints.down("1782")]: {
            marginLeft: -22,
        },
    },
    centerAlign: {
        textAlign: "center",
    },
    nowrap: {
        whiteSpace: "nowrap",
    },
    button: {
        float: "right",
        marginBottom: "7px",
        [theme.breakpoints.down("sm")]: {
            display: "none",
        },
    },
    iconAdd: {
        backgroundColor: "#34aa20",
        color: "white",
        height: "25px",
        width: "25px",
        paddingTop: "0px",
        fontSize: "100%",
        borderRadius: 12,
    },
    minWidth: {
        minWidth: 650,
        minHeight: 550,
    },
    reportGeneration: {
        minWidth: 600,
    },
    buildReport: {
        minWidth: 650,
    },
    pageFields: {
        minWidth: 1035,
        minHeight: 550,
    },
    flex: {
        flex: 1,
    },
    root1: {
        width: "100%",
        overflowX: "auto",
        backgroundColor: "#f0f0f0",
        minWidth: 596,
    },
    table: {
        minWidth: 500,
    },
    progress: {
        display: "table",
        marginLeft: "auto",
        marginRight: "auto",
    },
});
const getThisCustomReport = CustomReportRecord.selectByUrlId();
const getAllCustomReports = CustomReportRecord.selectAll();
const getPages = Page.selectAll();
const getFields = Field.selectAll(); //lazy way but get all so we can trigger what we want

const getCustomReportsWPages = createSelector((session, id) => {
    return session.CustomReportRecord.all()
        .toModelArray()
        .map((customreportrecord) => ({
            customreportpage: customreportrecord.customreportpage.all().orderBy("id").toModelArray(),
            ...customreportrecord.ref,
        }));
});
const getCustomReportFields = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["id"]),
    (session, id) => {
        return session.CustomReportRecord.filter((c) => c.id === id)
            .all()
            .toModelArray()
            .map((customreportrecord) => ({
                customreportpage: customreportrecord.customreportpage
                    .all()
                    .orderBy("id")
                    .toModelArray()
                    .map((customreportpage) => ({
                        customreportqueryfields: customreportpage.customreportqueryfields.all().orderBy("id").toRefArray(),
                        ...customreportpage,
                    })),
                ...customreportrecord.ref,
            }));
    }
);

const getCustomReportTableFields = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["id"]),
    (session, id) => {
        return session.CustomReportRecord.filter((c) => c.id === id)
            .all()
            .toModelArray()
            .map((customreportrecord) => ({
                customreportpage: customreportrecord.customreportpage
                    .all()
                    .orderBy("id")
                    .toModelArray()
                    .map((customreportpage) => ({
                        customreporttablefields: customreportpage.customreporttablefields.all().orderBy("id").toRefArray(),
                        ...customreportpage,
                    })),
                ...customreportrecord.ref,
            }));
    }
);

function MAKE_OPTIONS_RCWD(table) {
    return table.map((row) => ({
        label: row,
        value: row,
    }));
}

class Home extends Component {
    state = {
        order: "desc",
        orderBy: null,
        dialogOpen: false,
        totalFields: [],
        customReportInfo: [],
        customRecordId: null,
        customReportOpen: false,
        rcwdReportOpen: false,
        uploading: false,
        rileyReportOpen: false,
        foo: null,
        openPageField: false,
        thispage: null,
        createNew: false,
        creatingReport: false,
        showReportRecord: false,
        showCreateCustomReport: false,
        deleteDialogOpen: false,
        deletid: 0,
        deletePageName: null,
        deleteSearchDialogOpen: null,
        report: null,
    };

    componentDidMount() {
        console.log("");
    }
    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = "desc";

        if (this.state.orderBy === property && this.state.order === "desc") {
            order = "asc";
        }

        order === "desc"
            ? this.props.applications.sort((a, b) => (b[orderBy].toUpperCase() < a[orderBy].toUpperCase() ? -1 : 1))
            : this.props.applications.sort((a, b) => (a[orderBy].toUpperCase() < b[orderBy].toUpperCase() ? -1 : 1));

        this.setState({ order, orderBy });
    };

    MAKE_OPTIONS = (pages) => {
        return pages.map((p) => ({
            label: p.name,
            value: p.id,
        }));
    };
    OPTIONS = (and_or) => {
        return and_or.map((a) => ({
            label: a,
            value: a,
        }));
    };

    //build record
    updateCustomReport = (values) => {
        const { authState, ormCustomReportRecordUpdate } = this.props;
        const { customRecordId } = this.state;

        ormCustomReportRecordUpdate({ city_id: authState.user.city_id, id: customRecordId, ...values });
        this.setState({ showReportRecord: true, showCreateCustomReport: false }); //update button showing states
    };

    //default on custom report, not all values
    defaultCustomReportValues = () => {
        var obj = {};
        const { thisCustomReport } = this.props;
        obj["report_name"] = thisCustomReport.report_name;

        // obj["start_date"] = thisCustomReport.start_date;
        // obj["end_date"] = thisCustomReport.end_date;
        return obj;
    };
    //default on page field values
    pageDefaultValues = () => {
        const { reports } = this.props;
        const { thispage } = this.state;
        var obj = {};

        if (reports.length > 0 && reports[0] && reports[0].customreportpage.length > 0) {
            var filtered = reports[0].customreportpage.filter((c) => c.id === thispage);
            if (filtered.length > 0) {
                if (filtered[0].customreportqueryfields.length > 0)
                    filtered.forEach((p, i) => {
                        p.customreportqueryfields.forEach((c) => {
                            // obj["page_id"] = p._fields.page;
                            obj["field_id" + c.id] = c.field_id;
                            obj["field_name" + c.id] = c.field_name;
                            obj["filter_type" + c.id] = c.filter_type;
                            obj["query_text" + c.id] = c.query_text;
                            obj["query_text2" + c.id] = c.query_text2;
                            obj["section_id" + c.id] = c.section_id;
                        });
                    });
                obj["page_id"] = filtered[0]._fields.page;
                obj["and_or"] = filtered[0].and_or;
                obj["search_name"] = filtered[0].search_name;
            }
        }

        return obj;
    };

    pageTableValues = () => {
        const { reportwithtablefields } = this.props;
        const { thispage } = this.state;
        var obj = {};

        if (reportwithtablefields.length > 0 && reportwithtablefields[0] && reportwithtablefields[0].customreportpage.length > 0) {
            var filtered = reportwithtablefields[0].customreportpage.filter((c) => c.id === thispage);
            if (filtered.length > 0 && filtered[0].customreporttablefields.length > 0) {
                filtered.forEach((p, i) => {
                    p.customreporttablefields.forEach((c) => {
                        obj["field_id" + c.id] = c.field_id;
                        obj["field_name" + c.id] = c.field_name;
                    });
                });
            }
        }
        return obj;
    };

    addNewFields = () => {
        const { ormCustomReportQueryFieldCreate } = this.props;
        const { thispage } = this.state;
        ormCustomReportQueryFieldCreate({
            customreportpage_id: thispage,
        });
    };

    addReturnFields = () => {
        const { ormCustomReportTableFieldCreate } = this.props;
        const { thispage } = this.state;
        ormCustomReportTableFieldCreate({
            customreportpage_id: thispage,
        });
    };
    reportDefaultValues = () => {
        // const { reports } = this.props;
        var obj = {};
        //assign null on load, so user selects a date
        obj["start_date"] = "";
        obj["end_date"] = "";
        // if (reports && reports[0] && reports[0].id === 9) obj["user"] = "";
        return obj;
    };
    runErrorValidator = (values) => {
        // const { reports } = this.props;
        var obj = {};
        if (values.start_date === "") {
            obj["start_date"] = "Required";
        } else if (values.end_date === "") {
            obj["end_date"] = "Required";
        }
        // else if (reports[0].id === 9 && values.user === "") {
        //     obj["user"] = "Required";
        // }
        return obj;
    };
    runErrorValidatorRiley = (values) => {
        var obj = {};
        if (values.board_meeting === "") {
            obj["board_meeting"] = "Required";
        }
        return obj;
    };
    runReportRiley = (values) => {
        window.open("/reports/rpbcwd/regulatory?date=" + values.board_meeting);
        this.setState({ rileyReportOpen: false });
    };
    runReportRcwd = (values) => {
        const { report } = this.state;
        const { authState } = this.props;
        if (report === 15) {
            window.open("/reports/rcwd/wca_reports/" + values.year + "/" + values.report_type);
        }
        if (report === 18) {
            window.open("/reports/rcwd/surety_register/" + values.year);
        }
        if (report === 19) {
            window.open("/reports/rcwd/permit_status/" + values.year + "/" + values.export_type);
        }
        if (report === 20) {
            window.open("/reports/rcwd/rfile_status/" + values.year + "/" + values.export_type);
        }
        if (report === 21) {
            window.open("/reports/rcwd/annual_inspection_report/" + values.year);
        }
        if (report === 26) {
            window.open("/reports/crwd/permits_by_year/" + values.year + "/" + values.city + "/" + values.subwatershed);
        }
        if (report === 33) {
            window.open("/reports/rcwd/surety_export/" + values.year);
        }
        if (report === 32) {
            window.open("/reports/shrwd/summary_report/" + values.year + "/" + values.project);
        }
        if (report === 34) {
            window.open("/reports/climate/usda/" + values.year + "/" + values.period + "/" + values.module);
        }
        if (report === 38) {
            window.open("/reports/edge/usda/" + values.year + "/" + values.period + "/" + values.module);
        }
        if (report === 41) {
            window.open("/reports/rpbcwd/summary");
        }
        if (report === 35) {
            this.setState({ uploading: true, uploading_icon: true, results: null });
            fetch("/reports/climate/usda_upload", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Token " + authState.user.auth_token
                },
                body: JSON.stringify(values)
            }).then(e => {
                if (e.status === 500) {
                    this.setState({uploading_icon: false, results: "There has been an error uploading."});
                } else {

                    e.blob().then(blob => {
                        var url = window.URL.createObjectURL(blob);
                        var a = document.createElement("a");
                        a.href = url;
                        a.download = "Import Results.xlsx";
                        document.body.appendChild(a);
                        a.click();
                        a.remove();
                        this.setState({uploading: false, uploading_icon: false, results: null});
                    });
                }
            });
        }
        if (report === 39) {
            this.setState({ uploading: true, uploading_icon: true, results: null });
            fetch("/reports/edge/usda_upload", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Token " + authState.user.auth_token
                },
                body: JSON.stringify(values)
            }).then(e => {
                if (e.status === 500) {
                    this.setState({uploading_icon: false, results: "There has been an error uploading."});
                } else if (e.status === 400) {
                    e.json().then(json => 
                        this.setState({uploading_icon: false, results: json.message})
                    )
                } else {
                    e.blob().then(blob => {
                        var url = window.URL.createObjectURL(blob);
                        var a = document.createElement("a");
                        a.href = url;
                        a.download = "Import Results.xlsx";
                        document.body.appendChild(a);
                        a.click();
                        a.remove();
                        this.setState({uploading: false, uploading_icon: false, results: null});
                    });
                }
            });
        }
        if (report === 40) {
            this.setState({ uploading: true, uploading_icon: true, results: null });
            fetch("/reports/edge/usda_upload_pre", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Token " + authState.user.auth_token
                },
                body: JSON.stringify(values)
            }).then(e => {
                if (e.status === 500) {
                    this.setState({uploading_icon: false, results: "There has been an error uploading."});
                } else {
                    this.setState({uploading: false, uploading_icon: false, results: null});
                }
            });
        }
        this.setState({ rcwdReportOpen: false });
    };
    runReport = (values) => {
        const { thisCustomReport, authState, reports, ormCustomReportQueryFieldUpdate, ormCustomReportRecordUpdate } = this.props;

        //Determine which report to update its query values with dates these are 'custom' to each report
        //# 2 is construction sites on orange county search params to update on DEV
        if (reports[0].id === 2) {
            reports.forEach((r) => {
                //update query dates
                ormCustomReportRecordUpdate({
                    id: r.id,
                    start_date: values.start_date,
                    end_date: values.end_date,
                });
                return r.customreportpage.forEach((p) => {
                    //2 = dev, 4 = prod
                    if (p.id === 2 || p.id === 4) {
                        //total sites search
                        ormCustomReportQueryFieldUpdate({
                            id: p.customreportqueryfields[0].id,
                            query_text: values.start_date,
                            query_text2: values.end_date,
                        });
                    } else if (p.id === 6) {
                        //acres search
                        return p.customreportqueryfields.forEach((qf) => {
                            ormCustomReportQueryFieldUpdate({
                                id: qf.id,
                                query_text: values.start_date,
                                query_text2: values.end_date,
                            });
                        });
                    } else if (p.id === 11 || p.id === 12) {
                        //active sites search, 11 = dev, 12 = prod
                        return p.customreportqueryfields.forEach((qf) => {
                            if (qf.field_id === 28) {
                                //date record created 'is before'
                                ormCustomReportQueryFieldUpdate({
                                    id: qf.id,
                                    field_id: qf.field_id,
                                    query_text: values.end_date,
                                    query_text2: null,
                                });
                            } else if (qf.field_id === 27) {
                                //date record closed #27 -'is after'
                                ormCustomReportQueryFieldUpdate({
                                    id: qf.id,
                                    field_id: qf.field_id,
                                    query_text: values.start_date,
                                    query_text2: null,
                                });
                            }
                        });
                    } else if (p.id === 13) {
                        //ongoing sites search
                        return p.customreportqueryfields.forEach((qf) => {
                            if (qf.field_id === 28 && qf.filter_type !== "Is Null") {
                                //date record created
                                ormCustomReportQueryFieldUpdate({
                                    id: qf.id,
                                    field_id: qf.field_id,
                                    query_text: values.start_date,
                                    query_text2: null,
                                });
                            } else if (qf.field_id === 27 && qf.filter_type !== "Is Null") {
                                //date record closed #27 - is after
                                ormCustomReportQueryFieldUpdate({
                                    id: qf.id,
                                    field_id: qf.field_id,
                                    query_text: values.end_date,
                                    query_text2: null,
                                });
                            } else {
                                ormCustomReportQueryFieldUpdate({
                                    id: qf.id,
                                    field_id: qf.field_id,
                                    query_text: null,
                                    query_text2: null,
                                });
                            }
                        });
                    }
                });
            });
        } else if (reports[0].id === 4) {
            //Annual Reports Orange County
            reports.forEach((r) => {
                //update query dates
                ormCustomReportRecordUpdate({
                    id: r.id,
                    start_date: values.start_date,
                    end_date: values.end_date,
                });
                return r.customreportpage.forEach((p) => {
                    //all pages use date query fields that dont change
                    //total Inspections search
                    ormCustomReportQueryFieldUpdate({
                        id: p.customreportqueryfields[0].id,
                        query_text: values.start_date,
                        query_text2: values.end_date,
                    });
                });
            });
        } else if (reports[0].id === 5) {
            //CGP Reports Orange County
            reports.forEach((r) => {
                //update query dates
                ormCustomReportRecordUpdate({
                    id: r.id,
                    start_date: values.start_date,
                    end_date: values.end_date,
                });
                return r.customreportpage.forEach((p) => {
                    //dev totalsites = 24, closed file total = 25
                    //prod totalsites = 19, closedfile total = 20
                    if (p.id === 24 || p.id === 25 || p.id === 19 || p.id === 20) {
                        ormCustomReportQueryFieldUpdate({
                            id: p.customreportqueryfields[0].id,
                            query_text: values.start_date,
                            query_text2: values.end_date,
                        });
                    } else if (p.id === 26 || p.id === 21) {
                        //dev total sites with cgp = 26
                        //prod = 21
                        return p.customreportqueryfields.forEach((qf) => {
                            if (qf.id === 24 || qf.id === 22) {
                                //date record was created dev = 24
                                //prod = 22
                                ormCustomReportQueryFieldUpdate({
                                    id: qf.id,
                                    field_id: qf.field_id,
                                    query_text: values.start_date,
                                    query_text2: values.end_date,
                                });
                            }
                        });
                    } else if (p.id === 27 || p.id === 22) {
                        //openfile total dev = 27, prod =22
                        return p.customreportqueryfields.forEach((qf) => {
                            //date field closed dev = 26, prod = 24
                            if (qf.id === 26 || qf.id === 24) {
                                //date file closed--subtract a day
                                let temp = values.end_date.split("/");
                                let subdate = sub(new Date(temp[2], temp[0] - 1, temp[1]), { days: 1 });
                                ormCustomReportQueryFieldUpdate({
                                    id: qf.id,
                                    field_id: qf.field_id,
                                    query_text: subdate.toLocaleDateString(),
                                });
                            } else if (qf.id === 25 || qf.id === 23) {
                                //date record was created dev = 25, prod = 23
                                ormCustomReportQueryFieldUpdate({
                                    id: qf.id,
                                    field_id: qf.field_id,
                                    query_text: values.start_date,
                                    query_text2: values.end_date,
                                });
                            }
                        });
                    }
                });
            });
        } else if (reports[0].id === 6) {
            //ERP Reports Orange County
            reports.forEach((r) => {
                //update query dates
                ormCustomReportRecordUpdate({
                    id: r.id,
                    start_date: values.start_date,
                    end_date: values.end_date,
                });
                return r.customreportpage.forEach((p) => {
                    //dev totalsites = 28, closed file total = 29
                    //prod totalsites = 23, closed = 24
                    if (p.id === 28 || p.id === 29 || p.id === 23 || p.id === 24) {
                        ormCustomReportQueryFieldUpdate({
                            id: p.customreportqueryfields[0].id,
                            query_text: values.start_date,
                            query_text2: values.end_date,
                        });
                    } else if (p.id === 30 || p.id === 25) {
                        //dev total sites with erp = 30
                        //prod = 25
                        return p.customreportqueryfields.forEach((qf) => {
                            if (qf.id === 30 || qf.id === 28) {
                                //date record was created dev = 30
                                //prod = 28
                                ormCustomReportQueryFieldUpdate({
                                    id: qf.id,
                                    field_id: qf.field_id,
                                    query_text: values.start_date,
                                    query_text2: values.end_date,
                                });
                            }
                        });
                    } else if (p.id === 31 || p.id === 26) {
                        //openfile total dev = 31, prod = 26
                        return p.customreportqueryfields.forEach((qf) => {
                            //date field closed dev = 32, prod = 30
                            if (qf.id === 32 || qf.id === 30) {
                                //date file closed--subtract a day
                                let temp = values.end_date.split("/");
                                let subdate = sub(new Date(temp[2], temp[0] - 1, temp[1]), { days: 1 });
                                ormCustomReportQueryFieldUpdate({
                                    id: qf.id,
                                    field_id: qf.field_id,
                                    query_text: subdate.toLocaleDateString(),
                                });
                            } else if (qf.id === 31 || qf.id === 29) {
                                //date record was created dev = 31, prod = 29
                                ormCustomReportQueryFieldUpdate({
                                    id: qf.id,
                                    field_id: qf.field_id,
                                    query_text: values.start_date,
                                    query_text2: values.end_date,
                                });
                            }
                        });
                    }
                });
            });
        } else if (reports[0].id === 7) {
            //Construction Summary ReportOrange County
            reports.forEach((r) => {
                //update query dates
                ormCustomReportRecordUpdate({
                    id: r.id,
                    start_date: values.start_date,
                    end_date: values.end_date,
                });
                return r.customreportpage.forEach((p) => {
                    //all searches other than 'active sites' search dev = 38, prod = 33
                    if (p.id !== 38 && p.id !== 33) {
                        ormCustomReportQueryFieldUpdate({
                            id: p.customreportqueryfields[0].id,
                            query_text: values.start_date,
                            query_text2: values.end_date,
                        });
                    } else {
                        //active sites search
                        return p.customreportqueryfields.forEach((qf) => {
                            //date record was created dev = 40 , prod = 37
                            // if (qf.id === 40 || qf.id === 37) {
                            ormCustomReportQueryFieldUpdate({
                                id: qf.id,
                                field_id: qf.field_id,
                                query_text: values.end_date,
                            });
                            // } else if (qf.id === 41 || qf.id === 38) {
                            //     //date field closed dev = 41, prod = 38
                            //     ormCustomReportQueryFieldUpdate({
                            //         id: qf.id,
                            //         field_id: qf.field_id,
                            //         query_text: values.end_date,
                            //     });
                            // }
                        });
                    }
                });
            });
        } else if (reports[0].id === 8) {
            //Construction Violation Report Orange County
            reports.forEach((r) => {
                //update query dates
                ormCustomReportRecordUpdate({
                    id: r.id,
                    start_date: values.start_date,
                    end_date: values.end_date,
                });
                return r.customreportpage.forEach((p) => {
                    //all searches use the same date

                    ormCustomReportQueryFieldUpdate({
                        id: p.customreportqueryfields[0].id,
                        query_text: values.start_date,
                        query_text2: values.end_date,
                    });
                });
            });
        } else if (reports[0].id === 9 || reports[0].id === 22) {
            //Staff Summary Report Orange County //prod is 22
            reports.forEach((r) => {
                //update query dates
                ormCustomReportRecordUpdate({
                    id: r.id,
                    start_date: values.start_date,
                    end_date: values.end_date,
                    user: values.user,
                });
                return r.customreportpage.forEach((p) => {
                    //all searches use the same date (for construction total insp/percent, ind total insp/percent)
                    // if (p.id === 50 || p.id === 51 || p.id === 54 || p.id === 55 p) {
                    return p.customreportqueryfields.forEach((qf) => {
                        //update 'user' for searchs DEV (42 = construction, 11502 = industrial) 11614 = ind fw, 11563 = ind sampling, ponds 10800 & 10860
                        //prod has same field ids
                        if (
                            qf.field_id === 42 ||
                            qf.field_id === 11502 ||
                            qf.field_id === 180 ||
                            qf.field_id === 89 ||
                            qf.field_id === 11614 ||
                            qf.field_id === 11563 ||
                            qf.field_id === 10800 ||
                            qf.field_id === 10860
                        ) {
                            ormCustomReportQueryFieldUpdate({
                                id: qf.id,
                                field_id: qf.field_id,
                                query_text: values.user,
                            });
                        } else {
                            //normal date update for all other searches as date is the first field of each search
                            //will double send this field update 3 times but has no affect on view timing, etc. Left it this way to save breaking out into all different senarios (ie more code)
                            ormCustomReportQueryFieldUpdate({
                                id: p.customreportqueryfields[0].id,
                                query_text: values.start_date,
                                query_text2: values.end_date,
                            });
                        }
                    });
                });
            });
        } else if (reports[0].id === 10 || reports[0].id === 23) {
            //Pond Summary Report Orange County
            reports.forEach((r) => {
                //update query dates
                ormCustomReportRecordUpdate({
                    id: r.id,
                    start_date: values.start_date,
                    end_date: values.end_date,
                });
                return r.customreportpage.forEach((p) => {
                    return p.customreportqueryfields.forEach((qf) => {
                        //normal date update
                        ormCustomReportQueryFieldUpdate({
                            id: p.customreportqueryfields[0].id,
                            query_text: values.start_date,
                            query_text2: values.end_date,
                        });
                    });
                });
            });
        } else if (reports[0].id === 11 || reports[0].id === 24) {
            //Pond violation Report Orange County
            reports.forEach((r) => {
                //update query dates
                ormCustomReportRecordUpdate({
                    id: r.id,
                    start_date: values.start_date,
                    end_date: values.end_date,
                });
                return r.customreportpage.forEach((p) => {
                    return p.customreportqueryfields.forEach((qf) => {
                        //search date update
                        ormCustomReportQueryFieldUpdate({
                            id: p.customreportqueryfields[0].id,
                            query_text: values.start_date,
                            query_text2: values.end_date,
                        });
                    });
                });
            });
        } else if (reports[0].id === 25 || reports[0].id === 27) {
            //prod 27
            //new to add
            //Ind violation Report Orange County
            reports.forEach((r) => {
                //update query dates
                ormCustomReportRecordUpdate({
                    id: r.id,
                    start_date: values.start_date,
                    end_date: values.end_date,
                });
                return r.customreportpage.forEach((p) => {
                    return p.customreportqueryfields.forEach((qf) => {
                        //search date update
                        ormCustomReportQueryFieldUpdate({
                            id: p.customreportqueryfields[0].id,
                            query_text: values.start_date,
                            query_text2: values.end_date,
                        });
                    });
                });
            });
        }

        //NOTE==>update query field IT NEEDS TO BE IN THE FIRST position as a date to use the search function properly
        // ormCustomReportQueryFieldUpdate({ id: queryfieldid, query_text: values.start_date, query_text2: values.end_date });
        // ormCustomReportRecordUpdate({ id: thisCustomReport.id, ...values });

        //get the report
        setTimeout(() => {
            window.open("/custom/report/" + authState.user.city_id + "/" + thisCustomReport.id);
        }, 300);

        this.setState({ customReportOpen: false, creatingReport: true });
        setTimeout(() => {
            this.setState({ creatingReport: false });
        }, 5000);
    };

    getUserFields = () => {
        const { fields } = this.props;
        const { report } = this.state;
        if (report === 9 || report === 22) {
            //we allow the staff user field to be populated
            let thisField = fields.find((f) => (f.id === 42 ? f : null));

            return thisField.values_csv.split(",").map((row) => ({
                label: row,
                value: row,
            }));
        }
    };

    render() {
        const {
            classes,
            pages,
            ormCustomReportRecordCreate,
            ormCustomReportPageCreate,
            ormCustomReportPageDelete,
            ormCustomReportQueryFieldUpdate,
            ormCustomReportRecordDelete,
            ormCustomReportTableFieldUpdate,
            authState,
            thisCustomReport,
            reportwithtablefields,
            customReportWPages,
            ormCustomReportPageUpdate,
            ormCustomReportQueryFieldDelete,
            ormCustomReportTableFieldDelete,
        } = this.props;

        var customReports = this.props.customReports.sort((a,b) => a.report_name.localeCompare(b.report_name))

        const { orderBy, order, reports } = this.props;
        const {
            dialogOpen,
            customReportOpen,
            rcwdReportOpen,
            rileyReportOpen,
            openPageField,
            thispage,
            createNew,
            creatingReport,
            showReportRecord,
            showCreateCustomReport,
            deleteDialogOpen,
            deletePageName,
            deleteSearchDialogOpen,
            report,
            uploading,
            uploading_icon,
            results,
        } = this.state;

        var columnData = [];
        if (authState && authState.user && authState.user.is_superuser) {
            //superuser columns with edit/delete
            columnData = [
                { id: "actions", label: "", allowSort: false, width: 5 },
                { id: "actions", label: "", allowSort: false, width: 5 },
                { id: "view", numeric: false, label: "View", allowSort: false, width: 20 },
                { id: "report_name", numeric: false, label: "Custom Report Name", allowSort: true },
                { id: "name", numeric: false, label: "Module", allowSort: true },
            ];
        } else {
            // user view columns
            columnData = [
                { id: "view", numeric: false, label: "View", allowSort: false, width: 40 },
                { id: "report_name", numeric: false, label: "Custom Report Name", allowSort: false },
                { id: "name", numeric: false, label: "Module", allowSort: false },
            ];
        }
        //custom report pages
        const columns = [
            { id: "actions", label: "", allowSort: false },
            { id: "searchname", label: "Search Name" },
            { id: "name", label: "Page Name" },
        ];

        var current_year = new Date().getFullYear();
        var year_arr = [];
        while (current_year > new Date().getFullYear() - 33) {
            year_arr.push(current_year.toString());
            current_year = current_year - 1;
        }

        return (
            <AppContainer authenticated>
                <>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant="h6">Custom Report List</Typography>
                            <Typography>
                                This module shows a list of custom reports that have been configured in your account. Custom Reports are setup and
                                configured by the software vendor and are considered a custom module. Changes to any module form fields need to be
                                made by the vendor. Please contact the vendor for any changes.
                            </Typography>
                        </Grid>{" "}
                        {authState && authState.user && authState.user.is_superuser && (
                            <Grid item xs={12}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    className={classes.button}
                                    fullWidth
                                    onClick={() => {
                                        ormCustomReportRecordCreate({
                                            city_id: authState.user.city_id, //get just section for FK creation
                                        }).then((r) => {
                                            // show on form after creation
                                            this.props.history.push("/customreports/" + r.id);
                                            this.setState({
                                                dialogOpen: true,
                                                customRecordId: r.id,
                                                showReportRecord: false,
                                                showCreateCustomReport: true,
                                            });
                                        });
                                    }}>
                                    <AddIcon className={classes.iconAdd} />
                                    &nbsp;&nbsp;&nbsp; Configure New Report
                                </Button>
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <Table className={classes.table}>
                                <EnhancedTableHead columnData={columnData} order={order} orderBy={orderBy} onRequestSort={this.handleRequestSort} />
                                <TableBody>
                                    {customReports.map((n) => {
                                        var thisReport = customReportWPages.find((cr) => cr.id === n.id);
                                        var module = "";

                                        //get the module name for the table
                                        if (thisReport.customreportpage.length > 1) {
                                            let temp = [];
                                            //first filter out all the searches so we only should module name once
                                            thisReport.customreportpage.forEach((r, i) => {
                                                if (!temp.find((f) => (f === r.name ? f : null))) {
                                                    temp.push(r.name);
                                                }
                                            });
                                            // has more than one module so add comma
                                            temp.forEach((p, i) => (p !== undefined ? (module += p + (i + 1 < temp.length ? ", " : "")) : null));
                                        } else if (thisReport.customreportpage.length > 0) {
                                            //only 1 page
                                            module = thisReport.customreportpage[0].name;
                                        }

                                        return (
                                            <TableRow hover>
                                                {authState && authState.user && authState.user.is_superuser && (
                                                    <>
                                                        <CustomTableCell className={classes.nowrap}>
                                                            <Tooltip title="Edit">
                                                                <Button
                                                                    color="primary"
                                                                    component={Link}
                                                                    to={"/customreports/" + n.id}
                                                                    onClick={() => {
                                                                        //if a page was set we will set states
                                                                        if (reports.find((r) => (r.customreportpage.length > 0 ? r : null))) {
                                                                            this.setState({
                                                                                dialogOpen: true,
                                                                                // showCreateCustomReport: false,
                                                                                // showReportRecord: true
                                                                            });
                                                                        } else {
                                                                            this.setState({
                                                                                dialogOpen: true,
                                                                                showCreateCustomReport: false,
                                                                                showReportRecord: true,
                                                                            });
                                                                        }
                                                                    }}
                                                                    className={classes.deleteWidth}>
                                                                    <EditIcon color="primary" />
                                                                </Button>
                                                            </Tooltip>
                                                        </CustomTableCell>
                                                        <CustomTableCell className={classes.nowrap}>
                                                            <Tooltip title="Delete">
                                                                <Button
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            deleteDialogOpen: true,
                                                                            deleteid: n.id,
                                                                            deletePageName: n.report_name,
                                                                        });
                                                                    }}
                                                                    className={classes.deleteWidth}>
                                                                    <DeleteIcon color="inherit" />
                                                                </Button>
                                                            </Tooltip>
                                                        </CustomTableCell>
                                                    </>
                                                )}
                                                {n.city_id === 26 || n.city_id === 10 || n.city_id === 44 || n.city_id === 12 || n.city_id === 62 || n.city_id === 56 || n.city_id === 58 || n.city_id === 67 || n.city_id === 70 ? (
                                                    <CustomTableCell className={classes.nowrap}>
                                                        <Tooltip title="View">
                                                            <Button
                                                                color="primary"
                                                                onClick={() => {
                                                                    if (n.id === 13) window.open("/reports/rcwd/activity_summary");
                                                                    else if (n.id === 14) window.open("/reports/rcwd/volume_banking");
                                                                    else if (n.id === 16) window.open("/reports/rcwd/regional_compliance");
                                                                    else if (n.id === 17) window.open("/reports/rcwd/permits_with_modifications");
                                                                    else if (n.id === 28) window.open("/reports/rcwd/permits_open_surety");
                                                                    else if (n.id === 29) window.open("/reports/esmc/project_report");
                                                                    else if (n.id === 30) window.open("/reports/rpbcwd/tp_tss_report");
                                                                    else if (n.id === 36) window.open("/reports/rpbcwd/permits_last_inspected");
                                                                    else if (n.id === 37) window.open("/reports/ccid/status_log");
                                                                    else if (n.id === 31) this.setState({ rileyReportOpen: true, report: n.id });
                                                                    else this.setState({ rcwdReportOpen: true, report: n.id });
                                                                }}
                                                                className={classes.deleteWidth}>
                                                                <ViewIcon color="primary" />
                                                            </Button>
                                                        </Tooltip>
                                                    </CustomTableCell>
                                                ) : (
                                                    <CustomTableCell className={classes.nowrap}>
                                                        <Tooltip title="View">
                                                            <Button
                                                                color="primary"
                                                                component={Link}
                                                                to={"/customreports/" + n.id}
                                                                onClick={() => this.setState({ customReportOpen: true, report: n.id })}
                                                                className={classes.deleteWidth}>
                                                                <ViewIcon color="primary" />
                                                            </Button>
                                                        </Tooltip>
                                                    </CustomTableCell>
                                                )}
                                                <CustomTableCell>{n.report_name}</CustomTableCell>
                                                <CustomTableCell>{module}</CustomTableCell>
                                            </TableRow>
                                        );
                                    })}
                                    {customReports.length < 1 && (
                                        <TableRow>
                                            <CustomTableCell colSpan={columnData.length} className={classes.centerAlign}>
                                                No Custom Reports Found - Contact MS4Front Support to Configure
                                            </CustomTableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </Grid>
                    </Grid>
                    <Dialog open={dialogOpen} classes={{ paper: classes.buildReport }}>
                        <Toolbar style={{ backgroundColor: "#d3d3d3" }}>
                            <Typography variant="h5" className={classes.flex} style={{ marginLeft: "7%" }}>
                                Build Database Params for a Custom Report
                            </Typography>

                            <IconButton component={Link} to={"/customreports/"} onClick={() => this.setState({ dialogOpen: false, totalFields: [] })}>
                                <Close />
                            </IconButton>
                        </Toolbar>
                        <DialogContent className={classes.dialogMin}>
                            <DialogContentText>
                                <Formm
                                    getApi={(el) => (this.formApi = el)}
                                    defaultValues={this.defaultCustomReportValues()}
                                    onSubmit={(values) => this.updateCustomReport(values)}>
                                    {(formApi) => (
                                        <form onSubmit={formApi.submitForm}>
                                            <Grid container spacing={1} alignItems="center" style={{ marginBottom: -15 }}>
                                                <Grid item xs={12}>
                                                    <TextField key={"report_name"} field={"report_name"} label={"Custom Report Name"} fullWidth />
                                                </Grid>

                                                {/* <Grid
                                                    item
                                                    xs={6}
                                                    style={{
                                                        marginTop: "4%",
                                                        marginRight: "-7%"
                                                    }}>
                                                    <DatePicker key={"start_date"} field={"start_date"} label="Start Date" autoPopulate={true} />
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={6}
                                                    style={{
                                                        marginTop: "4%",
                                                        marginLeft: "6%"
                                                    }}>
                                                    <DatePicker key={"end_date"} field={"end_date"} autoPopulate={true} label="End Date" />
                                                </Grid> */}
                                                {showCreateCustomReport && (
                                                    <Grid item xs={12}>
                                                        <Button
                                                            type="submit"
                                                            variant="contained"
                                                            color="primary"
                                                            className={classes.button}
                                                            style={{ marginTop: 20 }}
                                                            fullWidth>
                                                            <Save />
                                                            &nbsp;&nbsp;&nbsp; Create Custom Report Record
                                                        </Button>
                                                    </Grid>
                                                )}
                                                {showReportRecord && (
                                                    <>
                                                        <Button
                                                            fullWidth
                                                            variant="contained"
                                                            color="primary"
                                                            onClick={(e) => {
                                                                this.setState({
                                                                    openPageField: true,
                                                                    createNew: true,
                                                                    showReportRecord: false,
                                                                    thispage: null,
                                                                });
                                                            }}
                                                            style={{
                                                                minWidth: 596,
                                                                marginBottom: 18,
                                                                marginTop: 17,
                                                            }}>
                                                            <AddIcon className={classes.iconAdd} />
                                                            &nbsp;&nbsp;&nbsp;Add New Search to Report
                                                        </Button>

                                                        <Paper className={classes.root1}>
                                                            <Table className={classes.table}>
                                                                <EnhancedTableHead
                                                                    // key={key}
                                                                    // order={order}
                                                                    // orderBy={orderBy}
                                                                    // onRequestSort={this.handleRequestSort}
                                                                    columnData={columns}
                                                                />
                                                                <TableBody>
                                                                    {(() => {
                                                                        return reports.map((t) => {
                                                                            return t.customreportpage.map((n) => {
                                                                                // return n.customreportfields.map(f => {

                                                                                if (!n) {
                                                                                    return (
                                                                                        <TableRow>
                                                                                            <CustomTableCell
                                                                                                colSpan={columns.length}
                                                                                                className={classes.centerAlign}>
                                                                                                No Page Fields Created
                                                                                            </CustomTableCell>
                                                                                        </TableRow>
                                                                                    );
                                                                                } else {
                                                                                    return (
                                                                                        <TableRow hover>
                                                                                            {window.innerWidth >= 960 && (
                                                                                                <CustomTableCell className={classes.nowrap}>
                                                                                                    <Tooltip title="View Page Fields">
                                                                                                        <Button
                                                                                                            onClick={() => {
                                                                                                                this.setState({
                                                                                                                    openPageField: true,
                                                                                                                    thispage: n.id,
                                                                                                                    createNew: false,
                                                                                                                    showReportRecord: false,
                                                                                                                });
                                                                                                            }}
                                                                                                            className={classes.deleteWidth}>
                                                                                                            <EditIcon color="primary" />
                                                                                                        </Button>
                                                                                                    </Tooltip>

                                                                                                    <Tooltip title="Delete Page">
                                                                                                        <Button
                                                                                                            onClick={() =>
                                                                                                                this.setState({
                                                                                                                    deleteSearchDialogOpen: true,
                                                                                                                    deleteid: n.id,
                                                                                                                })
                                                                                                            }
                                                                                                            className={classes.deleteWidth}>
                                                                                                            <DeleteIcon color="inherit" />
                                                                                                        </Button>
                                                                                                    </Tooltip>
                                                                                                </CustomTableCell>
                                                                                            )}
                                                                                            {/* PRINTING OF THE DATA IN THE TABLE */}
                                                                                            <CustomTableCell key={n.id}>
                                                                                                {n.search_name}
                                                                                            </CustomTableCell>
                                                                                            <CustomTableCell key={n.id}>{n.name}</CustomTableCell>
                                                                                        </TableRow>
                                                                                    );
                                                                                }
                                                                                // });
                                                                            });
                                                                        });
                                                                    })()}
                                                                </TableBody>
                                                            </Table>
                                                        </Paper>
                                                    </>
                                                )}
                                            </Grid>
                                        </form>
                                    )}
                                </Formm>
                            </DialogContentText>
                        </DialogContent>
                    </Dialog>
                    <Dialog open={openPageField} classes={{ paper: classes.pageFields }}>
                        <Toolbar style={{ backgroundColor: "#d3d3d3" }}>
                            <Typography variant="h5" className={classes.flex} style={{ marginLeft: "7%" }}>
                                Add a Page and Select Query Fields Fields
                            </Typography>

                            <IconButton onClick={() => this.setState({ openPageField: false, showReportRecord: true })}>
                                <Close />
                            </IconButton>
                        </Toolbar>
                        <DialogContent className={classes.dialogMin}>
                            <DialogContentText>
                                <Formm
                                    getApi={(el) => (this.formApi = el)}
                                    defaultValues={this.pageDefaultValues()}
                                    onSubmit={(values) => this.createPageQueryFields(values)}>
                                    {(formApi) => (
                                        <form onSubmit={formApi.submitForm}>
                                            <Grid container spacing={1} alignItems="center" style={{ marginBottom: -15 }}>
                                                {((reports && reports.length > 0 && reports[0].customreportpage.length === 0) ||
                                                    createNew === true) && (
                                                    <>
                                                        <Select
                                                            field={"page_id"}
                                                            label={<HelpLabel inputLabel="Select a Page" />}
                                                            options={this.MAKE_OPTIONS(pages)}
                                                            eventHandle={(v) => {
                                                                let thispage = pages.find((p) => p.id === v);
                                                                ormCustomReportPageCreate({
                                                                    customreportrecord_id: thisCustomReport.id,
                                                                    page_id: v,
                                                                    name: thispage.name,
                                                                }).then((p) => this.setState({ thispage: p.id, createNew: false }));
                                                            }}
                                                            fullWidth
                                                        />
                                                    </>
                                                )}

                                                <Grid container spacing={1}>
                                                    {reports &&
                                                        reports.length > 0 &&
                                                        reports[0].customreportpage.length > 0 &&
                                                        createNew === false &&
                                                        reports[0].customreportpage.map((cr, k) => {
                                                            if (thispage === cr.id) {
                                                                //get the actual page & fields
                                                                return (
                                                                    <>
                                                                        <Select
                                                                            field={"page_id"}
                                                                            label={"Select a Page"}
                                                                            options={this.MAKE_OPTIONS(pages)}
                                                                            eventHandle={(v) => {
                                                                                let thispage = pages.find((p) => p.id === v);
                                                                                ormCustomReportPageCreate({
                                                                                    customreportrecord_id: thisCustomReport.id,
                                                                                    page_id: v,
                                                                                    name: thispage.name,
                                                                                }).then((p) => this.setState({ thispage: p.id }));
                                                                            }}
                                                                            fullWidth
                                                                        />
                                                                        <Grid item xs={6}>
                                                                            <TextField
                                                                                // key={i + }
                                                                                field={"search_name"}
                                                                                label={"Search Name"}
                                                                                fullWidth
                                                                                eventHandle={(value) => {
                                                                                    setTimeout(() => {
                                                                                        ormCustomReportPageUpdate({
                                                                                            customreportrecord_id: thisCustomReport.id,
                                                                                            id: thispage,
                                                                                            search_name: value,
                                                                                        });
                                                                                    }, 1000);
                                                                                }}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={6}>
                                                                            <Select
                                                                                field={"and_or"}
                                                                                label={"Select AND or OR for Search"}
                                                                                options={this.OPTIONS(["AND", "OR"])}
                                                                                eventHandle={(v) => {
                                                                                    ormCustomReportPageUpdate({
                                                                                        customreportrecord_id: thisCustomReport.id,
                                                                                        id: thispage,

                                                                                        and_or: v,
                                                                                    });
                                                                                }}
                                                                                fullWidth
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={12}>
                                                                            <Typography
                                                                                className={classes.flex}
                                                                                style={{
                                                                                    fontSize: 16,
                                                                                    color: "red",
                                                                                    textAlign: "center",
                                                                                    textDecoration: "underline",
                                                                                }}>
                                                                                First Field Needs to be a Date Field with search parameters, section,
                                                                                etc. to Work Properly
                                                                            </Typography>
                                                                        </Grid>
                                                                        {cr.customreportqueryfields.map((tf, i) => (
                                                                            <>
                                                                                <Grid item xs={1}>
                                                                                    <Tooltip title="Permanently Delete Query Field?">
                                                                                        <IconButton
                                                                                            className={classes.delete}
                                                                                            onClick={(e) => {
                                                                                                ormCustomReportQueryFieldDelete(tf.id);
                                                                                            }}>
                                                                                            <DeleteIcon
                                                                                                className={classes.deleteIcon}
                                                                                                style={{
                                                                                                    color: "#333333",
                                                                                                    width: "1.3em",
                                                                                                    height: "1.3em",
                                                                                                    marginTop: 6,
                                                                                                }}
                                                                                            />
                                                                                        </IconButton>
                                                                                    </Tooltip>
                                                                                </Grid>

                                                                                <Grid item xs={2}>
                                                                                    <TextField
                                                                                        // key={i + }
                                                                                        field={"field_id" + tf.id}
                                                                                        label={"Field ID (numerical only)"}
                                                                                        fullWidth
                                                                                        eventHandle={(value) => {
                                                                                            setTimeout(() => {
                                                                                                ormCustomReportQueryFieldUpdate({
                                                                                                    id: tf.id,
                                                                                                    field_id: value,
                                                                                                });
                                                                                            }, 1000);
                                                                                        }}
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={2}>
                                                                                    <TextField
                                                                                        // key={i}
                                                                                        field={"field_name" + tf.id}
                                                                                        label={"Field Name"}
                                                                                        fullWidth
                                                                                        eventHandle={(value) => {
                                                                                            setTimeout(() => {
                                                                                                ormCustomReportQueryFieldUpdate({
                                                                                                    id: tf.id,
                                                                                                    field_name: value,
                                                                                                });
                                                                                            }, 1000);
                                                                                        }}
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={2}>
                                                                                    <TextField
                                                                                        // key={i}
                                                                                        field={"filter_type" + tf.id}
                                                                                        label={"filter_type "}
                                                                                        fullWidth
                                                                                        eventHandle={(value) => {
                                                                                            setTimeout(() => {
                                                                                                ormCustomReportQueryFieldUpdate({
                                                                                                    id: tf.id,
                                                                                                    filter_type: value,
                                                                                                });
                                                                                            }, 1000);
                                                                                        }}
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={2}>
                                                                                    <TextField
                                                                                        // key={i}
                                                                                        field={"query_text" + tf.id}
                                                                                        label={"query_text "}
                                                                                        fullWidth
                                                                                        eventHandle={(value) => {
                                                                                            setTimeout(() => {
                                                                                                ormCustomReportQueryFieldUpdate({
                                                                                                    id: tf.id,
                                                                                                    query_text: value,
                                                                                                });
                                                                                            }, 1000);
                                                                                        }}
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={2}>
                                                                                    <TextField
                                                                                        // key={i}
                                                                                        field={"query_text2" + tf.id}
                                                                                        label={"query_text2 "}
                                                                                        fullWidth
                                                                                        eventHandle={(value) => {
                                                                                            setTimeout(() => {
                                                                                                ormCustomReportQueryFieldUpdate({
                                                                                                    id: tf.id,
                                                                                                    query_text2: value,
                                                                                                });
                                                                                            }, 1000);
                                                                                        }}
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={1}>
                                                                                    <TextField
                                                                                        // key={i}
                                                                                        field={"section_id" + tf.id}
                                                                                        label={"Section"}
                                                                                        fullWidth
                                                                                        eventHandle={(value) => {
                                                                                            setTimeout(() => {
                                                                                                ormCustomReportQueryFieldUpdate({
                                                                                                    id: tf.id,
                                                                                                    section_id: value,
                                                                                                });
                                                                                            }, 1000);
                                                                                        }}
                                                                                    />
                                                                                </Grid>
                                                                            </>
                                                                        ))}
                                                                    </>
                                                                );
                                                            } else return null;
                                                        })}
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Button
                                                        style={{ marginTop: 10 }}
                                                        variant="contained"
                                                        color="primary"
                                                        className={classes.button}
                                                        fullWidth
                                                        onClick={() => {
                                                            this.addNewFields();
                                                        }}>
                                                        <AddIcon className={classes.iconAdd} />
                                                        &nbsp;&nbsp;&nbsp; Add Query Fields
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </form>
                                    )}
                                </Formm>
                                <Formm
                                    getApi={(el) => (this.formApi = el)}
                                    defaultValues={this.pageTableValues()}
                                    onSubmit={(values) => this.createPageTableFields(values)}>
                                    {(formApi) => (
                                        <form onSubmit={formApi.submitForm}>
                                            <Grid container spacing={1} alignItems="center" style={{ marginBottom: -15 }}>
                                                <Grid item xs={12}>
                                                    <Button
                                                        style={{ marginTop: 10 }}
                                                        variant="contained"
                                                        color="primary"
                                                        className={classes.button}
                                                        fullWidth
                                                        onClick={() => {
                                                            this.addReturnFields();
                                                        }}>
                                                        <AddIcon className={classes.iconAdd} />
                                                        &nbsp;&nbsp;&nbsp; Add Return Fields
                                                    </Button>
                                                </Grid>
                                                <Grid container spacing={1}>
                                                    {reportwithtablefields &&
                                                        reportwithtablefields.length > 0 &&
                                                        reportwithtablefields[0].customreportpage.length > 0 &&
                                                        createNew === false &&
                                                        reportwithtablefields[0].customreportpage.map((cr, k) => {
                                                            if (thispage === cr.id) {
                                                                //get the actual page & fields
                                                                return (
                                                                    <>
                                                                        {cr.customreporttablefields.map((tf, i) => (
                                                                            <>
                                                                                <Grid item xs={1}>
                                                                                    <Tooltip title="Permanently Delete Return Field?">
                                                                                        <IconButton
                                                                                            className={classes.delete}
                                                                                            onClick={(e) => {
                                                                                                ormCustomReportTableFieldDelete(tf.id);
                                                                                            }}>
                                                                                            <DeleteIcon
                                                                                                className={classes.deleteIcon}
                                                                                                style={{
                                                                                                    color: "#333333",
                                                                                                    width: "1.3em",
                                                                                                    height: "1.3em",
                                                                                                    marginTop: 6,
                                                                                                }}
                                                                                            />
                                                                                        </IconButton>
                                                                                    </Tooltip>
                                                                                </Grid>
                                                                                <Grid item xs={5}>
                                                                                    <TextField
                                                                                        // key={i + }
                                                                                        field={"field_id" + tf.id}
                                                                                        label={"Field ID (numerical only)"}
                                                                                        fullWidth
                                                                                        eventHandle={(value) => {
                                                                                            setTimeout(() => {
                                                                                                ormCustomReportTableFieldUpdate({
                                                                                                    id: tf.id,
                                                                                                    field_id: value,
                                                                                                });
                                                                                            }, 1000);
                                                                                        }}
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={6}>
                                                                                    <TextField
                                                                                        // key={i}
                                                                                        field={"field_name" + tf.id}
                                                                                        label={"Field Name"}
                                                                                        fullWidth
                                                                                        eventHandle={(value) => {
                                                                                            setTimeout(() => {
                                                                                                ormCustomReportTableFieldUpdate({
                                                                                                    id: tf.id,
                                                                                                    field_name: value,
                                                                                                });
                                                                                            }, 1000);
                                                                                        }}
                                                                                    />
                                                                                </Grid>
                                                                            </>
                                                                        ))}
                                                                    </>
                                                                );
                                                            } else return null;
                                                        })}
                                                </Grid>
                                            </Grid>
                                        </form>
                                    )}
                                </Formm>
                            </DialogContentText>
                        </DialogContent>
                    </Dialog>
                    <Dialog open={customReportOpen} classes={{ paper: classes.reportGeneration }}>
                        <Toolbar style={{ backgroundColor: "#d3d3d3" }}>
                            <Typography variant="h5" className={classes.flex} style={{ marginLeft: "7%" }}>
                                Select Dates to Run Custom Report
                            </Typography>

                            <IconButton
                                style={{ marginLeft: "auto" }}
                                component={Link}
                                to={"/customreports/"}
                                onClick={() => this.setState({ customReportOpen: false })}>
                                <Close />
                            </IconButton>
                        </Toolbar>
                        <DialogContent className={classes.dialogMin}>
                            <DialogContentText>
                                <Formm
                                    getApi={(el) => (this.formApii = el)}
                                    dontValidateOnMount="true"
                                    validateOnSubmit="true"
                                    validateError={this.runErrorValidator}
                                    defaultValues={this.reportDefaultValues()}
                                    onSubmit={(values) => this.runReport(values)}>
                                    {(formApii) => (
                                        <form onSubmit={formApii.submitForm}>
                                            <Grid container spacing={1} alignItems="center" style={{ marginTop: -13, marginBottom: -15 }}>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    style={{
                                                        marginTop: "4%",
                                                        marginLeft: "1%",
                                                    }}>
                                                    <DatePicker fullWidth key={"start_date"} field={"start_date"} label="Start Date" />
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    style={{
                                                        marginTop: "4%",
                                                        marginLeft: "1%",
                                                    }}>
                                                    <DatePicker fullWidth key={"end_date"} field={"end_date"} label="End Date" />
                                                </Grid>
                                                {(report === 9 || report === 22) && ( //only for OC staff report 23 is prod
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        style={{
                                                            marginTop: "4%",
                                                            marginLeft: "1%",
                                                        }}>
                                                        <Select
                                                            field="user"
                                                            label={
                                                                <HelpLabel
                                                                    inputLabel="Select User"
                                                                    helpText="The selected user will be placed on the report"
                                                                />
                                                            }
                                                            options={this.getUserFields()}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                )}

                                                <Grid item xs={12}>
                                                    <Button
                                                        type="submit"
                                                        variant="contained"
                                                        color="primary"
                                                        className={classes.button}
                                                        style={{ marginTop: 30 }}
                                                        fullWidth>
                                                        <Save />
                                                        &nbsp;&nbsp;&nbsp; Run Search
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </form>
                                    )}
                                </Formm>
                            </DialogContentText>
                        </DialogContent>
                    </Dialog>
                    <Dialog open={uploading} classes={{ paper: classes.reportGeneration }}>
                        <Toolbar style={{ backgroundColor: "#d3d3d3" }}>
                            <Typography variant="h5" className={classes.flex}>
                                Uploading
                            </Typography>
                            {results && (
                                <IconButton onClick={() => this.setState({ uploading: false })}>
                                    <Close />
                                </IconButton>
                            )}
                        </Toolbar>
                        <DialogContent className={classes.dialogMin}>
                            {uploading_icon && (
                                <DialogContentText>
                                    Please wait...
                                    <CircularProgress size={24} className={classes.progress} />
                                </DialogContentText>
                            )}
                            <div dangerouslySetInnerHTML={{ __html: results }} />
                        </DialogContent>
                    </Dialog>
                    <Dialog open={rcwdReportOpen} classes={{ paper: classes.reportGeneration }}>
                        <Toolbar style={{ backgroundColor: "#d3d3d3" }}>
                            <Typography variant="h5" className={classes.flex}>
                                Select Parameters
                            </Typography>
                            <IconButton onClick={() => this.setState({ rcwdReportOpen: false })}>
                                <Close />
                            </IconButton>
                        </Toolbar>
                        <DialogContent className={classes.dialogMin}>
                            <DialogContentText>
                                <Formm
                                    defaultValues={{
                                        year: new Date().getFullYear().toString(),
                                        report_type: "District Wide",
                                        export_type: "HTML",
                                        city: "All",
                                        subwatershed: "All",
                                        period: "April 1-June 30",
                                        module: "All"
                                    }}
                                    onSubmit={(values) => this.runReportRcwd(values)}>
                                    {(formApii) => (
                                        <form onSubmit={formApii.submitForm}>
                                            <Grid container spacing={1} alignItems="center">
                                                {report !== 34 && report !== 35 && report !== 38 && report !== 39 && report !== 40 && report !== 41 && (
                                                    <Grid item xs={12}>
                                                        <Select field="year" label="Year" options={MAKE_OPTIONS_RCWD(year_arr)} fullWidth />
                                                    </Grid>
                                                )}
                                                {report === 34 && (
                                                    <>
                                                    <Grid item xs={12}>
                                                        <Select
                                                            field="year"
                                                            label="Calendar Year"
                                                            options={MAKE_OPTIONS_RCWD([
                                                                "2024",
                                                                "2025",
                                                                "2026",
                                                                "2027",
                                                                "2028",
                                                                "2029",
                                                                "2030",
                                                            ])}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Select
                                                            field="period"
                                                            label="Reporting Period"
                                                            options={MAKE_OPTIONS_RCWD([
                                                                "January 1-March 31",
                                                                "April 1-June 30",
                                                                "July 1-September 30",
                                                                "October 1-December 31",
                                                            ])}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Select
                                                            field="module"
                                                            label="Select Partner Module"
                                                            options={MAKE_OPTIONS_RCWD([
                                                                "All",
                                                                "Land Assistance Fund (Federation)",
                                                                "Regenerative Finance Model (Akiptan)",
                                                                "Regenerative Agriculture Fund I (FBN/PepsiCo/ADM)",
                                                                "Regenerative Agriculture Fund II (FBN)",
                                                                "Climate Smart Practice Linked Incentive (PCM)",
                                                                "Climate Smart Practice Linked Crop Plans I (PFI)",
                                                                "Climate Smart Practice Linked Crop Plans II (Coop/Mondelez)",
                                                                "Climate Smart Practice Linked Crop Plans III (Nutrien)",
                                                            ])}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    </>
                                                )}
                                                {report === 35 && (
                                                    <>
                                                    <Grid item xs={12}>
                                                        <FileInput field="file" id="file_upl" fullWidth label="Select USDA Workbook to Import" />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Select
                                                            field="module"
                                                            label="Select Partner Module"
                                                            options={MAKE_OPTIONS_RCWD([
                                                                "Land Assistance Fund (Federation)",
                                                                "Regenerative Finance Model (Akiptan)",
                                                                "Regenerative Agriculture Fund I (FBN/PepsiCo/ADM)",
                                                                "Regenerative Agriculture Fund II (FBN)",
                                                                "Climate Smart Practice Linked Incentive (PCM)",
                                                                "Climate Smart Practice Linked Crop Plans I (PFI)",
                                                                "Climate Smart Practice Linked Crop Plans II (Coop/Mondelez)",
                                                                "Climate Smart Practice Linked Crop Plans III (Nutrien)",
                                                            ])}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    </>
                                                )}
                                                {report === 38 && (
                                                    <>
                                                    <Grid item xs={12}>
                                                        <Select
                                                            field="year"
                                                            label="Calendar Year"
                                                            options={MAKE_OPTIONS_RCWD([
                                                                "2024",
                                                                "2025",
                                                                "2026",
                                                                "2027",
                                                                "2028",
                                                                "2029",
                                                                "2030",
                                                            ])}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Select
                                                            field="period"
                                                            label="Reporting Period"
                                                            options={MAKE_OPTIONS_RCWD([
                                                                "January 1-March 31",
                                                                "April 1-June 30",
                                                                "July 1-September 30",
                                                                "October 1-December 31",
                                                            ])}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Select
                                                            field="module"
                                                            label="Select Partner Module"
                                                            options={MAKE_OPTIONS_RCWD([
                                                                "All",
                                                                "FSF PCSC Tracking",
                                                            ])}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    </>
                                                )}
                                                {report === 39 && (
                                                    <>
                                                    <Grid item xs={12}>
                                                        <FileInput field="file" id="file_upl" fullWidth label="Select USDA Workbook to Import" />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Select
                                                            field="module"
                                                            label="Select Partner Module"
                                                            options={MAKE_OPTIONS_RCWD([
                                                                "FSF PCSC Tracking",
                                                            ])}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    </>
                                                )}
                                                {report === 40 && (
                                                    <>
                                                    <Grid item xs={12}>
                                                        <FileInput field="file" id="file_upl" fullWidth label="Select Workbook to Import" />
                                                    </Grid>
                                                    </>
                                                )}
                                                {report === 32 && (
                                                    <Grid item xs={12}>
                                                        <Select
                                                            field="project"
                                                            label="Project"
                                                            options={MAKE_OPTIONS_RCWD([
                                                                "Bear Park",
                                                                "Polk Co Ditch 119", 
                                                                "Polk Co Ditch 9", 
                                                                "Project 3 – Onstad-Liberty Ditch",
                                                                "Project 5",
                                                                "Project 11 – Beltrami Flood Control and Diversion Project",
                                                                "Project 12 – Improvement and Consolidation of Polk County Ditches No. 98 and 148",
                                                                "Project 13 – Reis-Scandia Twp Ditch",
                                                                "Project 17",
                                                                "Project 20",
                                                                "Project 24",
                                                                "Project 27 – Union-Sarah Lakes Outlet",
                                                                "Project 32 – Polk County Ditch 80 and Laterals 1 & 2",
                                                                "Sand Hill Ditch", 
                                                            ])}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                )}
                                                {report === 15 && (
                                                    <Grid item xs={12}>
                                                        <Select
                                                            field="report_type"
                                                            label="Report Type"
                                                            options={MAKE_OPTIONS_RCWD(["Anoka", "Ramsey", "Washington", "District Wide"])}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                )}
                                                {(report === 19 || report === 20) && (
                                                    <Grid item xs={12}>
                                                        <Select
                                                            field="export_type"
                                                            label="Export Type"
                                                            options={MAKE_OPTIONS_RCWD(["HTML", "Excel"])}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                )}
                                                {report === 26 && (
                                                    <Grid item xs={12}>
                                                        <Select
                                                            field="city"
                                                            label="City"
                                                            options={MAKE_OPTIONS_RCWD([
                                                                "Falcon Heights",
                                                                "Maplewood",
                                                                "Lauderdale",
                                                                "Roseville",
                                                                "St. Paul",
                                                                "All",
                                                            ])}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                )}
                                                {report === 26 && (
                                                    <Grid item xs={12}>
                                                        <Select
                                                            field="subwatershed"
                                                            label="Subwatershed"
                                                            options={MAKE_OPTIONS_RCWD([
                                                                "Como",
                                                                "Crosby",
                                                                "Davern",
                                                                "Downtown",
                                                                "East Kittsondale",
                                                                "Goodrich-Western",
                                                                "Hidden Falls",
                                                                "Mccarons Lake",
                                                                "Mississippi River Blvd",
                                                                "Phalen Creek",
                                                                "St. Anthony Hill",
                                                                "St. Anthony Park",
                                                                "Trout Brook",
                                                                "Urban",
                                                                "West Kittsondale",
                                                                "West Seventh",
                                                                "All",
                                                            ])}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                )}
                                                <Grid item xs={12}>
                                                    <Button type="submit" variant="contained" color="primary" fullWidth>
                                                        <Save />
                                                        &nbsp;&nbsp;&nbsp; Generate Report
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </form>
                                    )}
                                </Formm>
                            </DialogContentText>
                        </DialogContent>
                    </Dialog>
                    <Dialog open={rileyReportOpen} classes={{ paper: classes.reportGeneration }}>
                        <Toolbar style={{ backgroundColor: "#d3d3d3" }}>
                            <Typography variant="h5" className={classes.flex}>
                                Select Parameters
                            </Typography>
                            <IconButton onClick={() => this.setState({ rileyReportOpen: false })}>
                                <Close />
                            </IconButton>
                        </Toolbar>
                        <DialogContent className={classes.dialogMin}>
                            <DialogContentText>
                                <Formm
                                    validateError={this.runErrorValidatorRiley}
                                    defaultValues={{
                                        board_meeting: new Date().toLocaleDateString()
                                    }}
                                    onSubmit={(values) => this.runReportRiley(values)}>
                                    {(formApii) => (
                                        <form onSubmit={formApii.submitForm}>
                                            <Grid container spacing={1} alignItems="center">
                                                <Grid item xs={12}>
                                                    <DatePicker fullWidth field="board_meeting" label="Last Board Meeting Date" />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Button type="submit" variant="contained" color="primary" fullWidth>
                                                        <Save />
                                                        &nbsp;&nbsp;&nbsp; Generate Report
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </form>
                                    )}
                                </Formm>
                            </DialogContentText>
                        </DialogContent>
                    </Dialog>
                    {creatingReport && (
                        <>
                            <CircularProgress size={90} className={classes.progress} />
                            <Typography variant="h6" style={{ textAlign: "center" }}>
                                Generating Report. Please Wait...
                            </Typography>
                        </>
                    )}
                    <WarningDialog
                        confirmAction={() => {
                            const { deleteid } = this.state;
                            // setTimeout(function() {
                            //     comp.loadTotalRecords();
                            // }, 1000);
                            ormCustomReportPageDelete(deleteid);

                            this.setState({ deleteSearchDialogOpen: false, deleteid: 0 });
                        }}
                        cancelAction={() => this.setState({ deleteSearchDialogOpen: false })}
                        open={deleteSearchDialogOpen}
                        title={`Delete Search?`}
                        text="Are you sure you wish to delete this custom search? Deleting this search will also remove all query fields."
                    />
                    <WarningDialog
                        confirmAction={() => {
                            const { deleteid } = this.state;
                            // setTimeout(function() {
                            //     comp.loadTotalRecords();
                            // }, 1000);
                            ormCustomReportRecordDelete(deleteid);
                            this.setState({ deleteDialogOpen: false, deleteid: 0 });
                        }}
                        cancelAction={() => this.setState({ deleteDialogOpen: false })}
                        open={deleteDialogOpen}
                        title={`Delete ${deletePageName} Custom Report?`}
                        text="Are you sure you wish to delete this custom report? Deleting this report will also remove all page and fields within this report."
                    />
                </>
            </AppContainer>
        );
    }
}
Home.displayName = "Custom Reports";

Home = connect(
    (state, ownProps) => ({
        customReports: getAllCustomReports(state, ownProps),
        customReportWPages: getCustomReportsWPages(state, ownProps), // for module name only on main table
        thisCustomReport: getThisCustomReport(state, ownProps),
        pages: getPages(state, ownProps),
        authState: state.auth,
        reports: getCustomReportFields(state, ownProps),
        reportwithtablefields: getCustomReportTableFields(state, ownProps),
        fields: getFields(state, ownProps),
    }),
    {
        ...CustomReportPage.actions,
        ...CustomReportRecord.actions,
        ...CustomReportQueryField.actions,
        ...CustomReportTableField.actions,
    }
)(Home);

export default withStyles(styles)(withRouter(Home));
